import React from "react";
import { Link } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";

import CustomButton from "./../../customButton/CustomButton";
import "./NimbleRentTopBanner.css";

const NimbleRentTopBanner = ({ showButton = true }) => {
  const theme = useTheme();

  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const containerStyle = {
    height: isSmScreen && showButton ? "120px" : "88px",
  };

  const buttonStyle = {
    padding: "3px 8px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
  };

  return (
    <div className="nimble-rent-top-banner-container" style={containerStyle}>
      <div className="nimble-rent-top-banner-text">
        <div className="nimble-rent-top-banner-textx">
          <span className="nimble-rent-top-banner-texta">Hurray</span>&nbsp;
          <span className="nimble-rent-top-banner-texta">Financing</span>
          &nbsp;
          <span className="nimble-rent-top-banner-textb">
            <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/Fire.png"></img>&nbsp;is here!!
          </span>
          &nbsp;
          {isSmScreen ? (
            <p className="nimble-rent-top-banner-textc">
              You can now buy or rent a property and pay later
            </p>
          ) : (
            <span className="nimble-rent-top-banner-textc">
              You can now buy or rent a property and pay later
            </span>
          )}
          {showButton && (
            <Link
              to={"/nimble-rent-information"}
              aria-label="nimble rent information"
            >
              <span className="nimble-rent-top-banner-button">
                <CustomButton
                  label="Check Eligibility"
                  style={{ padding: "1px 16px" }}
                />
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default NimbleRentTopBanner;
