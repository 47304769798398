import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Resizer from "react-image-file-resizer";

import { toast } from "react-toastify";

import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import { IoMdPerson } from "react-icons/io";
import { FaStickyNote } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import ClearIcon from "@mui/icons-material/Clear";
import HomeIcon from "@mui/icons-material/Home";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";

import { AiFillWarning } from "react-icons/ai";
import { useAuth } from "../../../context/auth";
import CustomButton from "../../customButton/CustomButton";

import config from "../../../config";

import "./ProfileUpdateForm.css";

const defaultAvatar = `
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
  </svg>
`;

const ProfileUpdateForm = ({ sourceURL }) => {
  // state
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();

  const [userType, setUserType] = useState("Buyer");

  const [profileImage, setProfileImage] = useState();
  const [profileImageUrl, setProfileImageUrl] = useState("");

  const [isAgent, setIsAgent] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [roles, setRoles] = useState([""]);

  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    address: "",
    phone: "",
    aboutMe: "",
    reg_number: "",
    photo: "",
  });

  useEffect(() => {
    const storedForm = JSON.parse(localStorage.getItem("profile"));
    const storedFormData = JSON.parse(localStorage.getItem("profileFormData"));

    if (auth?.user) {
      setPhoto(
        storedFormData?.photo || storedForm?.photo || auth.user?.photo || ""
      );
      setRoles(auth?.user?.role);
    }
  }, []);

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem("profileFormData"));
    const storedForm = JSON.parse(localStorage.getItem("profile"));
    if (storedFormData || auth?.user) {
      setProfileData((prev) => ({
        ...prev,
        firstName:
          storedFormData?.firstName ||
          storedForm?.firstName ||
          auth?.user?.firstName ||
          "",
      }));
      setProfileData((prev) => ({
        ...prev,
        lastName:
          storedFormData?.lastName ||
          storedForm?.lastName ||
          auth?.user?.lastName ||
          "",
      }));
      setProfileData((prev) => ({
        ...prev,
        email:
          storedFormData?.email || storedForm?.email || auth?.user?.email || "",
      }));
      setProfileData((prev) => ({
        ...prev,
        company:
          storedFormData?.company ||
          storedForm?.company ||
          auth?.user?.company ||
          "",
      }));
      setProfileData((prev) => ({
        ...prev,
        address:
          storedFormData?.address ||
          storedForm?.address ||
          auth?.user?.address ||
          "",
      }));
      setProfileData((prev) => ({
        ...prev,
        phone:
          storedFormData?.phone || storedForm?.phone || auth?.user?.phone || "",
      }));
      setProfileData((prev) => ({
        ...prev,
        aboutMe:
          storedFormData?.aboutMe ||
          storedForm?.description ||
          auth?.user?.description ||
          "",
      }));
      setProfileData((prev) => ({
        ...prev,
        reg_number:
          storedFormData?.reg_number ||
          storedForm?.reg_number ||
          auth?.user?.info?.regNumber ||
          "",
      }));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.user?.role && auth.user?.role?.includes("Agent")) {
      setUserType("Agent");
    }
  }, [auth?.user?.role]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    if (sourceURL === "be-agent") {
      setIsAgent(true);
    }
  }, [sourceURL]);

  const bigYellowCircle = isAgent
    ? "agent-profileUpdate-big-yellow"
    : "profileUpdate-big-yellow";
  const blueCircle = isAgent
    ? "agent-profileUpdate-blue"
    : "profileUpdate-blue";
  const blackCircle = isAgent
    ? "agent-profileUpdate-black"
    : "profileUpdate-black";
  const smallYellowCircle = isAgent
    ? "agent-profileUpdate-small-yellow"
    : "profileUpdate-small-yellow";

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileImageUrl(URL.createObjectURL(file));
    }
  };

  const handlePhotoUpload = async (e) => {
    try {
      // Check if a profile image is selected
      if (profileImage) {
        // Resize the image
        const resizedImage = await new Promise((resolve, reject) => {
          Resizer.imageFileResizer(
            profileImage,
            1080,
            720,
            "JPEG",
            100,
            0,
            (uri) => resolve(uri),
            "base64",
            1080,
            720
          );
        });

        // Upload the resized image
        const { data } = await axios.post("/upload-image", {
          file: resizedImage,
          label: "profile_photo",
        });

        // Set the photo state and return the Location
        setPhoto(data.Location);
        return data.Location;
      }
    } catch (err) {
      // console.error("Error uploading photo:", err);
      throw err; // Propagate the error to the calling program
    }
  };

  // Function to update form field and save to localStorage
  const updateFormField = (fieldName, value) => {
    const updatedFormData = { ...formData, [fieldName]: value };
    setProfileData((prevState) => ({ ...prevState, [fieldName]: value }));
    setFormData(updatedFormData);
    localStorage.setItem("profileFormData", JSON.stringify(updatedFormData));
  };

  // Initialize form state from localStorage

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (
        (userType === "Agent" || isAgent || sourceURL) &&
        !profileData?.company
      ) {
        toast.error("Company name is required");
        return;
      } else if (
        (userType === "Agent" || isAgent || sourceURL) &&
        !profileData.reg_number
      ) {
        toast.error("Registration No. is required");
        return;
        // } else if (!profileImage) {
        //   toast.error("Profile image is required");
        //   return;
      } else if (!profileData.firstName) {
        toast.error("FirstName is required");
        return;
      } else if (!profileData.lastName) {
        toast.error("Lastname is required");
        return;
      } else if (!profileData.address) {
        toast.error("Address is required");
        return;
      } else if (!profileData.phone) {
        toast.error("Phone No. is required");
        return;
      } else if (
        (userType === "Agent" || isAgent || sourceURL) &&
        !profileData.aboutMe
      ) {
        toast.error("Brief Profile is required");
        return;
      } else {
        if (userType === "Agent" || isAgent || sourceURL) {
          if (!auth.user?.role?.includes("Agent")) {
            roles.push("Agent");
          }
        }
        setLoading(true);
        const uploadedPhoto = await handlePhotoUpload();
        updateFormField(
          "photo",
          uploadedPhoto ? uploadedPhoto : auth?.user.photo
        );

        if (userType === "Agent" || isAgent || sourceURL) {
          localStorage.setItem(
            "profile",
            JSON.stringify({
              userId: auth?.user?.userId,
              firstName: profileData.firstName,
              lastName: profileData.lastName,
              email: profileData.email,
              company: profileData.company,
              address: profileData.address,
              phone: profileData.phone,
              description: profileData.aboutMe,
              registrationNumber: profileData.reg_number || "",
              roles: roles || [auth?.user.role],
              photo,
            })
          );

          navigate("/user/document-manager", {
            state: { uploadedPhoto },
          });
          return;
        }

        const { data } = await axios.post(
          `${config.AUTH_API}/user/updateProfile`,
          {
            userId: auth?.user?.userId,
            firstName: profileData.firstName,
            lastName: profileData.lastName,
            email: profileData.email,
            company: profileData.company,
            address: profileData.address,
            phone: profileData.phone,
            description: profileData.aboutMe,
            registrationNumber: profileData.reg_number || "",
            roles: roles || [auth?.user.role],
            photo: uploadedPhoto,
            agentDocuments: [],
          }
        );

        if (!data.success) {
          toast.error(data.message);
          setLoading(false);
        } else {
          const data1 = {
            ...auth.user,
            userId: auth.user.userId,
            photo: uploadedPhoto ? uploadedPhoto : auth.user?.photo,
          };

          setAuth({ ...auth, user: data.responsePayload });

          let fromLS = JSON.parse(localStorage.getItem("auth"));
          fromLS.user = data1;
          localStorage.setItem("auth", JSON.stringify(fromLS));
          setLoading(false);

          toast.success("Profile updated");

          // reload page on redirect
          window.location.href = "/";
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong. Try again.");
      setLoading(false);
    }
  };

  const [formData, setFormData] = useState([
    { text: "Passport photo ID", image: null, blob: null },
    { text: "Proof of identification", image: null, blob: null },
    { text: "CAC certification", image: null, blob: null },
  ]);

  const handleClickClear = (fieldName) => {
    const inputField = document.querySelector(`input[name="${fieldName}"]`);
    if (inputField) {
      inputField.value = "";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    updateFormField(name, value);
  };

  return (
    <>
      <div className="">
        <div className=" profileUpdate-form">
          <div className="col-lg-12">
            <div className="col-lg-12 profileUpdate-container-first">
              <div className="profileUpdate-word">
                <h3 className="profileUpdate-title">
                  {`${sourceURL ? "Agent Request Form" : "Update Profile"}`}
                </h3>
                <div className="profileUpdate-subtitle">
                  <h6>Please ensure you fill in your correct details.</h6>
                </div>
                {!sourceURL && userType === "Buyer" && (
                  <>
                    <div className="d-flex is-agent">
                      <label
                        id="isAgent"
                        className="col-sm-6 col-form-label is-agent-label"
                      >
                        Do you want to be an Agent ?
                      </label>
                      <div className="col-sm-4 d-flex">
                        <label
                          className={`radio-button ${
                            isAgent === false ? "selected" : ""
                          }`}
                        >
                          <input
                            className="input-style m-1  col-form-label is-agent-label"
                            type="radio"
                            name="isAgent"
                            value={false}
                            checked={isAgent === false}
                            onChange={() => setIsAgent(false)}
                          />
                          <span className="pl-5"> No</span>
                        </label>
                        <label
                          className={`radio-button ${
                            isAgent === true ? "selected" : ""
                          }`}
                        >
                          <input
                            className=" input-style m-1   col-form-label is-agent-label"
                            type="radio"
                            name="isAgent"
                            value={true}
                            checked={isAgent === true}
                            onChange={() => {
                              setIsAgent(true);
                            }}
                          />
                          Yes
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="profileUpdate-box">
                <div className="profileUpdate-profileCard">
                  <div className="profileUpdate-imageContainer">
                    {profileImageUrl || photo || auth?.user.photo ? (
                      <img
                        src={profileImageUrl || photo || auth?.user.photo}
                        className="profileImage"
                        alt="Profile"
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: defaultAvatar }}
                        className="profileImage defaultAvatar"
                      />
                    )}
                    <div className="verySmall-circle">
                      <label htmlFor="file-input" className="upload-icon">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 0V16"
                            stroke="#0c0c0d"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16 8H0"
                            stroke="#0c0c0d"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </label>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                  </div>

                  <div className="row mb-3 profileUpdate-textContainer">
                    <div className="profileUpdate-accountType-context">
                      <p className="profileUpdate-accountType">Account Type</p>
                      <p className="profileUpdate-accountUser">
                        {userType === "Buyer" ? "User" : "Agent"}
                      </p>
                      <label htmlFor="file-input" className="changePhotoButton">
                        Change profile photo
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 profileUpdate-container ">
              <div className="col-lg-6 profileUpdate-header">
                <form className="profileUpdate-modal">
                  <FormControl
                    sx={{ width: "100%" }}
                    variant="outlined"
                    className="form-input"
                  >
                    {userType === "Agent" || isAgent || sourceURL ? (
                      <>
                        <span className="profileUpdate-input-label">
                          Company Name{" "}
                          <StarIcon sx={{ color: "red", fontSize: 10 }} />
                        </span>
                        <OutlinedInput
                          type="text"
                          size="small"
                          placeholder="Enter Company name"
                          name="company"
                          value={profileData.company}
                          onChange={(e) =>
                            updateFormField("company", e.target.value)
                          }
                          sx={{
                            borderRadius: "8px",
                            border: "1px solid #D1D5DB",
                            marginBottom: "15px",
                            padding: "1.5px 10px",
                            background: " #F9FAFB",
                            marginRight: "0px",
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <StoreRoundedIcon />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear text"
                                onClick={() => {
                                  handleClickClear("company");
                                  handleChange({
                                    target: { name: "company", value: "" },
                                  });
                                }}
                                edge="end"
                              >
                                <ClearIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                            </InputAdornment>
                          }
                        />

                        <span className="profileUpdate-input-label">
                          Registration Number{" "}
                          <StarIcon sx={{ color: "red", fontSize: 10 }} />
                        </span>
                        <OutlinedInput
                          type="text"
                          size="small"
                          placeholder="Enter registration number"
                          name="reg_number"
                          value={profileData.reg_number}
                          onChange={(e) =>
                            updateFormField("reg_number", e.target.value)
                          }
                          sx={{
                            borderRadius: "8px",
                            border: "1px solid #D1D5DB",
                            marginBottom: "15px",
                            padding: "1.5px 10px",
                            background: " #F9FAFB",
                            marginRight: "0px",
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <AppRegistrationRoundedIcon />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear text"
                                onClick={() => {
                                  handleClickClear("reg_number");
                                  handleChange({
                                    target: { name: "reg_number", value: "" },
                                  });
                                }}
                                edge="end"
                              >
                                <ClearIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}

                    <span className="profileUpdate-input-label">
                      First name{" "}
                      <StarIcon sx={{ color: "red", fontSize: 10 }} />
                    </span>
                    <OutlinedInput
                      type="text"
                      size="small"
                      placeholder="Enter full name"
                      name="firstName"
                      value={profileData.firstName}
                      onChange={(e) =>
                        updateFormField("firstName", e.target.value)
                      }
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "15px",
                        padding: "1.5px 10px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IoMdPerson />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("firstName");
                              handleChange({
                                target: { name: "firstName", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    <span className="profileUpdate-input-label">
                      Last name <StarIcon sx={{ color: "red", fontSize: 10 }} />
                    </span>
                    <OutlinedInput
                      type="text"
                      size="small"
                      placeholder="Enter last name"
                      name="lastName"
                      value={profileData.lastName}
                      onChange={(e) =>
                        updateFormField("lastName", e.target.value)
                      }
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "15px",
                        padding: "1.5px 10px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IoMdPerson />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("lastName");
                              handleChange({
                                target: { name: "lastName", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    <span className="profileUpdate-input-label">
                      Email <StarIcon sx={{ color: "red", fontSize: 10 }} />
                    </span>
                    <OutlinedInput
                      type="email"
                      size="small"
                      placeholder="Input email"
                      name="email"
                      value={profileData.email}
                      readOnly
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "15px",
                        padding: "1.5px 10px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IoMail />
                        </InputAdornment>
                      }
                    />

                    <span className="profileUpdate-input-label">
                      Address
                      <StarIcon sx={{ color: "red", fontSize: 10 }} />
                    </span>
                    <OutlinedInput
                      type="text"
                      size="small"
                      placeholder="Enter address "
                      name="address"
                      value={profileData.address}
                      onChange={(e) =>
                        updateFormField("address", e.target.value)
                      }
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "15px",
                        padding: "1.5px 10px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("address");
                              handleChange({
                                target: { name: "address", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    <span className="profileUpdate-input-label">
                      Phone Number{" "}
                      <StarIcon sx={{ color: "red", fontSize: 10 }} />
                    </span>
                    <OutlinedInput
                      type="text"
                      size="small"
                      placeholder="+234*****"
                      name="phone"
                      value={profileData.phone}
                      onChange={(e) => updateFormField("phone", e.target.value)}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "15px",
                        padding: "1.5px 10px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <FaPhoneAlt />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("phone");
                              handleChange({
                                target: { name: "phone", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    <span className="profileUpdate-input-label">
                      Write something about yourself
                    </span>
                    <OutlinedInput
                      type="text"
                      size="small"
                      placeholder="Write your message"
                      name="message"
                      multiline
                      rows={3}
                      value={profileData.aboutMe}
                      onChange={(e) =>
                        updateFormField("aboutMe", e.target.value)
                      }
                      maxLength={5000}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "15px",
                        padding: "1.5px 10px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <FaStickyNote style={{ marginBottom: 40 }} />
                        </InputAdornment>
                      }
                    />

                    {(userType === "Agent" || isAgent || sourceURL) && (
                      <label className="alert alert-warning d-flex align-items-center">
                        <span>
                          <AiFillWarning
                            className="bi flex-shrink-0 "
                            style={{ width: "15", height: "15" }}
                          />
                        </span>{" "}
                        Your data will be reviewed by our legal department
                      </label>
                    )}

                    {userType === "Agent" || isAgent || sourceURL ? (
                      <span className="submit-button profileUpdate-botton2">
                        <CustomButton
                          label={loading ? "Processing..." : "Next"}
                          disabled={loading}
                          onClick={handleSubmit}
                          style={{
                            height: "40px",
                            padding: "8px 70px 8px 70px",
                          }}
                        />
                      </span>
                    ) : (
                      <span className="submit-button profileUpdate-botton2">
                        <CustomButton
                          label={loading ? "Processing..." : "Update Profile"}
                          disabled={loading}
                          onClick={handleSubmit}
                          style={{
                            height: "40px",
                            padding: "8px 70px 8px 70px",
                          }}
                        />
                      </span>
                    )}
                  </FormControl>
                </form>
              </div>

              <div className="col-lg-6 profileUpdate-circles">
                <div className="profileUpdate-circle-section">
                  <div
                    id={bigYellowCircle}
                    className="profileUpdate-circle"
                  ></div>
                  <div id={blackCircle} className="profileUpdate-circle"></div>
                  <div id={blueCircle} className="profileUpdate-circle"></div>
                  <div
                    id={smallYellowCircle}
                    className="profileUpdate-circle"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileUpdateForm;
