export const houseType = () => [
  "Flat",
  "Bungalows",
  "Duplex",
  "Terrace Duplex",
  "Semi-detached Duplex",
  "Fully-detached Duplex",
  "Mansion",
  "Apartment/Condos",
  "Maisonette",
  "Pent-house",
  "Thatched/Traditional houses",
];
