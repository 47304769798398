import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./ImageGallery.css";

export const ImageGallery = ({
  photos,
  showThumbs = true,
  showStatus = true,
  showIndicators = true,
  height = null,
  width = null,
  thumbWidth = 50,
  className = "",
}) => {
  const renderThumbnails = () => {
    return photos.map((photo, index) => (
      <div key={index} className="thumbnail-container">
        <img
          src={photo.thumbSrc || photo.src}
          alt={`Thumbnail ${index}`}
          className="thumbnail"
          style={{ height: photo.thumbHeight || "auto" }} // Set the height of the thumbnail image
        />
      </div>
    ));
  };

  return (
    <div className={`image-container ${className}`}>
      <Carousel
        showThumbs={showThumbs}
        showStatus={showStatus}
        infiniteLoop={true}
        autoPlay={true}
        showIndicators={showIndicators}
        thumbWidth={thumbWidth}
        renderThumbs={renderThumbnails}
      >
        {photos.map((photo, index) => (
          <div key={index} className="slide">
            <img
              src={photo.src}
              alt={`Slide Image ${index}`}
              width={width}
              height={height}
              className="rounded-image"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
export const generatePhotosArray = (photos) => {
  if (photos?.length > 0) {
    const x = photos?.length === 1 ? 2 : 4;
    let arr = [];

    photos.map((p) =>
      arr.push({
        src: p?.Location,
        width: x,
        height: x,
      })
    );
    return arr;
  } else {
    return [
      {
        src: "Image",
        width: 2,
        height: 1,
      },
    ];
  }
};
