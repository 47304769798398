import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IoMdPerson } from "react-icons/io";
import { FaStickyNote } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import ClearIcon from "@mui/icons-material/Clear";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { useAuth } from "../../context/auth";
import { contactUsSchema } from "../contactUs/validations";
import CustomButton from "../../components/customButton/CustomButton";

import Modall from "../../components/modal/Modal";
import RequestSubmissionSuccessModal from "../../components/requestSubmissionSuccessModal/RequestSubmissionSuccessModal";

import config from "../../config";

import "./ContactUs.css";

const ContactUs = () => {
  // state
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();

  const [isRequestSuccessModalOpen, setIsRequestSuccessModalOpen] =
    useState(false);

  const isSmScreen = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (values, actions) => {
    console.log(values);
    const {
      contactName,
      email,
      message,
      phone,
      subject,
      propertyType,
      propertySubtype,
      enquiryType,
      location,
    } = values;

    try {
      setLoading(true);

      const { data } = await axios.post(
        `${config.AUTH_API}/api/Emailing/ContactUs`,
        {
          Name: contactName,
          Email: email,
          Phone: phone,
          Message: message,
          Subject: subject,
          PropertyType: propertyType,
          EnquiryType: enquiryType,
          PropertySubtype: propertySubtype,
          Location: location,
          EmailReceiver: config.AdminEmail || "",
          Action: "",
        }
      );

      if (!data.success) {
        toast.error(data.message);
        setLoading(false);
      } else {
        setIsRequestSuccessModalOpen(true);
        // toast.success(data.message);
        setLoading(false);
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong. Try again.");
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    isSubmitting,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      contactName: auth?.user?.firstName || "",
      email: auth?.user?.email || "",
      phone: auth?.user?.phone || "",
      message: "",
      subject: "",
      enquiryType: "",
      propertyType: "",
      propertySubtype: "",
      location: "",
    },
    validationSchema: contactUsSchema,
    onSubmit,
  });

  //Function to handle subject change
  const handleSubjectChange = (e) => {
    const selectedSubject = e.target.value;
    setFieldValue("subject", selectedSubject);

    // Reset other fields when subject changes
    setFieldValue("enquiryType", "");
    setFieldValue("propertyType", "");
    setFieldValue("propertySubtype", "");
    setFieldValue("location", "");
  };

  const handleClickClear = (fieldName) => {
    const inputField = document.querySelector(`input[name="${fieldName}"]`);
    if (inputField) {
      inputField.value = "";
    }
  };

  return (
    <>
      <div className="">
        <Modall
          handleClose={() => setIsOpen(setIsRequestSuccessModalOpen)}
          isOpen={isRequestSuccessModalOpen}
          modalContentClassName={
            isSmScreen
              ? "small-request-success-modal-content"
              : "request-success-modal-content"
          }
          closeBtnClassName={
            // isSmScreen
            //   ? "small-request-success-close-btn"
            //   :
            "request-success-close-btn"
          }
        >
          <RequestSubmissionSuccessModal
            setIsRequestSuccessModalOpen={setIsRequestSuccessModalOpen}
            isRequestSuccessModalOpen={isRequestSuccessModalOpen}
            title={"Request form Submission"}
            modalText={
              "Your request has been submitted, We will reach out to you within three working days. Thank you."
            }
          />
        </Modall>
        <div
          className="contactus-top-banner"
          style={{
            backgroundImage: `url(./ContactUsBackground.png)`,
          }}
        >
          <h5 className="nimblecase-word">Real Estate, The Right Way</h5>
          <img className="hand-img" src="./HandImg.png" alt="Hand Image" />
        </div>

        <div className="form-box">
          <div className="col-lg-12">
            <div className="contact-us-container col-lg-12">
              <div className="content-header col-lg-5">
                <div className="col-lg-11 content-word">
                  <h3 className="content-title">Contact Us</h3>
                  <div className="content-subtitle">
                    We're here to discuss your needs, answer your questions and
                    offer expert solutions
                  </div>
                </div>
                <form
                  className="contact-us-contact-modal"
                  // onSubmit={handleSubmit}
                >
                  <FormControl
                    sx={{ width: "100%" }}
                    variant="outlined"
                    className="form-input"
                  >
                    <span className="modal-input-label">Full name</span>
                    <OutlinedInput
                      size="small"
                      placeholder="Your name"
                      name="contactName"
                      value={values.contactName}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IoMdPerson />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("contactName");
                              handleChange({
                                target: { name: "contactName", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.contactName && touched.contactName && (
                      <div className="mt-0 text-danger">
                        <h6>
                          {" "}
                          <p> {errors.contactName}</p>
                        </h6>
                      </div>
                    )}

                    <span className="modal-input-label">Email</span>
                    <OutlinedInput
                      size="small"
                      placeholder="Input email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IoMail />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("email");
                              handleChange({
                                target: { name: "email", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.email && touched.email && (
                      <div className="mt-0 text-danger">
                        <h6>
                          {" "}
                          <p> {errors.email}</p>
                        </h6>
                      </div>
                    )}

                    <span className="modal-input-label">Phone Number</span>
                    <OutlinedInput
                      size="small"
                      placeholder="+234*****"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <FaPhoneAlt />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("phone");
                              handleChange({
                                target: { name: "phone", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.phone && touched.phone && (
                      <div className="mt-0 text-danger">
                        <h6>
                          {" "}
                          <p> {errors.phone}</p>
                        </h6>
                      </div>
                    )}

                    <>
                      <span className="modal-input-label">Quick note</span>
                      <OutlinedInput
                        size="small"
                        placeholder="Write your message"
                        name="message"
                        multiline
                        rows={3}
                        value={values.message}
                        onChange={handleChange}
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #D1D5DB",
                          marginBottom: "20px",
                          padding: "3.5px 20px",
                          background: " #F9FAFB",
                          marginRight: "0px",
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <FaStickyNote style={{ marginBottom: 40 }} />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear text"
                              onClick={() => {
                                handleClickClear("message");
                                handleChange({
                                  target: { name: "message", value: "" },
                                });
                              }}
                              edge="end"
                            >
                              <ClearIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </>

                    <span className="modal-input-label">Conversation type</span>
                    <Select
                      size="small"
                      placeholder="Conversation type"
                      name="subject"
                      value={values.subject}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Conversation type" }}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      }
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{
                          paddingLeft: "17px",
                        }}
                      >
                        Conversation type
                      </option>
                      <MenuItem value="Complaints">Complaints</MenuItem>
                      <MenuItem value="Enquiry">Enquiry</MenuItem>
                    </Select>

                    {values.subject === "Enquiry" && (
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="enquiryType"
                          value={values.enquiryType}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="sell"
                            checked={values.enquiryType === "sell"}
                            control={<Radio />}
                            label="Sell"
                          />
                          <FormControlLabel
                            value="lease"
                            checked={values.enquiryType === "lease"}
                            control={<Radio />}
                            label="Lease"
                          />
                          <FormControlLabel
                            value="finance"
                            checked={values.enquiryType === "finance"}
                            control={<Radio />}
                            label="Finance"
                          />
                        </RadioGroup>
                      </FormControl>
                    )}

                    <span className="modal-input-label">Property type</span>
                    <FormControl>
                      <Select
                        size="small"
                        placeholder="Choose a property type"
                        name="propertyType"
                        value={values.propertyType}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Property type" }}
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #D1D5DB",
                          marginBottom: "20px",
                          padding: "3.5px 20px",
                          background: " #F9FAFB",
                          marginRight: "0px",
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <HomeIcon />
                          </InputAdornment>
                        }
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{
                            paddingLeft: "17px",
                          }}
                        >
                          Choose a property type
                        </option>
                        <MenuItem value="House"> House </MenuItem>
                        <MenuItem value="Land">Land</MenuItem>
                        <MenuItem value="Industrial">Industrial</MenuItem>
                        <MenuItem value="Commercial">Commercial</MenuItem>
                      </Select>
                    </FormControl>

                    <span className="modal-input-label">Location</span>
                    <OutlinedInput
                      size="small"
                      placeholder="Enter property location"
                      name="location"
                      value={values.location}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <LocationOnIcon />
                        </InputAdornment>
                      }
                    />
                    <Link
                      className="submit-botton"
                      to={"/nimble-rent-information"}
                      aria-label="nimble rent information"
                    >
                      <span className="submit-button">
                        <CustomButton
                          // label="Submit"
                          label={isSubmitting ? "Please wait" : "Submit"}
                          disabled={loading}
                          onClick={handleSubmit}
                          style={{
                            // width: "192px",
                            height: "40px",
                            padding: "8px 70px 8px 70px",
                          }}
                        />
                      </span>
                    </Link>
                  </FormControl>
                </form>
              </div>
              <div className="contact-us-circles col-lg-7">
                <div className="contact-us-circle-section">
                  <div
                    id="contact-us-big-yellow"
                    class="contact-us-circle"
                  ></div>
                  <div id="contact-us-black" class="contact-us-circle"></div>
                  <div id="contact-us-blue" class="contact-us-circle"></div>
                  <div
                    id="contact-us-small-yellow"
                    class="contact-us-circle"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
