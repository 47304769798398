import React, { useEffect, useState } from "react";
import axios from "axios";
import { ShimmerPostList } from "react-shimmer-effects";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { useAuth } from "../context/auth";
import { useSearch } from "../context/search";
// import AdCard from "../components/cards/AdCard";
import AdCard from "../components/cards/newAdCard/AdCard.js";
// import SearchForm from "../components/forms/SearchForm";
import SearchForm from "../components/newSearchForm/SearchForm.js";
import LogoutMessage from "../components/misc/logoutMessage/LogoutMessage";
import RowPerPage from "../components/rowPerPage/RowPerPage.js";

export default function Buy() {
  // context
  const [auth, setAuth] = useAuth();
  const [search, setSearch] = useSearch();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // state
  const [ads, setAds] = useState();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAds();
  }, [page, perPage]);

  useEffect(() => {
    setPage(1);
    window.scrollTo(0, 0);
  }, [perPage]);

  useEffect(() => {
    const path = window.location.pathname.split("/");

    if (path[1] === "buy") {
      search.action = "Buy";
    } else if (path[1] === "rent") {
      search.action = "Rent";
    } else search.action = "";
    search.type = "";

    setSearch((prev) => ({ ...prev, address: prev.address, loading: false }));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ads]);

  const fetchAds = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/ads-for-sell/${page}/${perPage}`);
      setAds(data?.ads);
      setTotal(data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <LogoutMessage>
        <SearchForm navMenuProperty={true} currentPage="buy" />

        <div
          className={
            windowWidth > 1500 ? "container page-wrapper" : "page-wrapper"
          }
        >
          <div className="row d-flex justify-content-center">
            {loading ? (
              <div style={{ padding: "40px 0" }}>
                <ShimmerPostList
                  postStyle="STYLE_FOUR"
                  col={3}
                  row={2}
                  gap={30}
                />
              </div>
            ) : (
              ads?.map((ad) => <AdCard ad={ad} key={ad._id} />)
            )}
            {ads?.length > 0 ? (
              <div className="row small-screen-page-navigation">
                <div className="col text-center mt-4 mb-4">
                  <Stack spacing={2}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Pagination
                        color="primary"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        variant="outlined"
                        count={Math.ceil(total / perPage)}
                        page={page}
                        onChange={handleChange}
                      />
                      <RowPerPage
                        total={total}
                        rowPerPage={perPage}
                        setRowPerPage={setPerPage}
                        setPage={setPage}
                      />
                    </div>
                  </Stack>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </LogoutMessage>
      {/* <pre>{JSON.stringify(auth, null, 4)} </pre> */}
    </div>
  );
}
