import React, { useState, useEffect, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ClearIcon from "@mui/icons-material/Clear";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import AdDetailsForm from "../../../../components/forms/adForm/AdDetailsForm.js";
import AdFeaturesForm from "../../../../components/forms/adForm/AdFeaturesForm.js";
import { useAuth } from "../../../../context/auth.js";
import Modall from "../../../../components/modal2/Modal.js";
import DynamicForm from "../../../../components/uploader/index.js";
import LogoutMessage from "../../../../components/misc/logoutMessage/LogoutMessage.js";

import AdFormMobile from "../../../../components/forms/adForm/AdFormMobile.js";

import config from "../../../../config.js";

import "./AdEdit.css";

function getSteps() {
  return ["Property Details", "Property Features", "Done"];
}

const OrangeStepIcon = (props) => {
  const { active, completed } = props;
  const containerSize = active ? 40 : 35;
  const iconSize = 30;

  return (
    <div
      style={{
        width: containerSize,
        height: containerSize,
        borderRadius: "50%",
        backgroundColor: active || completed ? "#EE7B0D" : "#FFFFFF",

        border: active ? "2px solid #EE7B0D" : "none",
        padding: active ? "4px" : "0px", // Adjust padding based on active state
        margin: "4px",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: active || completed ? "#FFFFFF" : "#DCE3E9",
        background: active ? "padding-box" : "none",
      }}
    >
      <div
        style={{
          width: iconSize,
          height: iconSize,
          borderRadius: "60%",
          backgroundColor: active || completed ? "#EE7B0D" : "#FFFFFF",
          border: `2px solid ${
            active ? "#EE7B0D" : completed ? "#EE7B0D" : "#DCE3E9"
          }`,
          padding: active ? "4px" : "0px", // Adjust padding based on active state
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "width 0.2s, height 0.2s",
          boxSizing: "border-box",
        }}
      >
        {completed ? <CheckIcon style={{ fontSize: "16px" }} /> : props.icon}
      </div>
    </div>
  );
};

const CustomTabs = ({ value, handleTabChange, tabData }) => {
  const isSmScreen = useMediaQuery("(max-width: 389px)");
  const isMdScreen = useMediaQuery("(min-width: 390px) and (max-width: 600px)");
  const isLgScreen = useMediaQuery(
    "(min-width: 601px) and (max-width: 1023px)"
  );

  const paddingStyle = isSmScreen
    ? "12px 10px"
    : isMdScreen
    ? "12px 12px"
    : "12px 20px";

  const widthStyle = isSmScreen
    ? "110px"
    : isMdScreen
    ? "120px"
    : isLgScreen
    ? "150px" // Adjust width for larger screens
    : "191px";

  const canvasRef = useRef(null);
  return (
    <Tabs
      value={value}
      onChange={handleTabChange}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
      TabIndicatorProps={{
        style: { display: "none" }, // Hide the indicator line
      }}
      orientation="horizontal" // Set orientation dynamically
    >
      {tabData.map((tab) => (
        <Tab
          key={tab.value}
          sx={{
            border: "1px solid gray",
            width: widthStyle,
            minWidth: "60px",
            height: 53,
            color: "#808080",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 500,
            padding: paddingStyle,
            lineHeight: "21px",
            ...(tab.value === "House" &&
              tab.borderRadius && {
                borderTopLeftRadius: tab.borderRadius,
                borderBottomLeftRadius: tab.borderRadius,
              }),
            ...(tab.value === "Industrial" && {
              borderTopRightRadius: tab.borderRadius,
              borderBottomRightRadius: tab.borderRadius,
            }),
            textTransform: "Capitalize",
          }}
          value={tab.value}
          label={tab.label}
          disabled={value !== tab.value} // Disable the tab if it's not active
        />
      ))}
    </Tabs>
  );
};

const AdEdit = ({ type, action }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [auth, setAuth] = useAuth();

  const [features, setFeatures] = useState([]);
  const [userId, setUserId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [value, setValue] = useState(type);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const fileRefs = useRef([]);
  const canvasRef = useRef(null);
  const [removedImages, setRemovedImages] = useState([]);
  const isSmScreen = useMediaQuery("(min-width: 300px) and (max-width: 800px)");
  const steps = getSteps();

  const [ad, setAd] = useState({
    _id: "",
    photos: [],
    uploading: false,
    propertyTitle: "",
    price: "",
    address: "",
    landmark: "",
    bedrooms: "",
    bathrooms: "",
    carpark: "",
    landsize: "",
    title: "",
    description: "",
    loading: false,
    sold: "",
    type,
    action,
    features,
    houseType: "",
  });
  const [loaded, setLoaded] = useState(false);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    fetchAd();
  }, []);

  useEffect(() => {
    if (params?.id) {
      fetchAd();
    }
  }, [params?.id]);

  useEffect(() => {
    setUserId(auth.user?.userId);
  }, []);

  const tabData = [
    { value: "House", label: "House", borderRadius: "10px" },
    { value: "Land", label: "Land" },
    {
      value: "Commercial",
      label: "Commercial",
    },
    {
      value: "Industrial",
      label: "Industrial",
      borderRadius: "10px",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    getFeature(newValue);
    setAd({ ...ad, type: newValue });
  };

  const fetchAd = async () => {
    try {
      const { data } = await axios.get(`/ad/${params.id}`);
      setAd(data?.ad);
      setFormData(
        data?.ad.photos.map((item) => {
          return {
            text: item?.Key,
            image: item?.Location,
            key: item?.key,
            blob: null,
          };
        })
      );
      setLoaded(true);
    } catch (err) {
      // console.log(err);
    }
  };

  const getFeature = async (type) => {
    setLoading(true);
    setFeatures([]);
    const { data } = await axios.get(`${config.API}/adFeature/${type}`);
    if (!data) {
      setLoading(false);
    } else {
      setFeatures(data?.features ? data?.features : []);
      setLoading(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const isButtonDisabled = () => {
    if (activeStep === 0) {
      // Check if any required fields in property details form are empty
      return !ad.propertyTitle || !ad.address || !ad.landmark || !ad.price;
    } else if (activeStep === 1) {
      return (
        (ad.type === "House" && (!ad.type || !ad.bedrooms || !ad.bathrooms)) ||
        (ad.type !== "House" && !ad.landsize)
      );
    } else {
      return false;
    }
  };

  const handleClick = async () => {
    try {
      if (!formData[0]?.image) {
        toast.error("Upload some photos");
        return;
      } else if (!ad.address) {
        toast.error("Address is required");
        return;
      } else if (!ad.landmark) {
        toast.error("landmark Address/location is required");
        return;
      } else if (!ad.price) {
        toast.error("Price is required");
        return;
      } else if (!ad.propertyTitle) {
        toast.error("Property title is required e.g 4 Bedroom Duplex");
        return;
      } else if (ad.type === "House" && (!ad.bedrooms || ad.bedrooms < 1)) {
        toast.error("No. of Bedrooms is required");
        return;
      } else if (ad.type === "House" && (!ad.bathrooms || ad.bathrooms < 1)) {
        toast.error("No. of Bathrooms is required");
        return;
      } else if (ad.type === "House" && !ad.carpark) {
        toast.error("No. of Carpark is required");
        return;
      } else if (ad.action === "Sell" && !ad.landsize) {
        toast.error("property landsize is required");
        return;
      } else if (ad.action === "Sell" && !ad.title) {
        toast.error("document Title is required");
        return;
      } else {
        setAd({ ...ad, loading: true });

        await processImageDeletions(removedImages);
        const uploadedPhotos = await uploadImages();

        const { data } = await axios.put(`/ad/${ad._id}/${userId}`, {
          ...ad,
          photos: uploadedPhotos,
        });
        if (data?.error) {
          toast.error(data.error);
          setAd({ ...ad, loading: false });
        } else {
          toast.success("Ad updated successfully");
          localStorage.removeItem("adData");
          setAd({ ...ad, loading: false });
          navigate("/dashboard");
        }
      }
    } catch (err) {
      // console.log(err);
      setAd({ ...ad, loading: false });
    }
  };

  async function uploadImages() {
    return new Promise(async (resolve, reject) => {
      if (formData.length === 0) {
        resolve();
        return;
      }
      try {
        setLoading(true);
        const files = formData;
        if (files?.length) {
          setAd((prev) => ({ ...prev, uploading: true }));
          const uploadedPhotos = await Promise.all(
            files.map(async (file) => {
              // console.log("fileeee", file);
              if (file.blob === null) {
                return {
                  Key: file.text,
                  Location: file.image,
                  key: file.key,
                };
              }
              return new Promise(async (innerResolve) => {
                const image = new Image();
                image.src = URL.createObjectURL(file.blob);
                image.onload = async () => {
                  const canvas = canvasRef.current;
                  const context = canvas.getContext("2d");
                  const newWidth = 1080;
                  const newHeight = 720;
                  // Resize the image using canvas
                  canvas.width = newWidth;
                  canvas.height = newHeight;
                  context.drawImage(image, 0, 0, newWidth, newHeight);
                  // Convert the canvas content back to base64
                  const resizedImage = canvas.toDataURL("image/jpeg", 1.0);
                  try {
                    const { data } = await axios.post("/upload-image", {
                      file: resizedImage,
                      label: file.text,
                    });
                    innerResolve(data);
                  } catch (err) {
                    // console.log(err);
                    innerResolve(null);
                  }
                };
              });
            })
          );
          // Processing after upload
          const filteredPhotos = uploadedPhotos.filter(Boolean);
          // console.log("filtered photos", filteredPhotos);
          // console.log("adddd   photos", ad.photos);
          const uniquePhotos = Array.from(
            new Set([
              ...ad.photos.map((photo) => photo?.Location),
              ...filteredPhotos.map((photo) => photo?.Location),
            ])
          ).map((location) =>
            filteredPhotos.find((photo) => photo?.Location === location)
          );
          //         photos: prev.photos.filter((p) => p.Key !== file.Key),
          const cleansedUniquePhotos = uniquePhotos?.filter(
            (photo) => photo !== undefined
          );
          setAd((prev) => ({
            ...prev,
            photos: cleansedUniquePhotos,
            uploading: false,
          }));
          // console.log("unique photos", uniquePhotos);
          // console.log("cleansed photos", cleansedUniquePhotos);
          setLoading(false);
          resolve(cleansedUniquePhotos);
        }
      } catch (err) {
        // console.log(err);
        setAd((prev) => ({ ...prev, uploading: false }));
        setLoading(false);
        reject(err);
      }
    });
  }

  async function processImageDeletions(imagesToRemove) {
    if (imagesToRemove.length === 0) {
      // console.log("No images to remove.");
      return;
    }

    // Filter images that need to be deleted from the backend
    const imagesToDeleteFromBackend = imagesToRemove.filter(
      (image) => !image.blob
    );

    // console.log("images to remove", removedImages);
    // console.log("images to remove from backend", imagesToDeleteFromBackend);

    if (imagesToDeleteFromBackend.length === 0) {
      // console.log("No backend images to remove.");
      return;
    }

    try {
      setLoading(true);
      const deletionResults = await Promise.all(
        imagesToDeleteFromBackend.map(async (image) => {
          try {
            const { data } = await axios.post("/remove-image", {
              key: image.key || image.image, // Adjust based on your image object structure
            });
            return { success: true, key: image.key || image.image, data };
          } catch (err) {
            // console.error(
            //   "Deletion error for image:",
            //   image.key || image.image,
            //   err
            // );
            return {
              success: false,
              key: image.key || image.image,
              error: err,
            };
          }
        })
      );

      // Optional: Process results, e.g., remove successfully deleted images from state
      const successfullyDeletedKeys = deletionResults
        .filter((result) => result.success)
        .map((result) => result.key);
      // console.log("Successfully deleted images:", successfullyDeletedKeys);
      // Update any state or UI here as necessary

      setLoading(false);
    } catch (error) {
      // console.error("Failed to process image deletions:", error);
      setLoading(false);
    }
  }

  const handleSingleImageDelete = async (file, index) => {
    // console.log("file>>>", file);
    const answer = window.confirm("Delete image?");
    if (!answer) return;
    setAd({ ...ad, uploading: true });
    try {
      const { data } = await axios.post("/remove-image", {
        key: file.key, // Adjust based on your image object structure
      });
      if (data?.ok) {
        setRemovedImages((prev) => [...prev, formData[index]]);
        const newFormData = formData.filter((_, i) => i !== index);
        setFormData(newFormData);
        return { success: true, key: image.key || image.image, data };
      }
    } catch (err) {
      console.log(err);
      setAd({ ...ad, uploading: false });
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <LogoutMessage>
        <Modall handleClose={() => setIsOpen(false)} isOpen={isOpen}>
          <DynamicForm
            formData={formData}
            setFormData={setFormData}
            fileRefs={fileRefs}
            ad={ad}
            removedImages={removedImages}
            setRemovedImages={setRemovedImages}
            // canvasRef={canvasRef}
            setAd={setAd}
            setIsOpen={setIsOpen}
          />
        </Modall>
        {!isSmScreen ? (
          <div className="main-edit-ad-container">
            <div className="create-ad-container">
              <div className="create-ad-title-and-stepper-container">
                <div className="create-ad-title-container">
                  <h1 className="create-ad-title">
                    Edit Ad for {ad?.action === "Sell" ? "Sale" : ad?.action}
                  </h1>
                </div>
                <Box sx={{ width: "100%" }}>
                  <div className="horizontal-stepper">
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel
                              StepIconComponent={OrangeStepIcon}
                              {...labelProps}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </div>
                </Box>
              </div>

              <div className="create-ad-form-container">
                <div className="create-ad-form-title">
                  <h2 className="ad-form-title-text">Property type</h2>
                </div>
                <div className="">
                  <CustomTabs
                    value={ad.type}
                    handleTabChange={handleTabChange}
                    tabData={tabData}
                  />
                </div>

                <div className="adform-left-section-wrapper">
                  {activeStep === 0 && (
                    <AdDetailsForm
                      ad={ad}
                      setAd={setAd}
                      type={type}
                      page={"edit"}
                    />
                  )}
                  {(activeStep === 1 || activeStep === 2) && (
                    <AdFeaturesForm ad={ad} setAd={setAd} type={type} />
                  )}
                  <div className="submit-next-button left-align">
                    <div className="adform-buttons">
                      {activeStep !== 0 && (
                        <Button
                          className="outlined-button "
                          variant="contained"
                          onClick={handlePrevious}
                          style={{
                            width: "175px",
                            height: "40px",
                            textTransform: "Capitalize",
                            backgroundColor: "white",
                            border: "2px solid #EE7B0D",
                            boxShadow: "none",
                            color: "#EE7B0D",
                            fontFamily: "Figtree",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "22px",
                            letterSpacing: "0em",
                            textAlign: "left",
                          }}
                        >
                          Previous
                        </Button>
                      )}

                      <Button
                        className="custom-button"
                        variant="contained"
                        onClick={
                          activeStep === steps.length - 1
                            ? handleClick
                            : handleNext
                        }
                        disabled={isButtonDisabled() || ad.loading}
                        style={{
                          width: "175px",
                          height: "40px",
                          textTransform: "Capitalize",
                          boxShadow: "none",
                          fontFamily: "Figtree",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "22px",
                          letterSpacing: "0em",
                          textAlign: "left",
                          background: "#EE7B0D",
                          color: "white",
                        }}
                      >
                        {activeStep === steps.length - 1
                          ? ad?.loading
                            ? "Submitting"
                            : "Submit"
                          : "Next"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Upload Photos form */}
            <div
              className={
                ad.type !== "House" && activeStep !== 0
                  ? "other-upload-photos-container"
                  : "upload-photos-container"
              }
            >
              <div className="photo-frames-container">
                {formData?.map((file, index) => (
                  <div key={index} style={{ position: "relative" }}>
                    {/* {file.length > 1 && ( */}
                    {/* <ClearIcon
                      onClick={() => handleSingleImageDelete(file, index)}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 10000,
                      }}
                    /> */}
                    {/* )} */}
                    <Avatar
                      sx={{ width: 50, height: 50 }}
                      alt="photo"
                      variant="rounded"
                      src={file?.image}
                      shape="square"
                      className="my-2"
                    />
                  </div>
                ))}
              </div>
              {/* Upload Photos button */}
              <div className="photo-button-container">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#EE7B0D",
                    color: "white",
                    boxShadow: "none",
                    textTransform: "Capitalize",
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  Upload Photos
                </Button>
              </div>
            </div>

            {/* <pre>{JSON.stringify(ad, null, 4)}</pre> */}
          </div>
        ) : (
          <AdFormMobile
            ad={ad}
            setAd={setAd}
            type={type}
            action={action}
            formData={formData}
            setFormData={setFormData}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            onClick={handleClick}
            page={"edit"}
          />
        )}
      </LogoutMessage>
    </div>
  );
};

export default AdEdit;
