import React from "react";
import { Link } from "react-router-dom";

import "./NimbleRentReferralBanner.css";
import CustomButton from "../customButton/CustomButton";

const NimbleRentBottomBannerMobile = () => {
  return (
    <div className="nimble-rent-referral-banner-container">
      <span className="nimble-rent-referral-banner-left-column">
        <div className="referral-banner-texts">
          <div className="referral-banner-text">Join our Referral Program</div>
          <div className="referral-banner-text">
          <h4 className="referral-banner-word-moblie">
              You can earn <span className="referral-banner-text2">competitive commissions</span> for every successful property
              sale by referring potential buyer
            </h4>
          </div>
        </div>

        <div>
          <Link
            to={"/referral-page"}
            aria-label="nimble rent information"
          >
            <button className="referral-check">Sign-Up Now</button>
          </Link>
        </div>
      </span>
      <span className="nimble-rent-bottom-banner-right-column">
        <img
          src="./referralImage.png"
          alt=""
          width={200}
          height={200}
        />
      </span>
    </div>
  );
};

export default NimbleRentBottomBannerMobile;
