import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";

import Select from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import ClearIcon from "@mui/icons-material/Clear";
import { FaStickyNote } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";

import { useAuth } from "../../../context/auth";
import { useData } from "../../../context/adData";
import { houseType } from "../../../helpers/houseType";
import config from "../../../config.js";

import "./AdForm.css";

function PropertyFeaturesForm({ ad, setAd, type }) {
  const [auth, setAuth] = useAuth();
  const [ddata, setDdata] = useData();
  const [removedImages, setRemovedImages] = useState([]);

  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [feature, setFeature] = useState([]);
  const [features, setFeatures] = useState([]);
  const [formData, setFormData] = useState([
    { text: "Sitting room", image: null, blob: null },
    { text: "Bedroom", image: null, blob: null },
    { text: "Compound", image: null, blob: null },
  ]);

  const [selectOptions, setSelectOptions] = useState(houseType);

  const isSmScreen = useMediaQuery("(max-width: 768px)");

  const fileRefs = useRef([]);
  // hooks
  const navigate = useNavigate();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // console.log(ad);

  // useEffect(() => {
  //   if (ad) {
  //     setDdata(ad);
  //   }
  // }, [ad]);

  useEffect(() => {
    if (auth.user) {
      setRole(auth.user?.role);
    }
    if (ddata.features) {
      setFeatures(ddata.features);
    } else {
      if (ad.type) {
        getFeature(ad.type);
      }
    }
  }, []);

  useEffect(() => {
    if (ad.type) {
      getFeature(ad.type);
    }
  }, [ad.type]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setFeature(typeof value === "string" ? value.split(",") : value);
    setAd({ ...ad, features: value });
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  // };

  // console.log(selectedAdType);

  const getFeature = async (type) => {
    setLoading(true);
    setFeatures(ddata.features);
    const { data } = await axios.get(`${config.API}/adFeature/${type}`);
    // console.log("data", data);
    if (!data) {
      setLoading(false);
    } else {
      setFeatures(data.features);
      setLoading(false);
    }
  };

  const handleHouseTypeSelectChange = (event) => {
    const value = event.target.value;
    setAd({ ...ad, houseType: value });
  };

  const handleClickClear = (fieldName) => {
    setAd((prevValues) => {
      const updatedValues = { ...prevValues, [fieldName]: "" };
      // console.log("up values", updatedValues); // Log updated values
      return updatedValues;
    });
  };

  // console.log("type>>>>>", ad.type);

  return (
    <div className="property-details-form-container left-align">
      {!isSmScreen && (
        <div className="property-details-title-container">
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", textAlign: "left" }}
          >
            Property Features
          </Typography>
        </div>
      )}

      <div className="property-details-form">
        {ad.type === "House" && (
          <FormControl sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", textAlign: "left" }}
            >
              House Type
              {ad?.type === "House" && (
                <StarIcon sx={{ color: "red", fontSize: 12 }} />
              )}
            </Typography>
            <Select
              displayEmpty
              value={ad.houseType}
              onChange={handleHouseTypeSelectChange}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "52px",
                background: "#F9FAFB",
                border: "1px solid #D1D5DB",
                padding: "25px 4px",
                borderRadius: "8px",
              }}
            >
              <MenuItem value="" disabled>
                Select {ad.type} type
              </MenuItem>
              {selectOptions &&
                selectOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        {ad.type === "House" && (
          <>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", textAlign: "left" }}
            >
              Number of Bedrooms
              {ad?.type === "House" && (
                <StarIcon sx={{ color: "red", fontSize: 12 }} />
              )}
            </Typography>

            <OutlinedInput
              size="small"
              placeholder="e.g 1"
              name="ad.bedrooms"
              value={ad.bedrooms}
              onChange={(e) => setAd({ ...ad, bedrooms: e.target.value })}
              sx={{
                borderRadius: "8px",
                border: "1px solid #D1D5DB",
                marginBottom: "20px",
                padding: "3.5px 20px",
                background: " #F9FAFB",
                marginRight: "0px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear text"
                    onClick={() => {
                      handleClickClear("bedrooms");
                    }}
                    edge="end"
                  >
                    <ClearIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                </InputAdornment>
              }
            />

            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", textAlign: "left" }}
            >
              Number of Bathrooms/Toilet{" "}
              {ad?.type === "House" && (
                <StarIcon sx={{ color: "red", fontSize: 12 }} />
              )}
            </Typography>

            <OutlinedInput
              size="small"
              placeholder="e.g 1"
              name="ad.bathrooms"
              value={ad.bathrooms}
              onChange={(e) => setAd({ ...ad, bathrooms: e.target.value })}
              sx={{
                borderRadius: "8px",
                border: "1px solid #D1D5DB",
                marginBottom: "20px",
                padding: "3.5px 20px",
                background: " #F9FAFB",
                marginRight: "0px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear text"
                    onClick={() => {
                      handleClickClear("bathrooms");
                    }}
                    edge="end"
                  >
                    <ClearIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                </InputAdornment>
              }
            />

            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", textAlign: "left" }}
            >
              Number of Carparks{" "}
              {ad?.type === "House" && (
                <StarIcon sx={{ color: "red", fontSize: 12 }} />
              )}
            </Typography>

            <OutlinedInput
              size="small"
              placeholder="e.g 1"
              name="ad.carpark"
              value={ad.carpark}
              onChange={(e) => setAd({ ...ad, carpark: e.target.value })}
              sx={{
                borderRadius: "8px",
                border: "1px solid #D1D5DB",
                marginBottom: "20px",
                padding: "3.5px 20px",
                background: " #F9FAFB",
                marginRight: "0px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear text"
                    onClick={() => {
                      handleClickClear("carpark");
                    }}
                    edge="end"
                  >
                    <ClearIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </>
        )}

        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", textAlign: "left" }}
        >
          Size of Property in sqft/sqm{" "}
          {ad?.type !== "House" && (
            <StarIcon sx={{ color: "red", fontSize: 12 }} />
          )}
        </Typography>

        <OutlinedInput
          size="small"
          placeholder="e.g 1"
          name="ad.landsize"
          value={ad.landsize}
          onChange={(e) => setAd({ ...ad, landsize: e.target.value })}
          sx={{
            borderRadius: "8px",
            border: "1px solid #D1D5DB",
            marginBottom: "20px",
            padding: "3.5px 20px",
            background: " #F9FAFB",
            marginRight: "0px",
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear text"
                onClick={() => {
                  handleClickClear("landsize");
                }}
                edge="end"
              >
                <ClearIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </InputAdornment>
          }
        />

        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", textAlign: "left" }}
        >
          Select Extra Features
        </Typography>
        <FormControl sx={{ width: "100%", marginBottom: "56px" }}>
          <Select
            id="demo-multiple-checkbox"
            displayEmpty
            sx={{
              height: "52px",
              background: "#F9FAFB",
              border: "1px solid #D1D5DB",
              padding: "25px 4px",
              borderRadius: "8px",
            }}
            multiple
            value={ad.features || []}
            onChange={handleSelectChange}
            renderValue={(selected) => {
              if (!selected || selected.length === 0) {
                return "Select extra features";
              }

              return selected.join(", ");
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {features?.map((feat, index) => (
              <MenuItem key={index} value={feat.feature}>
                <Checkbox
                  checked={
                    ddata.features
                      ? ddata.features.indexOf(feat.feature) > -1
                      : feature.indexOf(feat.feature) > -1
                  }
                />
                <ListItemText primary={feat.feature} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {isSmScreen && (
          <div className="mt-3">
            <FormControl sx={{ width: "100%" }}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Property Description{" "}
                <StarIcon sx={{ color: "red", fontSize: 15 }} />
              </Typography>

              <OutlinedInput
                // size="small"
                placeholder="Add additional features/description"
                name="description"
                value={ad.description}
                onChange={(e) => setAd({ ...ad, description: e.target.value })}
                multiline
                rows={5}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #D1D5DB",
                  marginBottom: "20px",
                  padding: "3.5px 20px",
                  background: " #F9FAFB",
                  marginRight: "0px",
                  paddingTop: "20px",
                  alignItems: "start",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <FaStickyNote />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear text"
                      onClick={() => {
                        handleClickClear("description");
                      }}
                      edge="end"
                    >
                      <ClearIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertyFeaturesForm;
