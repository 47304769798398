import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import AdDetailsForm from "./AdDetailsForm";
import AdFeaturesForm from "./AdFeaturesForm";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import { useAuth } from "../../../context/auth";
import { useData } from "../../../context/adData";
import Modall from "../../modal2/Modal";
import DynamicForm from "../../uploader";
import LogoutMessage from "../../misc/logoutMessage/LogoutMessage";
import AdFormMobile from "./AdFormMobile.js";
// import CustomTabs from "../../customTabs/CustomTab.js";

import config from "../../../config.js";

import "./AdForm.css";

function getSteps() {
  return ["Property Details", "Property Features", "Done"];
}

const OrangeStepIcon = (props) => {
  const { active, completed } = props;
  const containerSize = active ? 40 : 35;
  const iconSize = 30;

  return (
    <div
      style={{
        width: containerSize,
        height: containerSize,
        borderRadius: "50%",
        backgroundColor: active || completed ? "#EE7B0D" : "#FFFFFF",

        border: active ? "2px solid #EE7B0D" : "none",
        padding: active ? "4px" : "0px", // Adjust padding based on active state
        margin: "4px",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: active || completed ? "#FFFFFF" : "#DCE3E9",
        background: active ? "padding-box" : "none",
      }}
    >
      <div
        style={{
          width: iconSize,
          height: iconSize,
          borderRadius: "60%",
          backgroundColor: active || completed ? "#EE7B0D" : "#FFFFFF",
          border: `2px solid ${
            active ? "#EE7B0D" : completed ? "#EE7B0D" : "#DCE3E9"
          }`,
          padding: active ? "4px" : "0px", // Adjust padding based on active state
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "width 0.2s, height 0.2s",
          boxSizing: "border-box",
        }}
      >
        {completed ? <CheckIcon style={{ fontSize: "16px" }} /> : props.icon}
      </div>
    </div>
  );
};

const CustomTabs = ({ value, handleTabChange, tabData }) => {
  const isSmScreen = useMediaQuery("(max-width: 389px)");
  const isMdScreen = useMediaQuery("(min-width: 390px) and (max-width: 600px)");
  const isLgScreen = useMediaQuery(
    "(min-width: 601px) and (max-width: 1023px)"
  );

  const paddingStyle = isSmScreen
    ? "12px 10px"
    : isMdScreen
    ? "12px 12px"
    : "12px 20px";

  const widthStyle = isSmScreen
    ? "110px"
    : isMdScreen
    ? "120px"
    : isLgScreen
    ? "150px" // Adjust width for larger screens
    : "191px";

  return (
    <Tabs
      value={value}
      onChange={handleTabChange}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
      TabIndicatorProps={{
        style: { display: "none" }, // Hide the indicator line
      }}
      orientation="horizontal" // Set orientation dynamically
    >
      {tabData.map((tab) => (
        <Tab
          key={tab.value}
          sx={{
            border: "1px solid gray",
            width: widthStyle,
            minWidth: "60px",
            height: 53,
            color: "#808080",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 500,
            padding: paddingStyle,
            lineHeight: "21px",
            ...(tab.value === "House" &&
              tab.borderRadius && {
                borderTopLeftRadius: tab.borderRadius,
                borderBottomLeftRadius: tab.borderRadius,
              }),
            ...(tab.value === "Industrial" && {
              borderTopRightRadius: tab.borderRadius,
              borderBottomRightRadius: tab.borderRadius,
            }),
            textTransform: "Capitalize",
          }}
          value={tab.value}
          label={tab.label}
        />
      ))}
    </Tabs>
  );
};

function AdForm({ action, type }) {
  const [activeStep, setActiveStep] = useState(0);
  const [auth, setAuth] = useAuth();
  const [ddata, setDdata] = useData();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(type);
  const [isOpen, setIsOpen] = useState(false);
  const [removedImages, setRemovedImages] = useState([]);
  const [features, setFeatures] = useState([]);
  const [formData, setFormData] = useState([
    { text: "Sitting room", image: null, blob: null },
    { text: "Bedroom", image: null, blob: null },
    { text: "Compound", image: null, blob: null },
  ]);

  const [checked, setChecked] = useState(false); // State for checkbox
  const steps = getSteps();

  const isSmScreen = useMediaQuery("(min-width: 300px) and (max-width: 800px)");

  const fileRefs = useRef([]);
  // hooks
  const navigate = useNavigate();

  const tabData = [
    { value: "House", label: "House", borderRadius: "10px" },
    { value: "Land", label: "Land" },
    {
      value: "Commercial",
      label: "Commercial",
    },
    {
      value: "Industrial",
      label: "Industrial",
      borderRadius: "10px",
    },
  ];

  const [ad, setAd] = useState({
    photos: ddata.photos || [],
    uploading: false,
    propertyTitle: ddata.propertyTitle || "",
    price: ddata.price || "",
    areaPerPrice: ddata.areaPerPrice || "",
    address: ddata.address || "",
    landmark: ddata.landmark || "",
    bedrooms: ddata.bedrooms || "",
    bathrooms: ddata.bathrooms || "",
    carpark: ddata.carpark || "",
    landsize: ddata.landsize || "",
    title: ddata.title || "",
    description: ddata.description || "",
    loading: false,
    type: type,
    action: action,
    postedBy: auth.user.userId,
    features: features,
    houseType: ddata.houseType || "",
  });

  useEffect(() => {
    if (ad) {
      setDdata(ad);
    }
  }, [ad]);

  useEffect(() => {
    if (ddata.features) {
      setFeatures(ddata.features);
    } else {
      if (ad.type) {
        getFeature(ad.type);
      }
    }
  }, []);

  useEffect(() => {
    if (ad.type) {
      getFeature(ad.type);
    }
  }, [ad.type]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    getFeature(newValue);
    setAd({ ...ad, type: newValue });
  };

  const handleTermsAndPolicyCheck = () => {
    setChecked((prev) => !prev); // Toggle checkbox state
  };

  const getFeature = async (type) => {
    // console.log("here");
    setLoading(true);
    setFeatures(ddata.features);
    const { data } = await axios.get(`${config.API}/adFeature/${type}`);
    if (!data) {
      setLoading(false);
    } else {
      // console.log(data);

      setFeatures(data.features);
      setLoading(false);
    }
  };

  const handleClick = async () => {
    try {
      if (!formData[0]?.image) {
        toast.error("Upload some photos");
        return;
      } else if (!ad.address) {
        toast.error("Address is required");
        return;
      } else if (!ad.landmark) {
        toast.error("landmark Address/location is required");
        return;
      } else if (!ad.price) {
        toast.error("Price is required");
        return;
      } else if (!ad.propertyTitle) {
        toast.error("Property title is required e.g 4 Bedroom Duplex");
        return;
      } else if (ad.type === "House" && (!ad.bedrooms || ad.bedrooms < 1)) {
        toast.error("No. of Bedrooms is required");
        return;
      } else if (ad.type === "House" && (!ad.bathrooms || ad.bathrooms < 1)) {
        toast.error("No. of Bathrooms is required");
        return;
      } else if (ad.type === "House" && !ad.carpark) {
        toast.error("No. of Carpark is required");
        return;
      } else if (ad.action === "Sell" && !ad.landsize) {
        toast.error("property landsize is required");
        return;
      } else if (ad.action === "Sell" && !ad.title) {
        toast.error("document Title is required");
        return;
      } else {
        setAd({ ...ad, loading: true });

        await processImageDeletions();
        const uploadedPhotos = await uploadImages();

        // console.log("uploadedPhotos>>>", uploadedPhotos);
        //price validation

        const { data } = await axios.post("/ad", {
          ...ad,
          photos: uploadedPhotos,
        });
        if (data?.error) {
          toast.error(data.error);
          setAd({ ...ad, loading: false });
        } else {
          toast.success("Ad created successfully");

          setDdata({ adData: null });
          localStorage.removeItem("adData");

          setAd({ ...ad, loading: false });

          // const adId = { adID: data.ad._id };
          // navigate("/payment/paystack/paystack", { state: adId });
          // navigate(`/adview/${ad.houseType}`);
          navigate("/dashboard");
        }
      }
    } catch (err) {
      // console.log(err);
      setAd({ ...ad, loading: false });
    }
  };

  const canvasRef = useRef(null);

  async function uploadImages() {
    // console.log("uploadImages", ad.photos);
    return new Promise(async (resolve, reject) => {
      if (formData.length === 0) {
        resolve();
        return;
      }
      try {
        setLoading(true);
        const files = formData;
        if (files?.length) {
          setAd((prev) => ({ ...prev, uploading: true }));
          const uploadedPhotos = await Promise.all(
            files.map(async (file) => {
              if (file.blob === null) {
                return {
                  Key: file.text,
                  Location: file.image,
                };
              }
              return new Promise(async (innerResolve) => {
                const image = new Image();
                image.src = URL.createObjectURL(file.blob);
                image.onload = async () => {
                  const canvas = canvasRef.current;
                  const context = canvas.getContext("2d");
                  const newWidth = 1080;
                  const newHeight = 720;
                  // Resize the image using canvas
                  canvas.width = newWidth;
                  canvas.height = newHeight;
                  context.drawImage(image, 0, 0, newWidth, newHeight);
                  // Convert the canvas content back to base64
                  const resizedImage = canvas.toDataURL("image/jpeg", 1.0);
                  try {
                    const { data } = await axios.post("/upload-image", {
                      file: resizedImage,
                      label: file.text,
                    });
                    innerResolve(data);
                  } catch (err) {
                    // console.log(err);
                    innerResolve(null);
                  }
                };
              });
            })
          );
          // Processing after upload
          const filteredPhotos = uploadedPhotos.filter(Boolean);
          const uniquePhotos = Array.from(
            new Set([
              ...ad.photos.map((photo) => photo.Location),
              ...filteredPhotos.map((photo) => photo.Location),
            ])
          ).map((location) =>
            filteredPhotos.find((photo) => photo.Location === location)
          );
          setAd((prev) => ({
            ...prev,
            photos: uniquePhotos,
            uploading: false,
          }));
          // console.log("unique photos", uniquePhotos);
          setLoading(false);
          resolve(uniquePhotos);
        }
      } catch (err) {
        // console.log(err);
        setAd((prev) => ({ ...prev, uploading: false }));
        setLoading(false);
        reject(err);
      }
    });
  }

  async function processImageDeletions() {
    if (removedImages.length === 0) {
      // console.log("No images to remove.");
      return;
    }

    // Filter images that need to be deleted from the backend
    const imagesToDeleteFromBackend = removedImages.filter(
      (image) => !image.blob
    );

    // console.log("images to remove", removedImages);
    // console.log("images to remove from backend", imagesToDeleteFromBackend);

    if (imagesToDeleteFromBackend.length === 0) {
      // console.log("No backend images to remove.");
      return;
    }

    try {
      setLoading(true);
      const deletionResults = await Promise.all(
        imagesToDeleteFromBackend.map(async (image) => {
          try {
            const { data } = await axios.post("/remove-image", {
              key: image.key || image.image, // Adjust based on your image object structure
            });
            return { success: true, key: image.key || image.image, data };
          } catch (err) {
            return {
              success: false,
              key: image.key || image.image,
              error: err,
            };
          }
        })
      );

      // Optional: Process results, e.g., remove successfully deleted images from state
      const successfullyDeletedKeys = deletionResults
        .filter((result) => result.success)
        .map((result) => result.key);
      // console.log("Successfully deleted images:", successfullyDeletedKeys);
      // Update any state or UI here as necessary

      setLoading(false);
    } catch (error) {
      // console.error("Failed to process image deletions:", error);
      setLoading(false);
    }
  }

  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleNext = () => {
    if (
      !ad.address ||
      !ad.landmark ||
      !ad.price ||
      !ad.propertyTitle ||
      ((ad.action === "Sell" || ad?.type === "Land") && !ad.title) ||
      (activeStep === 1 && ad?.type !== "House" && !ad.landsize) ||
      (activeStep === 1 && ad?.type === "House" && !ad.bedrooms) ||
      (activeStep === 1 && ad?.type === "House" && !ad.bathrooms)
    ) {
      toast.error("One or more fields required");
      return;
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const isButtonDisabled = () => {
    if (activeStep === 0) {
      // Check if any required fields in property details form are empty
      return !ad.propertyTitle || !ad.address || !ad.landmark || !ad.price;
    } else if (activeStep === 1) {
      return (
        (ad.type === "House" && (!ad.type || !ad.bedrooms || !ad.bathrooms)) ||
        (ad.type !== "House" && !ad.landsize)
      );
    } else {
      return false;
    }
  };

  return (
    <div>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <LogoutMessage>
        <Modall handleClose={() => setIsOpen(false)} isOpen={isOpen}>
          <DynamicForm
            formData={formData}
            setFormData={setFormData}
            fileRefs={fileRefs}
            ad={ad}
            removedImages={removedImages}
            setRemovedImages={setRemovedImages}
            // canvasRef={canvasRef}
            setAd={setAd}
            setIsOpen={setIsOpen}
          />
        </Modall>
        {!isSmScreen ? (
          <div className="main-create-ad-container">
            <div className="create-ad-container">
              <div className="create-ad-title-and-stepper-container">
                <div className="create-ad-title-container">
                  <h1 className="create-ad-title">
                    Create an Ad for {action === "Sell" ? "Sale" : action}
                  </h1>
                </div>
                <Box sx={{ width: "100%" }}>
                  <div className="horizontal-stepper">
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel
                              StepIconComponent={OrangeStepIcon}
                              {...labelProps}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </div>
                </Box>
              </div>

              <div className="create-ad-form-container">
                <div className="create-ad-form-title">
                  <h2 className="ad-form-title-text">Choose Ad type</h2>
                </div>
                <div className="">
                  <CustomTabs
                    value={value}
                    handleTabChange={handleTabChange}
                    tabData={tabData}
                  />
                </div>

                <div className="adform-left-section-wrapper">
                  {activeStep === 0 && (
                    <AdDetailsForm ad={ad} setAd={setAd} type={type} />
                  )}

                  {(activeStep === 1 || activeStep === 2) && (
                    <AdFeaturesForm ad={ad} setAd={setAd} type={type} />
                  )}

                  <div className="submit-next-button left-align">
                    {activeStep === steps.length - 1 && (
                      <div className="mb-3 left-align">
                        <Checkbox
                          checked={checked}
                          onChange={handleTermsAndPolicyCheck}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        By submitting this form I agree to the{" "}
                        <Link className="text-primary" to="/seller-terms">
                          Terms of Use
                        </Link>{" "}
                      </div>
                    )}

                    <div className="adform-buttons">
                      {activeStep !== 0 && (
                        <Button
                          className="outlined-button "
                          variant="contained"
                          onClick={handlePrevious}
                          style={{
                            width: "175px",
                            height: "40px",
                            textTransform: "Capitalize",
                            backgroundColor: "white",
                            border: "2px solid #EE7B0D",
                            boxShadow: "none",
                            color: "#EE7B0D",
                            fontFamily: "Figtree",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "22px",
                            letterSpacing: "0em",
                            textAlign: "left",
                          }}
                        >
                          Previous
                        </Button>
                      )}

                      <Button
                        className="custom-button"
                        variant="contained"
                        onClick={() => {
                          if (!(ad?.loading || isButtonDisabled())) {
                            if (activeStep === steps.length - 1) {
                              handleClick();
                            } else {
                              handleNext();
                            }
                          }
                        }}
                        disabled={ad?.loading || isButtonDisabled()}
                        style={{
                          width: "175px",
                          height: "40px",
                          textTransform: "Capitalize",
                          boxShadow: "none",
                          fontFamily: "Figtree",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "22px",
                          letterSpacing: "0em",
                          textAlign: "left",
                          backgroundColor:
                            activeStep === steps.length - 1
                              ? checked
                                ? "#EE7B0D"
                                : "#DCE3E9"
                              : isButtonDisabled()
                              ? "#DCE3E9"
                              : "#EE7B0D",

                          color:
                            activeStep === steps.length - 1
                              ? "white"
                              : isButtonDisabled()
                              ? "grey"
                              : "white",
                        }}
                      >
                        {activeStep === steps.length - 1
                          ? ad?.loading
                            ? "Submitting"
                            : "Submit"
                          : "Next"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Upload Photos form */}
            <div
              className={
                ad.type !== "House" && activeStep !== 0
                  ? "other-upload-photos-container"
                  : "upload-photos-container"
              }
            >
              <div className="photo-frames-container">
                {formData?.map((file, index) => (
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      sx={{ width: 50, height: 50 }}
                      alt="Photo"
                      variant="rounded"
                      key={index}
                      src={file?.image}
                      shape="square"
                      className="my-2"
                    />
                  </Stack>
                ))}
              </div>
              {/* Upload Photos button */}
              <div className="photo-button-container">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#EE7B0D",
                    color: "white",
                    boxShadow: "none",
                    textTransform: "Capitalize",
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  Upload Photos
                </Button>
              </div>
            </div>

            {/* <pre>{JSON.stringify(ad, null, 4)}</pre> */}
          </div>
        ) : (
          <AdFormMobile
            ad={ad}
            setAd={setAd}
            type={type}
            action={action}
            formData={formData}
            setFormData={setFormData}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            onClick={handleClick}
          />
        )}
      </LogoutMessage>
    </div>
  );
}

export default AdForm;
