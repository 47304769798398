import React from "react";
import "./CustomButton.css";

// sample of how to use this reusable component

/* <div className="">
          <CustomButton 
          label="Customed Button" 
          labelClassName="contact-agent"
          
          />
          <span>{"  "}</span>

          <CustomButton
            className="outlined-button"
            label="Outlined Customed Button"
          />
        </div> */

const CustomButton = ({
  label,
  className,
  labelClassName,
  style,
  onClick,
  disabled,
}) => {
  return (
    <button
      className={`custom-button ${className}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={`custom-button-label ${labelClassName}`}>{label} </span>
    </button>
  );
};

export default CustomButton;
