import React, { useState, useEffect } from "react";

import axios from "axios";
import { ShimmerPostList } from "react-shimmer-effects";

import { useAuth } from "../../../context/auth";
import UserAdCard from "../../../components/cards/userAdsCard/UserAdCard";
import Modall from "../../../components/modal/Modal";
import CustomButton from "../../../components/customButton/CustomButton";

import "./ListedAds.css";

export default function ListedAds() {
  // context
  const [auth, setAuth] = useAuth();
  // state
  const [ads, setAds] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const agent = auth.user?.role?.includes("Agent");

  useEffect(() => {
    fetchAds();
  }, [auth.token !== ""]);

  useEffect(() => {
    if (page === 1) return;
    fetchAds();
  }, [page, agent]);

  const fetchAds = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/user-ads/${auth.user?.userId}/${page}/${perPage}`
      );

      setAds([...ads, ...data.ads]);
      setTotal(data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const [value, setValue] = React.useState("wishlist");
  const [orientation, setOrientation] = React.useState("horizontal");

  useEffect(() => {
    const handleResize = () => {
      // Set orientation to vertical on small screens
      setOrientation(window.innerWidth <= 600 ? "vertical" : "horizontal");
    };

    handleResize(); // Call initially
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Modall handleClose={() => setIsOpen(false)} isOpen={isOpen}>
        <p className="header-modal">Request Pending Approval</p>
        <div className="info-modal">
          Dear {auth?.user?.lastName},
          <br />
          <br />
          Your application to become an agent is under review. We typically
          process requests within 1-3 business days. You will receive an email
          once a decision is made.
          <br />
          <br />
          For any queries, feel free to contact us.
          <br />
          <br />
          Best,
          <br />
          NimbleCasa Team
        </div>
      </Modall>
      <div className="card border-0 mt-2">
        {loading ? (
          <div style={{ padding: "40px 0" }}>
            <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={2} gap={30} />
          </div>
        ) : total > 0 ? (
          <div className="row listed-ads-title">
            <div className="col-12">
              <div>Listed {ads?.length > 1 ? "Properties" : "Property"} </div>
            </div>
            <div className="card-body listed-ad-card-body">
              <div className="row d-flex justify-content-center">
                {ads?.map((ad) => (
                  <UserAdCard
                    setIsOpen={setIsOpen}
                    approved={!auth?.user?.info?.isApproved}
                    ad={ad}
                    key={ad._id}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row listed-ads-title">
              <div className="col-12 ">
                <div>You have not listed any ads yet!</div>
              </div>
            </div>
            <div className="empty-state">
              <img src="./empty.svg" alt="Empty" height={400} width={400} />
            </div>
          </>
        )}
      </div>
      {ads?.length < total ? (
        <div className="row">
          <div className="col text-center mt-4 mb-4">
            <CustomButton
              label={
                loading ? "Loading..." : `${ads?.length} / ${total} Load more`
              }
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                setPage(page + 1);
              }}
              className="request-submit-button"
              labelClassName="request-submit-wrapper"
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
