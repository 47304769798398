import React, { useState, useEffect } from "react";

import useTheme from "@mui/system/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import LogoutMessage from "../../../components/misc/logoutMessage/LogoutMessage";
import ContentWrapper from "./../../../components/contentWrapper/ContentWrapper";
import NimbleRentInfoFaq from "../../../components/nimble-rent/nimble-rent-info-faq/NimbleRentInfoFaq";

import NimbleRentTopBanner from "../../../components/nimble-rent/nimble-rent-top-banner/NimbleRentTopBanner";

import "./NimbleRentInformationPage.css";
import NimbleRentInfo from "../../../components/nimbleRentDetail/NimbleRentInfo";
import NimbleRentInfoMobile from "../../../components/nimbleRentDetail/NimbleRentInfoMobile";

const NimbleRentInformationPage = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={windowWidth > 1500 ? "container page-wrapper" : "page-wrapper"}
    >
      {/* <ContentWrapper> */}
      <div className="nimble-rent-info-container">
        <LogoutMessage>
          <section className="nimble-rent-info-eligibility-container">
            {isSmScreen ? <NimbleRentInfoMobile /> : <NimbleRentInfo />}
          </section>

          <section className="nimble-rent-info-faq-container">
            <NimbleRentInfoFaq />
          </section>
        </LogoutMessage>
      </div>
      {/* </ContentWrapper> */}
    </div>
  );
};

export default NimbleRentInformationPage;
