import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";

import { useSearch } from "../../context/search.js";
import { type } from "../../helpers/actionTypeList.js";
import { generatePriceOptions } from "../../helpers/priceList.js";

import NewLocationSearchInput from "../location/NewLocationSearchInput.js";

import "./SearchForm.css";

export default function SearchForm({ navMenuProperty, currentPage }) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [userCurrentLocation, setUserCurrentLocation] = useState("");
  // context
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useSearch();

  const [selectedType, setSelectedType] = useState("All");
  const [isInputFocused, setInputFocused] = useState(false);

  // State to manage visibility of select boxes and search button
  const [showFilters, setShowFilters] = useState(true);

  const isSmScreen = useMediaQuery("(max-width:768px)");
  const isMediumScreen = useMediaQuery("(max-width:820px)");

  const navigate = useNavigate();

  const path = window.location.pathname.split("/");

  const priceStep = 1000000; // Adjust the step size as needed

  useEffect(() => {
    if (path[1] === "search") {
      handleSearch();
    }
  }, [search.pageNo]);

  // Detect screen size and hide filters on small screens
  useEffect(() => {
    const handleResize = () => {
      // if (window.innerWidth <= 767) {
      //   setShowFilters(false);
      // } else {
      //   setShowFilters(true);
      // }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!path[1]) {
      setSearch({
        address: "",
        action: "", //Buy
        type: "", //House
        total: 0,
        pageNo: 1,
        perPage: 8,
        loading: true,
      });
      return;
    }
    if (path[1] === "buy") {
      search.action = "Buy";
      search.address = "";
      search.type = "";
      search.total = 0;
      search.pageNo = 1;
      search.loading = true;
      search.perPage = 8;
      return;
    }
    if (path[1] === "rent") {
      search.action = "Rent";
      search.address = "";
      search.loading = true;
      search.total = 0;
      search.pageNo = 1;
      search.perPage = 8;
      search.type = "";
      return;
    }

    setSearch((prev) => ({ ...prev, address: prev.address, loading: false }));
  }, []);

  const handleButtonClick = (buttonName) => {
    if (buttonName === "All") {
      setSearch((prevSearch) => ({
        ...prevSearch,
        action: "",
      }));
      setSelectedType(buttonName);
      // setShowFilters(true);
    } else {
      setSearch((prevSearch) => ({
        ...prevSearch,
        action: buttonName,
      }));
      setSelectedType(buttonName);
      // setShowFilters(true);
    }
    // Show filters and search button when a button is clicked
    // setShowFilters(true);
  };

  const handleSearch = async () => {
    setLoading(true);
    setSearch((prev) => ({ ...prev, loading: true }));
    try {
      const { results, page, price, pageNo, perPage, ...rest } = search;

      const query = queryString.stringify(rest);

      const { data } = await axios.get(`/search/${pageNo}/${perPage}?${query}`);

      if (search?.page !== "/search") {
        setSearch((prev) => ({
          ...prev,
          results: data.ads,
          total: data.total,
          page: window.location.pathname,
          loading: false,
        }));
        navigate("/search");
      } else {
        setSearch((prev) => ({
          ...prev,
          results: data.ads,
          total: data.total,
          page: window.location.pathname,
          loading: false,
        }));
      }
    } catch (err) {
      setSearch({ ...search, loading: false });
    } finally {
      setLoading(false);
    }
  };

  // Function to handle change in minimum price
  const handleMinPriceChange = (event) => {
    const newMinPrice = parseInt(event.target.value);
    setSearch((prevSearch) => ({
      ...prevSearch,
      minPrice: newMinPrice,
      maxPrice:
        newMinPrice > prevSearch.maxPrice ? newMinPrice : prevSearch.maxPrice,
    }));
  };

  // Function to handle change in maximum price
  const handleMaxPriceChange = (event) => {
    const newMaxPrice = parseInt(event.target.value);
    setSearch((prevSearch) => ({
      ...prevSearch,
      maxPrice: newMaxPrice,
    }));
  };

  return (
    <>
      <div className="search-section">
        <div className="searchForm-container">
          <div
            className=""
            style={{
              backgroundImage: "url(/search-form-image.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center ",
              backgroundRepeat: "no-repeat",
              height: "100%",
              width: "100%",
            }}
          >
            <div className="">
              <div className="top-buttons-container">
                {(isSmScreen || isMediumScreen) && (
                  <>
                    <button
                      className={
                        selectedType === "All" ? "button1 active" : "button1"
                      }
                      onClick={() => handleButtonClick("All")}
                    >
                      All
                    </button>
                    {currentPage !== "buy" && (
                      <button
                        className={
                          selectedType === "Buy" ? "button2 active" : "button2"
                        }
                        onClick={() => handleButtonClick("Buy")}
                      >
                        Buy
                      </button>
                    )}
                    {currentPage !== "rent" && (
                      <button
                        className={
                          selectedType === "Rent" ? "button3 active" : "button3"
                        }
                        onClick={() => handleButtonClick("Rent")}
                      >
                        Rent
                      </button>
                    )}
                  </>
                )}
              </div>
              {isSmScreen && (
                <div className="search-and-filter-container">
                  <div className="search-bar-container">
                    <div className="search-bar">
                      <NewLocationSearchInput
                        value={value}
                        setValue={setValue}
                        userCurrentLocation={userCurrentLocation}
                        setUserCurrentLocation={setUserCurrentLocation}
                        options={options}
                        setOptions={setOptions}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        setInputFocused={setInputFocused}
                        isInputFocused={isInputFocused}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {(showFilters || isInputFocused) && !isMediumScreen && (
              <div className="search-and-filter-container">
                <div className="search-filter-container">
                  {!isSmScreen && (
                    <div className="search-bar-container">
                      <div className="search-bar">
                        <NewLocationSearchInput
                          value={value}
                          setValue={setValue}
                          userCurrentLocation={userCurrentLocation}
                          setUserCurrentLocation={setUserCurrentLocation}
                          options={options}
                          setOptions={setOptions}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          setInputFocused={setInputFocused}
                          isInputFocused={isInputFocused}
                        />
                      </div>
                    </div>
                  )}

                  <div className="filters-container">
                    <>
                      <select
                        className="select1 form-select"
                        aria-label="form-select select-options"
                        value={search.type}
                        onChange={(e) => {
                          setSearch({
                            ...search,
                            type: e.target.value,
                            price: "",
                          });
                        }}
                      >
                        {type.map((item) => (
                          <option
                            className="optgroup"
                            selected={item.selected}
                            key={item._id}
                            value={item.value}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <select
                        className="select2 form-select"
                        aria-label="form-select select-options"
                        value={search.minPrice || ""}
                        onChange={handleMinPriceChange}
                      >
                        {" "}
                        <option value="" disabled selected>
                          Min. Price
                        </option>
                        {generatePriceOptions(0)}
                      </select>
                      <select
                        className="select3 form-select"
                        aria-label="form-select select-options"
                        value={search.maxPrice || ""}
                        onChange={handleMaxPriceChange}
                      >
                        {" "}
                        <option value="" disabled selected>
                          Max. Price
                        </option>
                        {generatePriceOptions(
                          search.minPrice ? search.minPrice : 0
                        )}
                      </select>

                      <button
                        className="search-button"
                        onClick={handleSearch}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {loading ? (
                          <CircularProgress
                            size={24}
                            sx={{ color: "#e2770d" }}
                          />
                        ) : (
                          <>
                            <img
                              src="/SearchLogo.png"
                              width={44}
                              height={44}
                              alt="SearchLogo"
                              style={{ marginLeft: "5px" }}
                            />
                            <span className="search-text">Search</span>
                          </>
                        )}
                      </button>
                    </>
                  </div>
                </div>
              </div>
            )}

            {isMediumScreen && !isSmScreen && (
              <div className="search-and-filter-container">
                <div className="search-bar-container">
                  <NewLocationSearchInput
                    value={value}
                    setValue={setValue}
                    userCurrentLocation={userCurrentLocation}
                    setUserCurrentLocation={setUserCurrentLocation}
                    options={options}
                    setOptions={setOptions}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    setInputFocused={setInputFocused}
                    isInputFocused={isInputFocused}
                    style={{ width: "100%" }} // Set search bar width to 100%
                  />
                </div>
              </div>
            )}

            {(showFilters || isInputFocused) && isMediumScreen && (
              <div className="search-and-filter-container">
                <div className="filters-container">
                  <>
                    <select
                      className="select1 form-select"
                      aria-label="form-select select-options"
                      value={search.type}
                      onChange={(e) => {
                        setSearch({
                          ...search,
                          type: e.target.value,
                          price: "",
                        });
                      }}
                    >
                      {type.map((item) => (
                        <option
                          className="optgroup"
                          selected={item.selected}
                          key={item._id}
                          value={item.value}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>

                    <select
                      className="select2 form-select"
                      aria-label="form-select select-options"
                      value={search.minPrice || ""}
                      onChange={handleMinPriceChange}
                    >
                      {" "}
                      <option value="" disabled selected>
                        Min. Price
                      </option>
                      {generatePriceOptions(0)}
                    </select>
                    <select
                      className="select3 form-select"
                      aria-label="form-select select-options"
                      value={search.maxPrice || ""}
                      onChange={handleMaxPriceChange}
                    >
                      {" "}
                      <option value="" disabled selected>
                        Max. Price
                      </option>
                      {generatePriceOptions(
                        search.minPrice ? search.minPrice : 0
                      )}
                    </select>

                    <button
                      className="search-button"
                      onClick={handleSearch}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#ffffff" }} />
                      ) : (
                        <>
                          <img
                            src="/SearchLogo.png"
                            width={44}
                            height={44}
                            alt="SearchLogo"
                            style={{ marginLeft: "5px" }}
                          />
                          <span className="search-text">Search</span>
                        </>
                      )}
                    </button>
                  </>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
