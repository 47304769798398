import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import CustomButton from "../customButton/CustomButton";

import "./RequestSubmissionSuccessModal.css";

export default function RequestSubmissionSuccessModal({
  setIsRequestSuccessModalOpen,
  isRequestSuccessModalOpen,
  title,
  modalText,
}) {
  const isSmScreen = useMediaQuery("(max-width:768px)");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClick = async (e) => {
    e.preventDefault();

    setLoading(false);
    setIsRequestSuccessModalOpen(false);
    navigate("/");
  };

  return (
    <div className="column">
      <form className="success-container">
        <div className="success-image-container">
          {!isSmScreen ? (
            <img
              src="./success-large.png"
              alt="success"
              width="196px"
              height="196px"
            />
          ) : (
            <img
              src="./success-small.png"
              alt="success"
              width="92.6px"
              height="92.6px"
            />
          )}
        </div>
        <div className="text-center form-submission-title">{title}</div>
        <div className="text-center form-submission-text"> {modalText}</div>

        <div className="success-button">
          <CustomButton
            label="Back home"
            onClick={handleClick}
            className="request-success-button"
            labelClassName="request-success-wrapper"
          />
        </div>
      </form>
    </div>
  );
}
