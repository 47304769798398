import { useParams, useNavigate } from "react-router-dom";
import { HiPause } from "react-icons/hi2";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  ImageGallery,
  generatePhotosArray,
} from "../../components/misc/imageGallery/ImageGallery";
import { Link } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
// import millify from "millify";
import dayjs from "dayjs";
import useMediaQuery from "@mui/material/useMediaQuery";

import AdFeatures from "../../components/cards/AdFeatures";
import { formatNumber } from "../../helpers/ad";
import LikeUnlike from "../../components/misc/LikeUnlike";
import { FiShare } from "react-icons/fi";
import MapCard from "../../components/cards/MapCard";
// import AdCard from "../../components/cards/AdCard";
import AdCard from "../../components/cards/newAdCard/AdCard";

import Modall from "../../../src/components/modal/Modal";
import ContactSellerModal from "../../components/contactAgentModal/ContactSellerModal";
import { useAuth } from "../../context/auth";
import LogoutMessage from "../../components/misc/logoutMessage/LogoutMessage";
import CustomButton from "../../components/customButton/CustomButton";

// import { BsFillHousesFill } from "react-icons/bs";

import "./AdView.css";

dayjs.extend(relativeTime);

export default function AdView() {
  // state
  const [ad, setAd] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);
  const [related, setRelated] = useState([]);
  const [soldRented, setSoldRented] = useState([]);
  // const [adAddress, setAdAddress] = useState();

  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const [auth, setAuth] = useAuth();

  // hooks
  const params = useParams();
  const navigate = useNavigate();
  // const houseType = params.houseType;
  const isSmScreen = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    if (params?.id) fetchAd();
    window.scrollTo(0, 0);
  }, [params?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params?.id]);

  const fetchAd = async () => {
    try {
      const { data } = await axios.get(`/ad/${params.id}`);
      if (data) {
        setAd(data?.ad);
        setSoldRented(data?.related.filter((r) => r.sold === "Sold"));
        setRelated(data?.related.filter((r) => r.sold !== "Sold"));
        setLoading(false);
      } else {
        setIsEmpty(true);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showTable = () => {
    const tableRows = [];
    for (let i = 0; i < ad?.features.length; i = i + 2) {
      tableRows.push(
        <div className="container">
          <ul
            style={{ padding: "1px" }}
            className="row"
            key={ad?.features[i] + "-" + ad?.features[i + 1] + "-" + i}
          >
            <div className="col">
              <li>{ad?.features[i]}</li>
            </div>
            {(ad?.features.length - 1 === i && ad?.features.length) % 2 ===
            0 ? (
              <div className="col">
                <li>{ad.features[i + 1]}</li>
              </div>
            ) : (
              <></>
            )}
          </ul>
        </div>
      );
    }
    return tableRows;
  };

  // const navigateToLogin = () => {
  //   navigate("/login?fromAction=like", { replace: true });
  // };

  const addressCombination = (r) => (
    <>
      {r.administrativeLevels?.level3long ||
        r.administrativeLevels?.level2long ||
        ""}
      &nbsp;
      {(r.administrativeLevels?.level3long &&
        r.administrativeLevels?.level2long) ||
        ""}
      &nbsp;
      {r.administrativeLevels?.level1long || r.city || ""} {r.country || ""}
    </>
  );

  const handleShare = async () => {
    const url = window.location.href;
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          url: url,
        });
        // console.log("Content shared successfully");
      } catch (error) {
        // console.error("Error sharing content:", error);
      }
    } else {
      // Fallback: Copy to clipboard
      try {
        await navigator.clipboard.writeText(url);
        alert("URL copied to clipboard");
      } catch (error) {
        // console.error("Error copying URL to clipboard:", error);
      }
    }
  };

  return (
    <LogoutMessage>
      <div className="container-fluid d-flex flex-column h-100 mt-3 adview-main-container">
        {loading ? (
          <div className="display-1 d-flex justify-content-center align-items-center vh-100">
            <span> Loading... </span>
          </div>
        ) : (
          <div>
            <Modall
              handleClose={() => setIsOpen(false)}
              isOpen={isOpen}
              modalContentClassName={
                isSmScreen
                  ? "small-contact-agent-modal-content"
                  : "contact-agent-modal-content"
              }
              closeBtnClassName={
                isSmScreen
                  ? "small-contact-agent-modal-close-btn"
                  : "contact-agent-modal-close-btn"
              }
            >
              <ContactSellerModal ad={ad} setIsOpen={setIsOpen} />
            </Modall>
            <div className="container mt-5 pt-3 h-100 ">
              {/* large screen */}
              <div className="row mt-2">
                {/* page header */}
                <div className="topmost-container">
                  <div className="flex-test">
                    <span className="ml-auto">
                      {" "}
                      {(ad?.sold === "Sold" && "❌ Off market") ||
                        (ad?.sold === "Available" && "✅ Available ") ||
                        (ad?.sold === "Under Contract" && (
                          <span className="mt-2">
                            <HiPause className="h5 mt-1 under-contract-icon" />
                            {"Under Contract"}
                          </span>
                        ))}
                    </span>

                    <span className="ml-auto share-icon">
                      {auth?.user === null ? (
                        <Link to="/login" state={{ fromAction: "like" }}>
                          <LikeUnlike ad={ad} size={"h4"} />
                        </Link>
                      ) : (
                        <Link>
                          <LikeUnlike ad={ad} size={"h4"} />
                        </Link>
                      )}
                    </span>
                    <span className="save display-icon-description">Save</span>

                    <Link className="link-color" onClick={handleShare}>
                      <span>
                        {<FiShare ad={ad} className="h5 pointer share-icon" />}
                      </span>
                      <span className="save display-icon-description">
                        Share
                      </span>
                    </Link>
                  </div>
                </div>

                {/* left-view - image_gallery*/}
                <div className="col-7 display-adview-lg1">
                  <div>
                    <ImageGallery
                      photos={generatePhotosArray(ad?.photos)}
                      height={500}
                      thumbHeight={50}
                      className="adview-images"
                    />
                  </div>
                </div>

                {/* right-view - ad_details */}
                <div className="col-5 display-adview-lg1">
                  <div className="right-side-outercontainer">
                    <div className="right-side-screen1">
                      <div className="ad-details-section">
                        <div className="ad-view-property-title">
                          {" "}
                          {ad?.propertyTitle ||
                            (ad?.houseType && `${ad?.houseType} property`) ||
                            `${ad?.type} property`}
                        </div>

                        <p className="adview-address-section mt-1 mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 -5 40 40"
                            stroke="currentColor"
                            className="custom-svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                            />
                          </svg>
                          <span className="adview-address">{ad?.landmark}</span>
                        </p>

                        <div className="adview-adfeatures">
                          {" "}
                          <AdFeatures ad={ad} />
                        </div>

                        <div className="adview-action-and-type">
                          <CustomButton
                            label={`For ${
                              ad?.action === "Sell" ? "Sale" : "Rent"
                            }`}
                            className="adview-action"
                            style={{ padding: "0px 20px", cursor: "auto" }}
                            labelClassName="adview-action-label"
                          />
                          {ad?.nimbleRentStatus && (
                            <CustomButton
                              label={
                                ad?.nimbleRentStatus &&
                                ad?.sold === "Available" ? (
                                  <span>
                                    <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
                                    Nimble Rent
                                  </span>
                                ) : (
                                  <span>Rented</span>
                                )
                              }
                              className="adview-nimblerent"
                              style={{ padding: "0px 20px", cursor: "auto" }}
                              labelClassName="adview-nimblerent-label"
                            />
                          )}

                          {ad?.nimbleMortgageStatus && (
                            <CustomButton
                              label={
                                ad?.nimbleMortgageStatus &&
                                ad?.sold === "Available" ? (
                                  <span>
                                    <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
                                    Mortgage Available
                                  </span>
                                ) : (
                                  <span>Sold</span>
                                )
                              }
                              className="adview-nimblerent"
                              style={{
                                padding: "0px 10px",
                                cursor: "auto",
                                // height: "100px",
                              }}
                              labelClassName="adview-nimblerent-label"
                            />
                          )}
                        </div>

                        {/* <hr /> */}
                        <div className="d-flex justify-content-between">
                          <div className="adview-price-container flex-direction-row">
                            <div className="adview-price d-flex flex-direction-row">
                              {" "}
                              <span className="adview-naira">
                                {" "}
                                <img
                                  src="https://nimblecasauatstorage.blob.core.windows.net/naira-icon/naira.svg"
                                  alt="N"
                                  width={30}
                                  height={30}
                                />
                              </span>
                              <span className="adview-naira-value">
                                {formatNumber(ad?.price)}
                              </span>
                              {/* {millify(ad?.price)} */}
                            </div>
                            {ad?.type === "Land" && ad?.areaPerPrice && (
                              <h5 className="adview-per-sqft">
                                {" "}
                                &nbsp;
                                <span>
                                  {" "}
                                  <em>per</em>
                                </span>
                                &nbsp;
                                {ad?.areaPerPrice || ""}
                              </h5>
                            )}
                          </div>
                        </div>
                        {/* <hr /> */}

                        {/* <span>Posted: {dayjs(ad?.createdAt).fromNow()}</span> */}

                        {/* <div className="align-items-center mb-3 mt-0">
                          <BsFillHousesFill /> {ad?.houseType}
                        </div> */}
                        <div className="adview-button-container">
                          <Link className="adview-button-inner-container">
                            <CustomButton
                              label="Contact Agent"
                              className="adview-contact-agent-button"
                              onClick={() => setIsOpen(true)}
                              labelClassName="adview-contact-agent"
                            ></CustomButton>
                          </Link>
                        </div>
                      </div>

                      <div className="ad-map-section">
                        <MapCard ad={ad} related={related} />
                      </div>

                      {/* overview */}
                      <div className="ad-overview-section">
                        <div className="ad-overview-title">
                          Property Overview
                        </div>
                        <hr className="hr" />
                        <span className="ad-overview-description">
                          {ad?.description}
                        </span>
                      </div>

                      {/* features */}
                      {ad?.features?.length > 0 ? (
                        <div className="ad-features-section">
                          <span>
                            <div className="ad-features-title">Features</div>
                            <hr />
                          </span>
                          <span>{showTable()}</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* medium and smaller screen */}
            <div className="container justify-content-center display-adview-sm">
              <div>
                <ImageGallery photos={generatePhotosArray(ad?.photos)} />
              </div>

              <div>
                <div>
                  <div className="ad-details-section">
                    <div className="ad-view-property-title">
                      {" "}
                      {ad?.propertyTitle ||
                        (ad?.houseType && `${ad?.houseType} property`) ||
                        `${ad?.type} property`}
                    </div>

                    <p className="adview-address-section mt-1 mb-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 -5 40 40"
                        stroke="currentColor"
                        className="custom-svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                        />
                      </svg>
                      <span className="adview-address">{ad?.landmark}</span>
                    </p>

                    <div className="adview-adfeatures">
                      {" "}
                      <AdFeatures ad={ad} />
                    </div>

                    <div className="adview-action-and-type">
                      <CustomButton
                        label={`For ${ad?.action === "Sell" ? "Sale" : "Rent"}`}
                        className="adview-action"
                        style={{ padding: "0px 20px", cursor: "auto" }}
                        labelClassName="adview-action-label"
                      />
                      {ad?.nimbleRentStatus && (
                        <CustomButton
                          label={
                            ad?.nimbleRentStatus && ad?.sold === "Available" ? (
                              <span>
                                <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
                                Nimble Rent
                              </span>
                            ) : (
                              <span> Rented </span>
                            )
                          }
                          className="adview-nimblerent"
                          style={{ padding: "0px 10px", cursor: "auto" }}
                          labelClassName="adview-nimblerent-label"
                        />
                      )}

                      {ad?.nimbleMortgageStatus && (
                        <CustomButton
                          label={
                            ad?.nimbleMortgageStatus &&
                            ad?.sold === "Available" ? (
                              <span>
                                <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
                                Mortgage Available
                              </span>
                            ) : (
                              <span> Sold </span>
                            )
                          }
                          className="adview-nimblerent"
                          style={{ padding: "0px 10px", cursor: "auto" }}
                          labelClassName="adview-nimblerent-label"
                        />
                      )}
                    </div>

                    {/* <hr /> */}
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-direction-row">
                        <div className="adview-price d-flex flex-direction-row">
                          {" "}
                          <span className="adview-naira">
                            {" "}
                            <img
                              src="https://nimblecasauatstorage.blob.core.windows.net/naira-icon/naira.svg"
                              alt="N"
                              width={30}
                              height={30}
                            />
                          </span>
                          <span className="adview-naira-value">
                            {formatNumber(ad?.price)}
                          </span>
                          {/* {millify(ad?.price)} */}
                        </div>
                        {ad?.type === "Land" && ad?.areaPerPrice && (
                          <h5 className="adview-per-sqft">
                            {" "}
                            &nbsp;
                            <span>
                              {" "}
                              <em>per</em>
                            </span>
                            &nbsp;
                            {ad?.areaPerPrice || ""}
                          </h5>
                        )}
                      </div>
                    </div>
                    {/* <hr /> */}

                    {/* <span>Posted: {dayjs(ad?.createdAt).fromNow()}</span> */}

                    {/* <div className="align-items-center mb-3 mt-0">
                          <BsFillHousesFill /> {ad?.houseType}
                        </div> */}
                    <div className="adview-button-container">
                      <Link className="adview-button-inner-container">
                        <CustomButton
                          label="Contact Agent"
                          className="adview-contact-agent-button"
                          onClick={() => setIsOpen(true)}
                          labelClassName="adview-contact-agent"
                        ></CustomButton>
                      </Link>
                    </div>
                  </div>

                  <div className="ad-map-section">
                    <MapCard ad={ad} related={related} />
                  </div>

                  {/* overview */}
                  <div className="ad-overview-section">
                    <div className="ad-overview-title">Property Overview</div>
                    <hr className="hr" />
                    <span className="ad-overview-description">
                      {ad?.description}
                    </span>
                  </div>

                  {/* features */}
                  {ad?.features?.length > 0 ? (
                    <div className="ad-features-section">
                      <span>
                        <div className="ad-features-title">Features</div>
                        <hr />
                      </span>
                      <span>{showTable()}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            {/* related properties */}
            {related?.length > 0 && (
              <>
                <br />
                <div className="container related-margin">
                  <div className="related-ads-title">
                    Related {related?.length > 1 ? "Properties" : "Property"}
                  </div>
                  <hr />
                  <div className="adview-inner-container">
                    {related?.map((ad) => (
                      <AdCard key={ad?._id} ad={ad} />
                    ))}
                  </div>
                </div>
              </>
            )}

            {/* Recently sold/rented properties */}
            {soldRented.length > 0 && (
              <>
                <br />
                <div className="container related-margin">
                  <div className="related-ads-title">
                    Recently {ad.action === "Rent" ? "Rented" : "Sold"}{" "}
                    {soldRented.length > 1 ? "Properties" : "Property"}
                  </div>
                  <hr />
                  {/* <div className="row adview-outer-container"> */}
                  <div className="adview-inner-container">
                    {soldRented?.map((ad) => (
                      <AdCard key={ad._id} ad={ad} />
                    ))}
                  </div>
                </div>
                {/* </div> */}
              </>
            )}
            {/* <pre>{JSON.stringify(related, null, 4)} </pre>
          <pre>{JSON.stringify(soldRented, null, 4)} </pre> */}
          </div>
        )}
      </div>
    </LogoutMessage>
  );
}
