import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/auth.js";
import config from "../../config.js";

import "./AdDelete.css";
import CustomButton from "../customButton/CustomButton.js";

export default function AdDelete({
  setIsDeleteOpen,
  isDeleteOpen,
  ad,
  userId,
  setIsRequestSuccessModalOpen,
  isRequestSuccessModalOpen,
}) {
  // state
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);

  // hooks
  const navigate = useNavigate();

  async function processImageDeletions(imagesToRemove) {
    if (imagesToRemove.length === 0) {
      return;
    }

    // Filter images that need to be deleted from the backend
    const imagesToDeleteFromBackend = imagesToRemove.filter(
      (image) => !image.blob
    );

    if (imagesToDeleteFromBackend.length === 0) {
      // console.log("No backend images to remove.");
      return;
    }

    try {
      setLoading(true);
      const deletionResults = await Promise.all(
        imagesToDeleteFromBackend.map(async (image) => {
          try {
            const { data } = await axios.post("/remove-image", {
              key: image.key || image.image, // Adjust based on your image object structure
            });
            return { success: true, key: image.key || image.image, data };
          } catch (err) {
            return {
              success: false,
              key: image.key || image.image,
              error: err,
            };
          }
        })
      );

      // Optional: Process results, e.g., remove successfully deleted images from state
      const successfullyDeletedKeys = deletionResults
        .filter((result) => result.success)
        .map((result) => result.key);

      setLoading(false);
    } catch (error) {
      // console.error("Failed to process image deletions:", error);
      setLoading(false);
    }
  }

  const handleDelete = async () => {
    try {
      setDelLoading(true);
      const imagesPreDelete = ad?.photos.map((file) => {
        return { key: file?.key };
      });
      // console.log("images to delete", imagesPreDelete);
      await processImageDeletions(imagesPreDelete);

      const { data } = await axios.delete(`/ad/${ad._id}/${userId}`);
      // console.log("ad create response => ", data);
      if (data?.error) {
        toast.error(data.error);
        setDelLoading(false);
      } else {
        // toast.success("Ad deleted successfully");
        setIsRequestSuccessModalOpen(true);
        setDelLoading(false);
        setIsDeleteOpen(false);
        window.location.href = "/dashboard";
        // navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
      setIsDeleteOpen(false);

      setDelLoading(false);
    }
  };

  return (
    <div className="row" onClick={(e) => e.stopPropagation()}>
      <div
        className="delete-ad-modal"
        autoComplete="off"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h3 mb-4 text-center delete-ad">Delete this ad</div>
        <div className="mx-2 my-3 text-center delete-ad-text">
          {" "}
          Are you sure you want to delete this property?
        </div>

        <div className="delete-ad-button-container">
          <CustomButton
            className=" col-12 delete-ad-cancel-button"
            labelClassName="delete-ad-cancel-button-label"
            onClick={(e) => {
              setIsDeleteOpen(false);
              (e) => e.stopPropagation();
            }}
            label={"Cancel"}
          />

          <CustomButton
            className=" col-12 delete-ad-delete-button"
            labelClassName="delete-ad-delete-button-label"
            onClick={() => {
              setIsDeleteOpen(true);
              handleDelete();
            }}
            label={loading ? "Deleting..." : "Delete"}
          />
        </div>
      </div>
    </div>
  );
}
