import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";

const ReferralTermsAndConditions = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleBackButton = () => {
    navigate("/referral-page");
  };
  return (
    <div className="container-fluid my-3 p-3">
      <div className="h1 pb-3 text-center p-header ">
        Referral Program Terms and Conditions
      </div>
      <div>
        <p className="py-3 pl-3">
          Welcome to NimbleCasa Referral Program. By participating in our
          referral program, you agree to the following terms and conditions:
        </p>
      </div>
      <ol>
        <li>
          {" "}
          <p className="list-heading">Eligibility </p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  The Referral Program is open to all users of NimbleCasa who
                  are aged 18 and above.{" "}
                </p>
              </li>
              <li>
                Users must have a valid account on the NimbleCasa application
                and must comply with all existing Terms of Service and policies
              </li>
            </ul>
          </p>
        </li>

        <li>
          {" "}
          <p className="list-heading"> Referral Process </p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  Referrers can refer potential buyers ("Referred Buyers") to
                  properties listed on the NimbleCasa App by directly
                  introducing the Referred Buyer via email, phone, or in-person
                  introduction
                </p>
              </li>
              <li>
                The Referred Buyer must complete the purchase of a property
                listed on the NimbleCasa App for the Referrer to be eligible for
                a commission.
              </li>
            </ul>
          </p>
        </li>

        <li>
          {" "}
          <p className="list-heading"> Commission Structure </p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  Referrers will earn a commission of 10% of the total
                  commission earned by NimbleCasa on any successful sale made by
                  a Referred Buyer
                </p>
              </li>
              <li>
                <p>
                  The total commission earned by NimbleCasa will be calculated
                  at 5% of the sale value of the property or land.
                </p>
              </li>
              <li>
                <p>
                  "Successful Sale" is defined as a completed real estate
                  transaction where the purchase amount has been fully settled,
                  and all necessary contracts have been executed.
                </p>
              </li>
            </ul>
          </p>
        </li>

        <li>
          {" "}
          <p className="list-heading">Payment of Commission </p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  The commission will be paid to the Referrer within 3 days
                  after the successful completion of the sale.
                </p>
              </li>
              <li>
                <p>
                  The commission will be paid directly to the Referrer bank
                  account and the Referrer must provide valid payment details.
                </p>
              </li>
              <li>
                <p>
                  All commissions are subject to mandatory deductions of 5%
                  (Five Percent) being Withholding Tax as mandated by the laws
                  of the Federal Republic of Nigeria and shall make available
                  tax credit receipts to the Referrer.
                </p>
              </li>
            </ul>
          </p>
        </li>

        <li>
          {" "}
          <p className="list-heading">Limitations </p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  Referrers cannot refer themselves, and self-referrals will not
                  be eligible for commissions.
                </p>
              </li>
              <li>
                <p>
                  Referrers cannot refer buyers who are already in discussions
                  with the NimbleCasa agents about the same property.
                </p>
              </li>
              <li>
                <p>
                  If a Referred Buyer is referred by multiple Referrers, only
                  the first Referrer will receive the commission.
                </p>
              </li>
            </ul>
          </p>
        </li>

        <li>
          {" "}
          <p className="list-heading"> Verification</p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  All referrals must be verified by the NimbleCasa Admin, who
                  will confirm that the Referred Buyer was introduced through
                  the Referrer’s efforts and that the sale was a direct result
                  of the referral.
                </p>
              </li>
              <li>
                <p>
                  Referrers may be required to provide proof of the
                  introduction, including contact information, communication
                  history, or other relevant details.
                </p>
              </li>
            </ul>
          </p>
        </li>

        <li>
          {" "}
          <p className="list-heading">Fraudulent Activity </p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  NimbleCasa reserves the right to withhold or revoke
                  commissions if fraudulent or suspicious activity is detected.
                </p>
              </li>
              <li>
                <p>
                  Any attempt to manipulate the referral process, including
                  creating multiple accounts or referring false leads, will
                  result in disqualification from the program.
                </p>
              </li>
            </ul>
          </p>
        </li>

        <li>
          {" "}
          <p className="list-heading">Changes to the Referral Program </p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  NimbleCasa reserves the right to modify, suspend, or terminate
                  the Referral Program at any time. Any changes will be
                  effective immediately upon posting on NimbleCasa and duly
                  communicated to Referrers.
                </p>
              </li>
              <li>
                <p>
                  Referrals made prior to changes will be honored under the
                  terms in effect at the time of the referral.
                </p>
              </li>
            </ul>
          </p>
        </li>

        <li>
          {" "}
          <p className="list-heading"> Disputes</p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  Any disputes related to the Referral Program will be governed
                  by the laws of the Federal Republic of Nigeria.
                </p>
              </li>
              <li>
                <p>
                  In the event of a dispute regarding the eligibility for a
                  commission or any other aspect of the program, the decision of
                  NimbleCasa will be final and binding.
                </p>
              </li>
            </ul>
          </p>
        </li>

        <li>
          {" "}
          <p className="list-heading"> Acceptance of Terms </p>
          <p>
            {/* <ol className="lower-alpha"> */}
            <ul>
              <li>
                <p>
                  By participating in this Referral Program, you agree to these
                  Terms and Conditions and acknowledge that you have read and
                  understood them in their entirety
                </p>
              </li>
            </ul>
          </p>
        </li>
      </ol>{" "}
      <div className="d-flex justify-content-around">
        <button
          className="btn  col-4  md-col-8 my-4"
          onClick={handleBackButton}
          style={{
            backgroundColor: "#EE7B0D",
            color: "#FFFFFF",
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default ReferralTermsAndConditions;
