import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import StarIcon from "@mui/icons-material/Star";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";

import AdDetailsForm from "./AdDetailsForm";
import AdFeaturesForm from "./AdFeaturesForm";
import CustomButton from "../../customButton/CustomButton";

const AdFormMobile = ({
  ad,
  setAd,
  type,
  action,
  formData,
  setFormData,
  setIsOpen,
  isOpen,
  onClick,
  page = "",
}) => {
  const [checked, setChecked] = useState(false); // State for checkbox
  const [loading, setLoading] = useState(false);

  const Adtype = ["House", "Land", "Commercial", "Industrial"];

  const handleTermsAndPolicyCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleAdTypeSelectChange = (event) => {
    const value = event.target.value;
    setAd({ ...ad, type: value });
  };

  const submitBackgroundColor = () => {
    if (page === "edit") return "#EE7B0D";
    else {
      if (ad?.loading || !checked) return "grey";
      else return "#EE7B0D";
    }
  };

  return (
    <div className="adform-mobile-container">
      <div className="adform-mobile-inner-container">
        <div className="create-ad-title">
          {page === "edit" ? "Edit Ad" : "Create an Ad "} for{" "}
          {ad?.action === "Sell" ? "Sale" : action}
        </div>

        <div className="adform-mobile-property-details-container">
          <div className="create-ad-form-type">
            <FormControl sx={{ width: "100%", mb: 2 }}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                {page === "edit" ? "Property Type" : "Choose Property Type"}
              </Typography>
              <Select
                defaultValue={"House"}
                value={ad.type}
                disabled={page === "edit"}
                onChange={handleAdTypeSelectChange}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "52px",
                  background: "#F9FAFB",
                  border: "1px solid #D1D5DB",
                  padding: "25px 4px",
                  borderRadius: "8px",
                }}
              >
                <MenuItem value="" disabled>
                  Select Ad type
                </MenuItem>
                {Adtype?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="adform-left-section-wrapper">
            <AdDetailsForm ad={ad} setAd={setAd} type={type} page={page} />
            <AdFeaturesForm ad={ad} setAd={setAd} type={type} />
          </div>
        </div>
      </div>

      {/* Upload Photos form */}
      <div
        className={`${
          page === "edit"
            ? "edit-upload-photos-container"
            : "upload-photos-container"
        }`}
      >
        <div className="ad-form-photo-title">
          Add property photos <StarIcon sx={{ color: "red", fontSize: 12 }} />
        </div>
        <div className="photo-frames-container">
          {formData?.map((file, index) => (
            <Stack direction="row" spacing={2} key={index}>
              {/* {page === "edit" && ( */}
              {/* <ClearIcon
                onClick={() => handleSingleImageDelete(file, index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 10000,
                }}
              /> */}
              {/* )} */}
              <Avatar
                sx={{ borderRadius: "8px", width: 76.42, height: 76.42 }}
                alt="Remy Sharp"
                variant="rounded"
                key={index}
                src={file?.image}
                shape="square"
                className="my-2"
              />
            </Stack>
          ))}
        </div>

        {/* Upload Photos button */}
        <div className="photo-button-container">
          <CustomButton
            label={"Upload Photos"}
            onClick={() => setIsOpen(true)}
            labelClassName={"adform-mobile-upload-photo-button-label"}
            className="adform-mobile-upload-photo-button"
          />
        </div>
      </div>

      <div className="adform-left-section-wrapper">
        {page !== "edit" && (
          <div className="adform-policy-check-container">
            <Checkbox
              checked={checked}
              onChange={handleTermsAndPolicyCheck}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span> By submitting this form I agree to the </span>
            <Link className="text-primary" to="/seller-terms">
              Terms of Use <StarIcon sx={{ color: "red", fontSize: 12 }} />
            </Link>{" "}
          </div>
        )}

        <div
          className={`${
            page === "edit"
              ? "adedit-mobile-submit-button-container"
              : "adform-mobile-submit-button-container"
          }`}
        >
          <Button
            className="adform-mobile-submit-button-label"
            variant="contained"
            onClick={onClick}
            disabled={page !== "edit" && (!checked || loading)}
            style={{
              fontFamily: "Figtree",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "22px",
              textAlign: "left",
              borderRadius: "8px",
              width: "192px",
              height: "40px",
              textTransform: "Capitalize",
              backgroundColor: submitBackgroundColor(),

              color: "white",
            }}
          >
            {loading ? "Uploading..." : ad.loading ? "Saving..." : "Submit"}
          </Button>
        </div>
      </div>

      {/* <pre>{JSON.stringify(ad, null, 4)}</pre> */}
    </div>
  );
};

export default AdFormMobile;
