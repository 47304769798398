/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Badge } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import useTheme from "@mui/system/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
// import millify from "millify";
import AdFeatures from "../../../components/cards/AdFeatures";
import { useAuth } from "../../../context/auth";

import { formatNumber } from "../../../helpers/ad";

// import LikeUnlike from "../../../components/misc/LikeUnlike";
import LikeUnlike from "../../misc/favoriteButton/LikeUnlike";
import {
  ImageGallery,
  generatePhotosArray,
} from "../../../components/misc/imageGallery/ImageGallery";
import Modall from "../../../components/modal/Modal";

import "../index.css";
import "./AdCard.css";
import ContactSellerModal from "../../../components/contactAgentModal/ContactSellerModal";
import CustomButton from "./../../customButton/CustomButton";
import FeaturedProperties from "./../../featuredProperties/FeaturedProperties";

export default function AdCard({ ad, tag = true, section, page }) {
  const theme = useTheme();
  // const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmScreen = useMediaQuery("(max-width:768px)");

  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [auth, setAuth] = useAuth();

  const [adAddress, setAdAddress] = useState();
  const [showBottom, setShowBottom] = useState(false);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -200;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  // console.log({ ad });
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    // window.scrollTo(0, 500);
  }, [pathname]);
  // console.log({ ad });
  useEffect(() => {
    ad?.landmarkGoogleMap?.map((r) =>
      setAdAddress(
        `${
          r.administrativeLevels?.level3long ||
          r.administrativeLevels?.level2long ||
          ""
        }  
          ${
            (r.administrativeLevels?.level3long &&
              r.administrativeLevels?.level2long) ||
            ""
          } 
          ${r.administrativeLevels?.level1long || r.city || ""} ${
          r.country || ""
        }`
      )
    );
  }, []);
  // console.log(showBottom);

  //tag is true
  const renderLabel = () => {
    if (page === "nimblerent" || page !== "home") {
      if (ad?.nimbleRentStatus && ad?.sold === "Available") {
        return (
          <span className="fire-icon-space">
            <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
            Nimble Rent Available
          </span>
        );
      }

      if (ad?.nimbleMortgageStatus && ad?.sold === "Available") {
        return (
          <span className="fire-icon-space">
            <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
            Mortgage Available
          </span>
        );
      }

      if (
        (ad?.nimbleRentStatus || ad?.nimbleMortgageStatus) &&
        ad?.sold === "Sold"
      ) {
        if (ad?.action === "Sell") return <span>Sold</span>;
        else return <span>Rented </span>;
      }
    }

    if (ad?.featuredPropertyStatus) {
      if (ad?.sold === "Available") return <span>Featured</span>;
      else {
        if (ad?.action === "Sell") return <span>Sold</span>;
        else return <span>Rented </span>;
      }
    }

    if (ad?.sold === "Sold" && ad.action === "Rent")
      return <span>Rented </span>;

    if (ad?.sold === "Sold" && ad.action === "Sell") return <span>Sold </span>;

    return (
      <span>
        {ad?.sold}
        {ad?.sold === "Sold"
          ? ""
          : ad?.action === "Sell"
          ? " for Sale"
          : " for Rent"}
      </span>
    );
  };

  const renderStyle = () => {
    if (
      ad?.featuredPropertyStatus &&
      !ad?.nimbleMortgageStatus &&
      ad?.sold === "Available"
    ) {
      return {
        background: "#FAE2C6",
        borderRadius: "99px",
        padding: "6px 20px",
      };
    }
    if (ad?.sold === "Sold") {
      return {
        background: "#D21C1C",
        borderRadius: "99px",
        padding: "6px 20px",
      };
    }
    if (ad?.sold === "Available") {
      return {
        background: "#F1F8FE",
        borderRadius: "99px",
        padding: "6px 20px",
      };
    }
    if (ad?.sold === "Under Contract") {
      return {
        background: "#9BD9DB",
        borderRadius: "99px",
        padding: "6px 20px",
      };
    }
  };

  //tag is false
  const tagFalseRenderLabel = () => {
    if (page === "nimblerent" && ad?.nimbleRentStatus) {
      if (ad?.sold === "Available") {
        return (
          <span>
            <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
            Nimble Rent Available
          </span>
        );
      }
      if (ad?.sold === "Sold") {
        return <span> Rented Out</span>;
      }
    }

    if (page === "home" && ad?.featuredPropertyStatus) {
      return <span> Featured</span>;
    }

    if (ad?.featuredPropertyStatus && ad?.sold === "Available") {
      return <span> Featured</span>;
    }

    if (ad?.sold === "Sold" && ad?.action === "Rent") {
      return <span>Rented</span>;
    }

    return (
      <span>
        {ad?.sold}
        {ad?.sold === "Sold"
          ? ""
          : ad?.action === "Sell"
          ? " for Sale"
          : " for Rent"}
      </span>
    );
  };

  const tagFalseRenderStyle = () => {
    if (
      ad?.featuredPropertyStatus &&
      !ad?.nimbleMortgageStatus &&
      ad?.sold === "Available"
    ) {
      return {
        background: "#FAE2C6",
        borderRadius: "99px",
        padding: "6px 20px",
      };
    }

    if (ad?.sold === "Sold") {
      return {
        background: "#D21C1C",
        borderRadius: "99px",
        padding: "6px 20px",
      };
    }

    if (ad?.sold === "Available") {
      return {
        background: "#F1F8FE",
        borderRadius: "99px",
        padding: "6px 20px",
      };
    }

    if (ad?.sold === "Under Contract") {
      return {
        background: "#9BD9DB",
        borderRadius: "99px",
        padding: "6px 20px",
      };
    }

    return {}; // Default style
  };

  return (
    <div
      className="ad-card-container1"
      onMouseEnter={() => {
        setShowBottom(true);
      }}
      onMouseLeave={() => setShowBottom(false)}
    >
      <Modall
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
        modalContentClassName={
          isSmScreen
            ? "small-contact-agent-modal-content"
            : "contact-agent-modal-content"
        }
        closeBtnClassName={
          isSmScreen
            ? "small-contact-agent-modal-close-btn"
            : "contact-agent-modal-close-btn"
        }
      >
        <ContactSellerModal ad={ad} setIsOpen={setIsOpen} />
      </Modall>
      {tag ? (
        <Link className="link" to={`/ad/${ad._id}`}>
          <div className="card ad-card-inner-wrapper1">
            {/* <Link className="link" to={`/ad/${ad.slug}`}> */}
            {/* <img
            src={ad?.photos?.[0].Location}
            alt={`${ad?.type}-${ad?.address}-${ad?.action}-${ad?.price}`}
            style={{
              height: "250px",
              // maxWidth: "350px",
              // alignSelf: "center",
              objectFit: "cover",
              height: "250px",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          />{" "} */}
            <div className="d-flex justify-content-between">
              <div className="ad-card-top">
                {/* <CustomButton
                  label={
                    ((page === "nimblerent" || page !== "home") &&
                      ad?.nimbleRentStatus &&
                      ad?.sold === "Available" && (
                        <span>
                          <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
                          Nimble Rent Available
                        </span>
                      )) ||
                    (page === "nimblerent" &&
                      ad?.nimbleRentStatus &&
                      ad?.sold === "Sold" && <span> Rented Out</span>) ||
                    (page === "home" && ad?.featuredPropertyStatus && (
                      <span> Featured</span>
                    )) ||
                    (page !== "home" &&
                      ad?.featuredPropertyStatus &&
                      ad?.sold === "Available" && <span> Featured</span>) ||
                    (ad?.sold === "Sold" && ad?.action === "Rent" ? (
                      <span>Rented</span>
                    ) : (
                      <span>
                        {ad?.sold}
                        {ad?.sold === "Sold"
                          ? ""
                          : ad?.action === "Sell"
                          ? " for Sale"
                          : " for Rent"}
                      </span>
                    ))
                  }
                  style={
                    (ad?.featuredPropertyStatus &&
                      ad?.sold === "Available" && {
                        background: "#FAE2C6",
                        borderRadius: "99px",
                        padding: "6px 20px",
                      }) 
                      ||
                    (ad?.sold === "Sold" && {
                      background: "#D21C1C",
                      borderRadius: "99px",
                      padding: "6px 20px",
                    }) ||
                    (ad?.sold === "Available" && {
                      background: "#F1F8FE",
                      borderRadius: "99px",
                      padding: "6px 20px",
                    }) ||
                    (ad?.sold === "Under Contract" && {
                      background: "#9BD9DB",
                      borderRadius: "99px",
                      padding: "6px 20px",
                    })
                  }
                  labelClassName={
                    (ad?.featuredPropertyStatus &&
                      ad?.sold === "Available" &&
                      "badge-button-label") ||
                    (ad?.nimbleRentStatus &&
                      ad?.sold === "Sold" &&
                      "sold-badge-button-label") ||
                    (ad?.sold === "Available" &&
                      "available-badge-button-label") ||
                    (ad?.sold === "Under Contract" &&
                      "undercontract-badge-button-label")
                  }
                /> */}

                <CustomButton
                  label={renderLabel()}
                  style={renderStyle()}
                  labelClassName={
                    (ad?.featuredPropertyStatus &&
                      !ad?.nimbleMortgageStatus &&
                      ad?.sold === "Available" &&
                      "badge-button-label") ||
                    (ad?.nimbleRentStatus &&
                      ad?.sold === "Sold" &&
                      "sold-badge-button-label") ||
                    (ad?.sold === "Available" &&
                      "available-badge-button-label") ||
                    (ad?.sold === "Under Contract" &&
                      "undercontract-badge-button-label")
                  }
                />
              </div>
              <div className="ad-card-top-right">
                {auth?.user === null ? (
                  <Link to="/login" state={{ fromAction: "like" }}>
                    <LikeUnlike ad={ad} />
                  </Link>
                ) : (
                  <Link>
                    <LikeUnlike ad={ad} />
                  </Link>
                )}
              </div>
            </div>

            <ImageGallery
              photos={generatePhotosArray(ad?.photos)}
              showThumbs={false}
              showStatus={false}
              showIndicators={true}
              height={"322"}
              width={"322"}
            />
            <div className="card-body1 ad-card-body1">
              <div className="ad-card-property-title1">
                {ad?.propertyTitle ||
                  (ad?.houseType && `${ad?.houseType} property`) ||
                  `${ad?.type} property`}
              </div>

              <div className="card-text address-height1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 40 40"
                  stroke="currentColor"
                  className="custom-svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>

                {ad?.landmark}
              </div>

              <div className="ad-features">
                <AdFeatures ad={ad} />
              </div>

              <div className="d-flex justify-content-between">
                <div className="d-flex flex-direction-row">
                  <div className="ad-price d-flex flex-direction-row">
                    {" "}
                    {/* <span className="ad-price">&#8358;</span> */}
                    <span className="ad-naira">
                      {" "}
                      {/* <img src="./naira.svg"></img> */}
                      <img
                        src="https://nimblecasauatstorage.blob.core.windows.net/naira-icon/naira.svg"
                        alt="N"
                        width={30}
                        height={30}
                      />
                    </span>
                    <span className="naira-value">
                      {formatNumber(ad?.price)}
                    </span>
                    {/* {millify(ad?.price)} */}
                  </div>
                  {ad?.type === "Land" && ad?.areaPerPrice && (
                    <h5 className="per-sqft">
                      {" "}
                      &nbsp;
                      <span>
                        {" "}
                        <em>per</em>
                      </span>
                      &nbsp;
                      {ad?.areaPerPrice || ""}
                    </h5>
                  )}
                </div>
              </div>

              <div
                className="ad-card-bottom "
                style={
                  isSmScreen
                    ? { display: showBottom ? "block" : "block" }
                    : { display: showBottom ? "block" : "none" }
                }
              >
                <div className="pt-3 d-flex justify-content-between align-items-center">
                  {!ad.featuredPropertyStatus && (
                    <p className="card-text day-posted">
                      Posted {dayjs(ad?.createdAt).fromNow()}
                    </p>
                  )}
                  <p className="justify-content-end">
                    <Link className="bg-white">
                      <CustomButton
                        label="Contact Agent"
                        className="contact-agent-button"
                        // style={{ padding: "0px 20px" }}
                        onClick={() => setIsOpen(true)}
                        labelClassName="contact-agent"
                      ></CustomButton>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <div className="card ad-card-inner-wrapper1">
          {/* <Link className="link" to={`/ad/${ad.slug}`}> */}
          {/* <img
        src={ad?.photos?.[0].Location}
        alt={`${ad?.type}-${ad?.address}-${ad?.action}-${ad?.price}`}
        style={{
          height: "250px",
          // maxWidth: "350px",
          // alignSelf: "center",
          objectFit: "cover",
          height: "250px",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      />{" "} */}
          <div className="d-flex justify-content-between">
            <div className="ad-card-top">
              {/* <CustomButton
                label={
                  ((page === "nimblerent" || page !== "home") &&
                    ad?.nimbleRentStatus &&
                    ad?.sold === "Available" && (
                      <span>
                        <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
                        Nimble Rent Available
                      </span>
                    )) ||
                  (page === "nimblerent" &&
                    ad?.nimbleRentStatus &&
                    ad?.sold === "Sold" && <span> Rented Out</span>) ||
                  (page === "home" && ad?.featuredPropertyStatus && (
                    <span> Featured</span>
                  )) ||
                  (page !== "home" &&
                    ad?.featuredPropertyStatus &&
                    ad?.sold === "Available" && <span> Featured</span>) ||
                  (ad?.sold === "Sold" && ad?.action === "Rent" ? (
                    <span>Rented</span>
                  ) : (
                    <span>
                      {ad?.sold}
                      {ad?.sold === "Sold"
                        ? ""
                        : ad?.action === "Sell"
                        ? " for Sale"
                        : " for Rent"}
                    </span>
                  ))
                }
                style={
                  (ad?.featuredPropertyStatus &&
                    ad?.sold === "Available" && {
                      background: "#FAE2C6",
                      borderRadius: "99px",
                      padding: "6px 20px",
                    }) ||
                  (ad?.sold === "Sold" && {
                    background: "#D21C1C",
                    borderRadius: "99px",
                    padding: "6px 20px",
                  }) ||
                  (ad?.sold === "Available" && {
                    background: "#F1F8FE",
                    borderRadius: "99px",
                    padding: "6px 20px",
                  }) ||
                  // page !== "nimblerent" &&
                
                  (ad?.sold === "Under Contract" && {
                    background: "#9BD9DB",
                    borderRadius: "99px",
                    padding: "6px 20px",
                  })
                }
                labelClassName={
                  (ad?.featuredPropertyStatus &&
                    ad?.sold === "Available" &&
                    "badge-button-label") ||
                  (ad?.nimbleRentStatus &&
                    ad?.sold === "Sold" &&
                    "sold-badge-button-label") ||
                  (ad?.sold === "Available" &&
                    "available-badge-button-label") ||
                  (ad?.sold === "Under Contract" &&
                    "undercontract-badge-button-label")
                }
              /> */}

              <CustomButton
                label={tagFalseRenderLabel()}
                style={tagFalseRenderStyle()}
                labelClassName={
                  (ad?.featuredPropertyStatus &&
                    !ad?.nimbleMortgageStatus &&
                    ad?.sold === "Available" &&
                    "badge-button-label") ||
                  (ad?.nimbleRentStatus &&
                    ad?.sold === "Sold" &&
                    "sold-badge-button-label") ||
                  (ad?.sold === "Available" &&
                    "available-badge-button-label") ||
                  (ad?.sold === "Under Contract" &&
                    "undercontract-badge-button-label")
                }
              />
            </div>
            <div className="ad-card-top-right">
              {auth?.user === null ? (
                <Link to="/login" state={{ fromAction: "like" }}>
                  <LikeUnlike ad={ad} />
                </Link>
              ) : (
                <Link>
                  <LikeUnlike ad={ad} />
                </Link>
              )}
            </div>
          </div>

          <ImageGallery
            photos={generatePhotosArray(ad?.photos)}
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            height={"322"}
            width={"322"}
          />
          <div className="card-body1 ad-card-body1">
            <div className="ad-card-property-title1">
              {ad?.propertyTitle ||
                (ad?.houseType && `${ad?.houseType} property`) ||
                `${ad?.type} property`}
            </div>

            <div className="card-text address-height1">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 40 40"
                  stroke="currentColor"
                  className="custom-svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>
              </span>
              {ad?.landmark}
            </div>

            <div className="ad-features">
              <AdFeatures ad={ad} />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex flex-direction-row">
                <div className="ad-price d-flex flex-direction-row">
                  {" "}
                  {/* <span>&#8358;</span> */}
                  <span className="ad-naira">
                    {" "}
                    {/* <img src="./naira.svg"></img> */}
                    <img
                      src="https://nimblecasauatstorage.blob.core.windows.net/naira-icon/naira.svg"
                      alt="N"
                      width={30}
                      height={30}
                    />
                  </span>
                  <span className="naira-value">{formatNumber(ad?.price)}</span>
                  {/* {millify(ad?.price)} */}
                </div>
                {ad?.type === "Land" && ad?.areaPerPrice && (
                  <h5 className="per-sqft">
                    {" "}
                    &nbsp;
                    <span>
                      {" "}
                      <em>per</em>
                    </span>
                    &nbsp;
                    {ad?.areaPerPrice || ""}
                  </h5>
                )}
              </div>
            </div>

            {/* {tag && (
              <div
                className="ad-card-bottom "
                style={
                  isSmScreen
                    ? { display: showBottom ? "block" : "block" }
                    : { display: showBottom ? "block" : "none" }
                }
              >
                <div className="pt-3 d-flex justify-content-between align-items-center">
                  <p className="card-text day-posted">
                    Posted {dayjs(ad?.createdAt).fromNow()}
                  </p>
                  <p className="justify-content-end">
                    <Link className="bg-white">
                      <CustomButton
                        label="Contact Agent"
                        className="contact-agent-button"
                        onClick={() => setIsOpen(true)}
                        labelClassName="contact-agent"
                      ></CustomButton>
                    </Link>
                  </p>
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
      {/* <pre>{JSON.stringify(ad, null, 4)} </pre> */}
    </div>
  );
}
