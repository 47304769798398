import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/auth";
import axios from "axios";
import { ShimmerPostList } from "react-shimmer-effects";

// import AdCard from "../../../components/cards/AdCard";
import AdCard from "../../../components/cards/newAdCard/AdCard";

import "./Wishlist.css";

export default function Wishlist() {
  // context
  const [auth, setAuth] = useAuth();
  // state
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAds();
  }, [auth.token !== ""]);

  useEffect(() => {
    fetchAds();
  }, [auth.wishlist]);

  const fetchAds = async () => {
    setLoading(true);
    try {
      // const { data } = await axios.post("/wishlist", { adId: ad._id, userId: auth?.user?.userId});
      const { data } = await axios.post(`/ad-wishlist`, {
        wishlist: auth.wishlist,
      });
      setAds(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  return (
    <div className="container-fluid p-0">
      {loading ? (
        <div style={{ padding: "40px 0" }}>
          <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={2} gap={30} />
        </div>
      ) : !ads?.length ? (
        <>
          <div className="row wishlist-title">
            <div className="col-12">
              <div>You have not liked any properties yet!</div>
            </div>
          </div>
          <div className="card-body wishlist-ad-card-body">
            <div className="row pb-3">
              <div className="row d-flex justify-content-center">
                <div className="empty-state">
                  <img src="./empty.svg" alt="Empty" height={400} width={400} />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container-fluid p-0">
          <div className="row wishlist-title">
            <div className="col-12">
              Liked {ads?.length > 1 ? "Properties" : "Property"}
            </div>
          </div>

          <div className="card-body wishlist-ad-card-body">
            <div className="row pb-3">
              <div className="row d-flex justify-content-center">
                {ads?.map((ad) => (
                  <AdCard ad={ad} key={ad._id} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
