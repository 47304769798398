import { React, useState, useRef, useEffect } from "react";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import CurrencyInput from "react-currency-input-field";
import StarIcon from "@mui/icons-material/Star";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import ClearIcon from "@mui/icons-material/Clear";
import { FaStickyNote } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useData } from "../../../context/adData";

import LocationAutoComplete from "../../location/LocationAutoComplete.js";

import "./AdForm.css";

function AdDetailsForm({ ad, setAd, type, page = "create" }) {
  const [ddata, setDdata] = useData();

  const AdStatus = [
    "Available",
    "Under Contract",
    ad?.action === "Sell" ? "Sold" : "Rented",
  ];

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [userCurrentLocation, setUserCurrentLocation] = useState("");
  // context

  const [isInputFocused, setInputFocused] = useState(false);

  const isSmScreen = useMediaQuery("(max-width: 768px)");

  // useEffect(() => {
  //   if (ad) {
  //     setDdata(ad);
  //   }
  // }, [ad]);

  const handleSelectChange = (e) => {
    setAd({ ...ad, areaPerPrice: e.target.value });
  };

  const handleClickClear = (fieldName) => {
    setAd((prevValues) => {
      const updatedValues = { ...prevValues, [fieldName]: "" };
      return updatedValues;
    });
  };

  const handleAdStatusChange = (event) => {
    const value = event.target.value;
    setAd({ ...ad, sold: value });
  };

  // console.log(ad.price);
  return (
    <div className="property-details-form-container left-align">
      <div className="property-details-title-container ">
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", textAlign: "left" }}
        >
          Property Details
        </Typography>
      </div>
      <div className="property-details-form">
        {page === "edit" && (
          <FormControl sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", textAlign: "left" }}
            >
              Property Status
            </Typography>
            <Select
              defaultValue={ad?.sold}
              value={ad?.sold}
              onChange={handleAdStatusChange}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "52px",
                background: "#F9FAFB",
                border: "1px solid #D1D5DB",
                padding: "25px 4px",
                borderRadius: "8px",
              }}
            >
              {AdStatus?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", textAlign: "left" }}
        >
          Property Name <StarIcon sx={{ color: "red", fontSize: 15 }} />
        </Typography>

        <OutlinedInput
          size="small"
          placeholder="e.g Luxurious mini flat"
          name="ad.propertyTitle"
          value={ad.propertyTitle}
          onChange={(e) => setAd({ ...ad, propertyTitle: e.target.value })}
          sx={{
            borderRadius: "8px",
            border: "1px solid #D1D5DB",
            marginBottom: "20px",
            padding: "3.5px 20px",
            background: " #F9FAFB",
            marginRight: "0px",
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear text"
                onClick={() => {
                  handleClickClear("propertyTitle");
                }}
                edge="end"
              >
                <ClearIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </InputAdornment>
          }
        />

        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", textAlign: "left" }}
        >
          Property Address/Location{" "}
          <StarIcon sx={{ color: "red", fontSize: 12 }} />
        </Typography>

        <OutlinedInput
          size="small"
          placeholder="e.g full address"
          name="ad.address"
          value={ad.address}
          onChange={(e) => setAd({ ...ad, address: e.target.value })}
          sx={{
            borderRadius: "8px",
            border: "1px solid #D1D5DB",
            marginBottom: "20px",
            padding: "3.5px 20px",
            background: " #F9FAFB",
            marginRight: "0px",
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear text"
                onClick={() => {
                  handleClickClear("address");
                }}
                edge="end"
              >
                <ClearIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </InputAdornment>
          }
        />

        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", textAlign: "left" }}
        >
          Landmark Address/Location{" "}
          <StarIcon sx={{ color: "red", fontSize: 12 }} />
        </Typography>

        <Tooltip
          title="This is the Property Address/Location that is visible to the public"
          placement="top"
        >
          <div className="mb-3 border-0 ">
            {/* <GooglePlacesAutocomplete
              apiKey={config.GOOGLE_PLACES_KEY}
              apiOptions="ng"
              value={ad?.landmark}
              selectProps={{
                defaultInputValue: ad?.landmark,
                placeholder: "Landmark address/location",
                onChange: ({ value }) => {
                  setAd({ ...ad, landmark: value.description });
                },
              }}
            /> */}

            <LocationAutoComplete
              value={ad?.landmark}
              setValue={setValue}
              userCurrentLocation={userCurrentLocation}
              setUserCurrentLocation={setUserCurrentLocation}
              options={options}
              setOptions={setOptions}
              inputValue={inputValue}
              setInputValue={setInputValue}
              setInputFocused={setInputFocused}
              isInputFocused={isInputFocused}
              ad={ad}
              setAd={setAd}
            />
          </div>
        </Tooltip>

        {ad.type === "Land" ? (
          <div className="d-flex align-items-start">
            <div className="d-flex flex-column">
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Property Price <StarIcon sx={{ color: "red", fontSize: 12 }} />
              </Typography>

              <CurrencyInput
                placeholder="Price in Naira"
                defaultValue={ad?.price}
                value={ad?.price}
                className="price-styling"
                prefix="₦ "
                onValueChange={(value) => setAd({ ...ad, price: value })}
              />

              {/* <OutlinedInput
                size="small"
                id="outlined-adornment-amount"
                value={ad.price}
                placeholder="Enter price in Naira"
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #D1D5DB",
                  marginBottom: "20px",
                  padding: "3.5px 20px",
                  background: " #F9FAFB",
                  marginRight: "0px",
                }}
                onChange={handlePriceChange}
                startAdornment={
                  <InputAdornment position="start">N</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear text"
                      onClick={() => {
                        handleClickClear("price");
                      }}
                      edge="end"
                    >
                      <ClearIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </InputAdornment>
                }
              />  */}
            </div>
            <div className="d-flex flex-column">
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "bold",
                  textAlign: "flex-start",
                  marginLeft: "20px",
                }}
              >
                Per
              </Typography>
              <FormControl sx={{ width: "85%", marginLeft: 2 }}>
                <Select
                  size="small"
                  value={ad.areaPerPrice}
                  onChange={handleSelectChange}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #D1D5DB",
                    marginBottom: "20px",
                    padding: "3.5px 20px",
                    background: " #F9FAFB",
                    marginRight: "0px",
                  }}
                  style={{ width: "114px", marginLeft: "5px" }}
                  name="per"
                  variant="outlined"
                >
                  {["sqm", "sqft", "plot"].map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", textAlign: "left" }}
            >
              Property Price <StarIcon sx={{ color: "red", fontSize: 12 }} />
            </Typography>
            <CurrencyInput
              placeholder="Price in Naira"
              defaultValue={ad?.price}
              value={ad?.price}
              prefix="₦ "
              className="price-styling"
              onValueChange={(value) => setAd({ ...ad, price: value })}
            />
          </div>
        )}

        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", textAlign: "left" }}
        >
          Property Document title{" "}
          {(ad?.action === "Sell" || ad?.type === "Land") && (
            <StarIcon sx={{ color: "red", fontSize: 12 }} />
          )}
        </Typography>

        <OutlinedInput
          size="small"
          placeholder="e.g C of O, Survey plan"
          name="ad.title"
          value={ad.title}
          onChange={(e) => setAd({ ...ad, title: e.target.value })}
          sx={{
            borderRadius: "8px",
            border: "1px solid #D1D5DB",
            marginBottom: "20px",
            padding: "3.5px 20px",
            background: " #F9FAFB",
            marginRight: "0px",
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear text"
                onClick={() => {
                  handleClickClear("title");
                }}
                edge="end"
              >
                <ClearIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </InputAdornment>
          }
        />

        {!isSmScreen && (
          <>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", textAlign: "left" }}
            >
              Property Description{" "}
              <StarIcon sx={{ color: "red", fontSize: 15 }} />
            </Typography>

            <OutlinedInput
              // size="small"
              placeholder="Enter additional features/description"
              name="description"
              value={ad.description}
              onChange={(e) => setAd({ ...ad, description: e.target.value })}
              multiline
              rows={5}
              sx={{
                borderRadius: "8px",
                border: "1px solid #D1D5DB",
                marginBottom: "20px",
                padding: "3.5px 20px",
                background: " #F9FAFB",
                marginRight: "0px",
                paddingTop: "20px",
                alignItems: "start",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <FaStickyNote />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear text"
                    onClick={() => {
                      handleClickClear("description");
                    }}
                    edge="end"
                  >
                    <ClearIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </>
        )}
      </div>
    </div>
  );
}

export default AdDetailsForm;
