// src/components/GoogleAnalytics.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GoogleAnalytics = ({ trackingId }) => {
  const location = useLocation();

  useEffect(() => {
    // Add the Google Analytics script to the head
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag("js", new Date());
    gtag("config", trackingId);

    // Track page views
    gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [trackingId, location]);

  useEffect(() => {
    // Track page views on route change
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location]);

  return null;
};

export default GoogleAnalytics;
