import React from "react";
import { NavLink, Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import "./NewFooter.css"; // Assuming you have a CSS file named Footer.css

const Footer = () => {
  return (
    <div className="footer footer-loader">
      <footer className="container main-c">
        <div className="row board-line">
          <div className="col-lg-4 col-md-12 footer-left ">
            <div className="footer-img">
              <img
                src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/nimble-logo2.png"
                alt="NimbleCasa Logo"
                className="logo desktop-only"
              />
              <p className="tagline desktop-only">Real Estate, The Right Way</p>
            </div>
            <div className="footer-copyright">
              {/* <p className="copyright">© 2024. A product of Nimble–Byte</p> */}
              <p>
                &copy; {new Date().getFullYear()}. A product of{" "}
                <a
                  className="NimbleByteLink"
                  href="https://nimble-byte.com/"
                  target="_blank"
                  aria-label="NimbleByte homepage"
                >
                  Nimble-Byte
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 footer-center">
            <div className="useful-links">
              <h4 className="useful-links-header">Useful links</h4>
              {/* <ul className="useful-links-list list-unstyled"> */}
              <ul>
                <li>
                  <Link className="useful-links-list" to="/terms-of-use">
                    {" "}
                    Terms of use{" "}
                  </Link>
                </li>
                <li>
                  <Link className="useful-links-list" to="/privacy-policy">
                    Privacy policy
                  </Link>
                </li>
                <li>
                  <Link
                    className="useful-links-list"
                    to="/referer-terms-and-conditions"
                  >
                    Referral terms
                  </Link>
                </li>
              </ul>
            </div>
            <div className="quick-links">
              <h4 className="quick-links-header">Quick links</h4>
              {/* <ul className="quick-links-list list-unstyled"> */}
              <ul className="quick-links-list">
                <li>
                  <Link
                    className="useful-links-list"
                    to="/rent"
                    aria-label="Rent"
                  >
                    Rent
                  </Link>
                </li>
                <li>
                  <Link
                    className="useful-links-list"
                    to="/buy"
                    aria-label="Buy"
                  >
                    Buy
                  </Link>
                </li>
                <li>
                  <Link
                    className="useful-links-list"
                    to="/contact-agents"
                    aria-label="Sell"
                  >
                    Sell
                  </Link>
                </li>
                <li>
                  <Link
                    className="useful-links-list"
                    to="/nimble-rent-information"
                    aria-label="Nimble rent"
                  >
                    Finance
                  </Link>
                </li>

                <li>
                  <Link
                    className="useful-links-list"
                    to="https://blog.nimblecasa.com/"
                    aria-label="Nimblecasa blog"
                  >
                    Blog
                  </Link>
                </li>

                <li>
                  <Link
                    className="useful-links-list"
                    to="/referral-page"
                    aria-label="Nimblecasa blog"
                  >
                    Referral
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 footer-right ">
            <div className="app-download mt-4">
              <h5 className="app-download-header">Download our app</h5>
              <div className="app-download-link">
                <Tooltip
                  title="Get it on Google Play"
                  // placement="bottom"
                  sx={{ background: "red" }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.nimblecasa.app"
                    target="_blank"
                  >
                    <img
                      className="app-download-image"
                      src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/google-app-download-badge-284x84.png"
                      // src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/Google-play.png"
                      // src="./google-play.png"
                      alt="Get it on Google Play"
                      aria-label="Get it on Google Play"
                      height="50"
                      width="180"
                    />
                  </a>
                </Tooltip>
                <Tooltip
                  title="Download on the App Store"
                  // placement="bottom"
                >
                  <a
                    href="https://apps.apple.com/ng/app/nimblecasa-real-estate-app/id6477383845"
                    target="_blank"
                  >
                    <img
                      className="app-download-image"
                      src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/apple-app-download-badge-284x84.png"
                      // src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/App-stoer.png"
                      // src="./app-stoer.png"
                      alt="Download on the App Store"
                      aria-label="Download on the App Store"
                      height="50"
                      width="580"
                    />
                  </a>
                </Tooltip>
              </div>
            </div>
            <div className="social-icons">
              <h5 className="social-icons-header mb-2">
                Lets connect on socials
              </h5>
              <div
                className="link footer-color"
                style={{ listStyleType: "none" }}
              >
                <Tooltip title="Facebook" placement="bottom">
                  <a
                    href="https://www.facebook.com/profile.php?id=61554805858914"
                    target="_blank"
                    aria-label="Visit us on facebook"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="white"
                      className="bi bi-facebook"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                    </svg>
                  </a>
                </Tooltip>
                <Tooltip title="Instagram" placement="bottom">
                  <a
                    href="https://www.instagram.com/nimblecasa/"
                    target="_blank"
                    aria-label="Visit us on instagram"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="white"
                      className="bi bi-instagram"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                    </svg>
                  </a>
                </Tooltip>
                <Tooltip title="Twitter" placement="bottom">
                  <a
                    href="https://twitter.com/NimbleCasa"
                    target="_blank"
                    aria-label="Visit us on twitter"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="white"
                      className="bi bi-twitter-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                    </svg>
                  </a>
                </Tooltip>
                <Tooltip title="Linkedin" placement="bottom">
                  <a
                    href="https://ng.linkedin.com/company/nimblecasa?trk=public_post_feed-actor-name"
                    target="_blank"
                    aria-label="Visit us on twitter"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-linkedin"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                    </svg>
                  </a>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
