import React, { useEffect, useRef, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { useSearch } from "../../context/search.js";

import { generatePriceOptions } from "../../helpers/priceList.js";
import NewLocationSearchInput from "../location/NewLocationSearchInput.js";

import "./SearchForm.css";

export default function NimbleFinanceSearchForm({
  nimbleFinanceSearch,
  setNimbleFinanceSearch,
  handleNimbleFinanceSearch,
}) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [userCurrentLocation, setUserCurrentLocation] = useState("");
  // context
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useSearch();

  const [selectedType, setSelectedType] = useState("All");
  const [isInputFocused, setInputFocused] = useState(false);

  // State to manage visibility of select boxes and search button
  const [showFilters, setShowFilters] = useState(true);

  const isSmScreen = useMediaQuery("(max-width:768px)");
  const isMediumScreen = useMediaQuery("(max-width:820px)");

  const navigate = useNavigate();
  const location = useLocation();

  const path = window.location.pathname.split("/");

  const priceStep = 1000000; // Adjust the step size as needed

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      setNimbleFinanceSearch((prev) => ({
        ...prev,
        perPage: 8,
        pageNo: 1,
      }));
      handleNimbleFinanceSearch();
    }
  }, [
    nimbleFinanceSearch.nimbleRentStatus,
    nimbleFinanceSearch.nimbleMortgageStatus,
  ]);

  const handleButtonClick = (buttonName) => {
    if (buttonName === "All") {
      setNimbleFinanceSearch((prevSearch) => ({
        ...prevSearch,
        nimbleMortgageStatus: true,
        nimbleRentStatus: true,
      }));
      setSelectedType(buttonName);
    } else if (buttonName === "Buy") {
      setNimbleFinanceSearch((prevSearch) => ({
        ...prevSearch,
        nimbleMortgageStatus: true,
        nimbleRentStatus: false,
      }));
      setSelectedType(buttonName);
    } else if (buttonName === "Rent") {
      setNimbleFinanceSearch((prevSearch) => ({
        ...prevSearch,
        nimbleRentStatus: true,
        nimbleMortgageStatus: false,
      }));
      setSelectedType(buttonName);
    }
  };

  // Function to handle change in minimum price
  const handleMinPriceChange = (event) => {
    const newMinPrice = parseInt(event.target.value);
    setNimbleFinanceSearch((prevSearch) => ({
      ...prevSearch,
      minPrice: newMinPrice,
      maxPrice:
        newMinPrice > prevSearch.maxPrice ? newMinPrice : prevSearch.maxPrice,
    }));
  };

  // Function to handle change in maximum price
  const handleMaxPriceChange = (event) => {
    const newMaxPrice = parseInt(event.target.value);
    setNimbleFinanceSearch((prevSearch) => ({
      ...prevSearch,
      maxPrice: newMaxPrice,
    }));
  };

  const handleSearchButton = () => {
    setNimbleFinanceSearch((prevSearch) => ({
      ...prevSearch,
      pageNo: 1,
      // perPage: 8,
    }));
    handleNimbleFinanceSearch();
  };
  return (
    <>
      <div className="search-section">
        <div className="searchForm-container">
          <div
            className=""
            style={{
              backgroundImage: "url(/search-form-image.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center ",
              backgroundRepeat: "no-repeat",
              height: "100%",
              width: "100%",
            }}
          >
            <div className="">
              <div className="nimble-finance-top-buttons-container">
                {(isSmScreen ||
                  isMediumScreen ||
                  location.pathname === "/nimble-finance") && (
                  <>
                    <button
                      className={
                        selectedType === "Buy" ? "button2 active" : "button2"
                      }
                      onClick={() => handleButtonClick("Buy")}
                    >
                      Mortgage
                    </button>
                    <button
                      className={
                        selectedType === "Rent" ? "button3 active" : "button3"
                      }
                      onClick={() => handleButtonClick("Rent")}
                    >
                      Nimble Rent
                    </button>
                  </>
                )}
              </div>
              {isSmScreen && (
                <div className="search-and-filter-container">
                  <div className="search-bar-container">
                    <div className="search-bar">
                      <NewLocationSearchInput
                        value={value}
                        setValue={setValue}
                        userCurrentLocation={userCurrentLocation}
                        setUserCurrentLocation={setUserCurrentLocation}
                        options={options}
                        setOptions={setOptions}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        setInputFocused={setInputFocused}
                        isInputFocused={isInputFocused}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {(showFilters || isInputFocused) && !isMediumScreen && (
              <div className="search-and-filter-container">
                <div className="search-filter-container">
                  {!isSmScreen && (
                    <div className="search-bar-container">
                      <div className="search-bar">
                        <NewLocationSearchInput
                          value={value}
                          setValue={setValue}
                          userCurrentLocation={userCurrentLocation}
                          setUserCurrentLocation={setUserCurrentLocation}
                          options={options}
                          setOptions={setOptions}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          setInputFocused={setInputFocused}
                          isInputFocused={isInputFocused}
                        />
                      </div>
                    </div>
                  )}

                  <div className="filters-container">
                    <>
                      <select
                        className="select2 form-select"
                        aria-label="form-select select-options"
                        value={nimbleFinanceSearch.minPrice || ""}
                        onChange={handleMinPriceChange}
                      >
                        {" "}
                        <option value="" disabled selected>
                          Min. Price
                        </option>
                        {generatePriceOptions(0)}
                      </select>
                      <select
                        className="select3 form-select"
                        aria-label="form-select select-options"
                        value={nimbleFinanceSearch.maxPrice}
                        onChange={handleMaxPriceChange}
                      >
                        {" "}
                        <option value="" disabled selected>
                          Max. Price
                        </option>
                        {generatePriceOptions(
                          nimbleFinanceSearch.minPrice
                            ? nimbleFinanceSearch.minPrice
                            : 0
                        )}
                      </select>

                      <button
                        className="search-button"
                        onClick={handleSearchButton}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {loading ? (
                          <CircularProgress
                            size={24}
                            sx={{ color: "#e2770d" }}
                          />
                        ) : (
                          <>
                            <img
                              src="/SearchLogo.png"
                              width={44}
                              height={44}
                              alt="SearchLogo"
                              style={{ marginLeft: "5px" }}
                            />
                            <span className="search-text">Search</span>
                          </>
                        )}
                      </button>
                    </>
                  </div>
                </div>
              </div>
            )}

            {isMediumScreen && !isSmScreen && (
              <div className="search-and-filter-container">
                <div className="search-bar-container">
                  <NewLocationSearchInput
                    value={value}
                    setValue={setValue}
                    userCurrentLocation={userCurrentLocation}
                    setUserCurrentLocation={setUserCurrentLocation}
                    options={options}
                    setOptions={setOptions}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    setInputFocused={setInputFocused}
                    isInputFocused={isInputFocused}
                    style={{ width: "100%" }} // Set search bar width to 100%
                  />
                </div>
              </div>
            )}

            {(showFilters || isInputFocused) && isMediumScreen && (
              <div className="search-and-filter-container">
                <div className="filters-container">
                  <>
                    <select
                      className="select2 form-select"
                      aria-label="form-select select-options"
                      value={nimbleFinanceSearch.minPrice || ""}
                      onChange={handleMinPriceChange}
                    >
                      {" "}
                      <option value="" disabled selected>
                        Min. Price
                      </option>
                      {generatePriceOptions(0)}
                    </select>
                    <select
                      className="select3 form-select"
                      aria-label="form-select select-options"
                      value={nimbleFinanceSearch.maxPrice}
                      onChange={handleMaxPriceChange}
                    >
                      {" "}
                      <option value="" disabled selected>
                        Max. Price
                      </option>
                      {generatePriceOptions(
                        nimbleFinanceSearch.minPrice
                          ? nimbleFinanceSearch.minPrice
                          : 0
                      )}
                    </select>

                    <button
                      className="search-button"
                      onClick={handleSearchButton}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#ffffff" }} />
                      ) : (
                        <>
                          <img
                            src="/SearchLogo.png"
                            width={44}
                            height={44}
                            alt="SearchLogo"
                            style={{ marginLeft: "5px" }}
                          />
                          <span className="search-text">Search</span>
                        </>
                      )}
                    </button>
                  </>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
