import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
// import LogoutMessage from "../misc/logoutMessage/LogoutMessage";

import "./LikeUnlike.css";

export default function LikeUnlike({ ad, size = null }) {
  // context
  const [auth, setAuth] = useAuth();

  // hooks
  const navigate = useNavigate();

  const handleLike = async () => {
    try {
      if (auth.user === null) {
        navigate("/login", {
          state: `/ad/${ad._id}`,
        });
        return;
      }
      const { data } = await axios.post("/wishlist", {
        adId: ad._id,
        userId: auth?.user?.userId,
      });
      const { wishlist } = data;

      // console.log("handle like wishlist => ", wishlist);
      setAuth({ ...auth, wishlist });

      const fromLS = JSON.parse(localStorage.getItem("auth"));
      fromLS.wishlist = wishlist;
      localStorage.setItem("auth", JSON.stringify(fromLS));
      // navigate("/");
      toast.success("Added to wishlist");
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnlike = async () => {
    try {
      if (auth.user === null) {
        navigate("/login", {
          state: `/ad/${ad.slug}`,
        });
        return;
      }

      const { data } = await axios.patch(
        `/wishlist/user/${auth?.user?.userId}/ad/${ad._id}`
      );

      const { ok } = data;

      if (ok) {
        const updatedWishlist = auth.wishlist?.filter((d) => {
          return d !== ad._id;
        });

        setAuth({ ...auth, wishlist: updatedWishlist });

        const fromLS = JSON.parse(localStorage.getItem("auth"));
        fromLS.wishlist = updatedWishlist;
        localStorage.setItem("auth", JSON.stringify(fromLS));
        // navigate("/");
        toast.success("Removed from wishlist");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="favorite-button">
      {/* <LogoutMessage> */}
      {auth.wishlist?.includes(ad?._id) ? (
        <span onClick={handleUnlike} className={`h2 pointer`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // viewBox="0 0 24 24"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            fill="red"
            className="w-6 h-6"
          >
            <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
          </svg>
        </span>
      ) : (
        <span onClick={handleLike} className={`h2 pointer`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            // viewBox="0 0 24 24"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            strokeWidth={1.5}
            stroke="black"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
            />
          </svg>
        </span>
      )}
      {/* <pre>{JSON.stringify(auth, null, 4)} </pre>  */}
      {/* </LogoutMessage> */}
    </div>
  );
}
