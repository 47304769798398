const Prices = [
  {
    _id: 0,
    name: "All Prices",
    array: [0, 1000000000],
  },
  {
    _id: 1,
    name: "₦0 to ₦1m",
    array: [0, 1000000],
  },
  {
    _id: 2,
    name: "₦1 to ₦2m",
    array: [1000000, 2000000],
  },
  {
    _id: 3,
    name: "₦2 to ₦5m",
    array: [2000000, 5000000],
  },
  {
    _id: 4,
    name: "₦5 to ₦10m",
    array: [5000000, 10000000],
  },
  {
    _id: 5,
    name: "₦10m to ₦50m",
    array: [10000000, 50000000],
  },
  {
    _id: 6,
    name: "₦50m to ₦100m",
    array: [50000000, 100000000],
  },
  {
    _id: 7,
    name: "₦100m and Above",
    array: [100000000, 100000000000],
  },
];

const sellPrices = [
  {
    _id: 0,
    name: "All Prices",
    array: [0, 1000000000],
  },
  {
    _id: 1,
    name: "₦0 to ₦10m",
    array: [0, 10000000],
  },
  {
    _id: 2,
    name: "₦10m to ₦50m",
    array: [10000000, 50000000],
  },
  {
    _id: 3,
    name: "₦50m to ₦100m",
    array: [50000000, 100000000],
  },
  {
    _id: 4,
    name: "₦100m and Above",
    array: [100000000, 100000000000],
  },
];

const rentPrices = [
  {
    _id: 0,
    name: "All Prices",
    array: [0, 1000000000],
  },
  {
    _id: 1,
    name: "₦0 to ₦1m",
    array: [0, 1000000],
  },
  {
    _id: 2,
    name: "₦1m to ₦5m",
    array: [1000000, 5000000],
  },
  {
    _id: 3,
    name: "₦5m to ₦10m",
    array: [5000000, 10000000],
  },
  {
    _id: 4,
    name: "₦10m to ₦100m",
    array: [10000000, 100000000],
  },
];

const generatePriceOptions = (min) => {
  const priceOptions = [];
  const max = 10000000000;
  for (let price = min; price <= max; ) {
    const formattedPrice = price.toLocaleString();

    priceOptions.push(
      <option key={price} value={price}>
        {formattedPrice}
      </option>
    );

    if (price < 1000000) {
      price += 500000; // Step of 1 million for values 500,000
    } else if (price < 5000000) {
      price += 1000000; // Step of 1 million for values between 1 million and 5 million
    } else if (price < 10000000) {
      price += 5000000; // Step of 5 million for values 10 million
    }else if (price < 100000000) {
      price += 10000000; // Step of 10 million for values between 10 million and 100 million
    } else if (price < 500000000) {
      price += 100000000; // Step of 1 million for values between 100 million and 500 million
    } else if (price < 1000000000) {
      price += 500000000;  // Step of 100 million for values between 10 million and 1 billion
    } else if (price < 10000000000) {
      price += 1000000000; // Step of 100 million for values between 10 million and 1 billion
    } else {
      price += 10000000000; // Step of 10 billion for values above 100 billion
    }
  }

  return priceOptions;
};

export { generatePriceOptions, sellPrices, rentPrices, Prices };
