import React, { useState, Fragment } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { useAuth } from "../../context/auth";
import { useData } from "../../context/adData";

export default function MobileNavbar({ isOpen, setIsOpen, setIsDeleteOpen }) {
  const [auth, setAuth] = useAuth();
  const [ddata, setDdata] = useData();
  const navigate = useNavigate();

  const [state, setState] = useState({
    left: false,
  });

  const [open, setOpen] = useState(true);

  const [openCreateAd, setOpenCreateAd] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("");

  const loggedIn = auth?.user !== null && auth?.token !== "";

  const handleCreateAd = (e) => {
    setOpenCreateAd(!openCreateAd);
    e.stopPropagation();
  };

  const handleRentClick = (event) => {
    event.preventDefault();
    const approved = auth?.user?.info?.isApproved;

    if (approved) {
      navigate("/ad/create/rent/house");
    } else {
      setIsOpen(true);
    }
  };

  const handleSaleClick = (event) => {
    event.preventDefault();
    const approved = auth?.user?.info?.isApproved;

    if (approved) {
      navigate("/ad/create/sell/house");
    } else {
      setIsOpen(true);
    }
  };

  const handleSettings = (e) => {
    setOpenSettings(!openSettings);
    e.stopPropagation();
  };

  const handleMenuItemClick = (text) => {
    setActiveMenuItem(text);
  };

  const logout = () => {
    setAuth({ user: null, token: "" });
    setDdata({ adData: null });
    localStorage.removeItem("auth");
    localStorage.removeItem("cLocation");
    localStorage.removeItem("adData");
    localStorage.removeItem("profileFormData");
    localStorage.removeItem("profile");
    navigate("/login");
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 200,
        color: "black",
        border: "1px solid #BAE7FF",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          color: "black",
          fontFamily: "Figtree",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px",
          textAlign: "left",
          bgcolor: "white",
        }}
        component="div"
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText />
          </ListItemButton>
        </ListItem>
        <NavLink aria-current="page" to="/" aria-label="Home">
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor:
                  activeMenuItem === "Home" ? "  #e2770d" : "transparent",
                "&:hover": { color: "#e2770d" },
              }}
              onClick={() => handleMenuItemClick("Home")}
            >
              <ListItemText
                sx={{
                  color: activeMenuItem === "Home" ? "white" : "black",
                  "&:hover": { color: "#e2770d" },
                }}
                primary="Home"
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          aria-current="page"
          to="/nimble-rent-information"
          aria-label="Nimble rent"
        >
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor:
                  activeMenuItem === "Finance" ? "#e2770d" : "transparent",
                color: activeMenuItem === "Finance" ? "white" : "inherit",
                "&:hover": { color: "#e2770d" },
              }}
              onClick={() => handleMenuItemClick("Finance")}
            >
              <ListItemText
                sx={{
                  color: activeMenuItem === "Finance" ? "white" : "black",
                  "&:hover": { color: "#e2770d" },
                }}
                primary="Finance 🔥"
              />
            </ListItemButton>
          </ListItem>{" "}
        </NavLink>

        <NavLink aria-current="page" to="/buy" aria-label="Nimble Buy">
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor:
                  activeMenuItem === "Buy" ? "#e2770d" : "transparent",
                color: activeMenuItem === "Buy" ? "white" : "inherit",
                "&:hover": { color: "#e2770d" },
              }}
              onClick={() => handleMenuItemClick("Buy")}
            >
              <ListItemText
                sx={{
                  color: activeMenuItem === "Buy" ? "white" : "black",
                  "&:hover": { color: "#e2770d" },
                }}
                primary="Buy"
              />
            </ListItemButton>
          </ListItem>{" "}
        </NavLink>

        <NavLink aria-current="page" to="/rent" aria-label="Rent">
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor:
                  activeMenuItem === "Rent" ? "#e2770d" : "transparent",
                color: activeMenuItem === "Rent" ? "white" : "inherit",
                "&:hover": { color: "#e2770d" },
              }}
              onClick={() => handleMenuItemClick("Rent")}
            >
              <ListItemText
                sx={{
                  color: activeMenuItem === "Rent" ? "white" : "black",
                  "&:hover": { color: "#e2770d" },
                }}
                primary="Rent"
              />
            </ListItemButton>
          </ListItem>{" "}
        </NavLink>

        <NavLink aria-current="page" to="/contact-agents" aria-label="Sell">
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor:
                  activeMenuItem === "Sell" ? "#e2770d" : "transparent",
                color: activeMenuItem === "Sell" ? "white" : "inherit",
                "&:hover": { color: "#e2770d" },
              }}
              onClick={() => handleMenuItemClick("Sell")}
            >
              <ListItemText
                sx={{
                  color: activeMenuItem === "Sell" ? "white" : "black",
                  "&:hover": { color: "#e2770d" },
                }}
                primary="Sell"
              />
            </ListItemButton>
          </ListItem>{" "}
        </NavLink>

        <NavLink
          aria-current="page"
          to="https://blog.nimblecasa.com/"
          aria-label="nimblecasa blog"
        >
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor:
                  activeMenuItem === "Blog" ? "#e2770d" : "transparent",
                color: activeMenuItem === "Blog" ? "white" : "inherit",
                "&:hover": { color: "#e2770d" },
              }}
              onClick={() => handleMenuItemClick("Blog")}
            >
              <ListItemText
                sx={{
                  color: activeMenuItem === "Blog" ? "white" : "black",
                  "&:hover": { color: "#e2770d" },
                }}
                primary="Blog"
              />
            </ListItemButton>
          </ListItem>{" "}
        </NavLink>

        <NavLink aria-current="page" to="/contact-us" aria-label="Contact Us">
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor:
                  activeMenuItem === "Contact Us" ? "#e2770d" : "transparent",
                color: activeMenuItem === "Contact Us" ? "white" : "inherit",
                "&:hover": { color: "#e2770d" },
              }}
              onClick={() => handleMenuItemClick("Contact Us")}
            >
              <ListItemText
                sx={{
                  color: activeMenuItem === "Contact Us" ? "white" : "black",
                  "&:hover": { color: "#e2770d" },
                }}
                primary="Contact Us"
              />
            </ListItemButton>
          </ListItem>{" "}
        </NavLink>
        <Divider />
      </List>

      {auth?.user?.role?.includes("Agent") ||
      auth?.user?.role?.includes("Admin") ? (
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            color: "black",
            fontFamily: "Figtree",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px",
            textAlign: "left",
            bgcolor: "white",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={handleCreateAd}>
            <ListItemText primary="Create Ad" />
            {openCreateAd ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openCreateAd} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{
                width: "100%",
                maxWidth: 360,
                color: "black",
                fontFamily: "Figtree",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                textAlign: "left",

                bgcolor: "white",
              }}
            >
              <NavLink
                aria-current="page"
                to="/ad/create/sell/house"
                aria-label="Sale"
                onClick={handleSaleClick}
              >
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeMenuItem === "Sale" ? "#e2770d" : "transparent",
                    color: activeMenuItem === "Sale" ? "white" : "inherit",
                    "&:hover": { color: "#e2770d" },
                  }}
                  onClick={() => {
                    if (isOpen) handleMenuItemClick("Sale");
                  }}
                >
                  <ListItemText
                    sx={{
                      color: activeMenuItem === "Sale" ? "white" : "black",
                      "&:hover": { color: "#e2770d" },
                    }}
                    primary="Sale"
                  />
                </ListItemButton>
              </NavLink>
              <NavLink
                aria-current="page"
                to="/ad/create/rent/house"
                aria-label="CreateAdRent"
                onClick={handleRentClick}
              >
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeMenuItem === "CreateAdRent"
                        ? "#e2770d"
                        : "transparent",
                    color:
                      activeMenuItem === "CreateAdRent" ? "white" : "inherit",
                    "&:hover": { color: "#e2770d" },
                  }}
                  onClick={() => {
                    if (isOpen) handleMenuItemClick("CreateAdRent");
                  }}
                >
                  <ListItemText
                    sx={{
                      color:
                        activeMenuItem === "CreateAdRent" ? "white" : "black",
                      "&:hover": { color: "#e2770d" },
                    }}
                    primary="Rent"
                  />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>
          <Divider />
        </List>
      ) : (
        ""
      )}

      {loggedIn ? (
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            color: "black",
            fontFamily: "Figtree",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px",
            textAlign: "left",

            bgcolor: "white",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={handleSettings}>
            <ListItemText primary="Settings" />
            {openSettings ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{
                width: "100%",
                maxWidth: 360,
                color: "black",
                fontFamily: "Figtree",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                textAlign: "left",

                bgcolor: "white",
              }}
            >
              <NavLink
                aria-current="page"
                to="/dashboard"
                aria-label={
                  auth?.user?.role?.includes("Agent")
                    ? "Open Dashboard"
                    : "Open Wishlist"
                }
              >
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeMenuItem === "DashWish" ? "#e2770d" : "transparent",
                    color: activeMenuItem === "DashWish" ? "white" : "inherit",
                    "&:hover": { color: "#e2770d" },
                  }}
                  onClick={() => handleMenuItemClick("DashWish")}
                >
                  <ListItemText
                    sx={{
                      color: activeMenuItem === "DashWish" ? "white" : "black",
                      "&:hover": { color: "#e2770d" },
                    }}
                    primary={
                      auth?.user?.role?.includes("Agent")
                        ? "Dashboard"
                        : "Wishlist"
                    }
                  />
                </ListItemButton>
              </NavLink>

              <NavLink
                aria-current="page"
                to="/user/profile"
                aria-label="Update Profile"
              >
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeMenuItem === "Update Profile"
                        ? "#e2770d"
                        : "transparent",
                    color:
                      activeMenuItem === "Update Profile" ? "white" : "inherit",
                    "&:hover": { color: "#e2770d" },
                  }}
                  onClick={() => handleMenuItemClick("Update Profile")}
                >
                  <ListItemText
                    sx={{
                      color:
                        activeMenuItem === "Update Profile" ? "white" : "black",
                      "&:hover": { color: "#e2770d" },
                    }}
                    primary="Update Profile"
                  />
                </ListItemButton>
              </NavLink>

              <NavLink
                aria-current="page"
                to="/user/update-password"
                aria-label="change password"
              >
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeMenuItem === "Change Password"
                        ? "#e2770d"
                        : "transparent",
                    color:
                      activeMenuItem === "Change Password"
                        ? "white"
                        : "inherit",
                    "&:hover": { color: "#e2770d" },
                  }}
                  onClick={() => handleMenuItemClick("Change Password")}
                >
                  <ListItemText
                    sx={{
                      color:
                        activeMenuItem === "Change Password"
                          ? "white"
                          : "black",
                      "&:hover": { color: "#e2770d" },
                    }}
                    primary="Change Password"
                  />
                </ListItemButton>
              </NavLink>

              <NavLink
                aria-current="page"
                aria-label="delete account"
                onClick={setIsDeleteOpen}
              >
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor:
                      activeMenuItem === "Delete Account"
                        ? "#e2770d"
                        : "transparent",
                    color:
                      activeMenuItem === "Delete Account" ? "white" : "inherit",
                    "&:hover": { color: "#e2770d" },
                  }}
                >
                  <ListItemText
                    sx={{
                      color:
                        activeMenuItem === "Delete Account" ? "white" : "black",
                      "&:hover": { color: "#e2770d" },
                    }}
                    primary="Delete Account"
                  />
                </ListItemButton>
              </NavLink>

              {!auth.user?.role?.includes("Agent") && (
                <NavLink
                  aria-current="page"
                  to="/user/be-agent"
                  aria-label="Become an Agent"
                  onClick={isOpen}
                >
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor:
                        activeMenuItem === "Become an Agent"
                          ? "#e2770d"
                          : "transparent",
                      color:
                        activeMenuItem === "Become an Agent"
                          ? "white"
                          : "inherit",
                      "&:hover": { color: "#e2770d" },
                    }}
                    onClick={() => handleMenuItemClick("Become an Agent")}
                  >
                    <ListItemText
                      sx={{
                        color:
                          activeMenuItem === "Become an Agent"
                            ? "white"
                            : "black",
                        "&:hover": { color: "#e2770d" },
                      }}
                      primary="Become an Agent"
                    />
                  </ListItemButton>
                </NavLink>
              )}
            </List>
          </Collapse>

          <ListItemButton
            onClick={logout}
            sx={{
              backgroundColor:
                activeMenuItem === "Logout" ? "#e2770d" : "transparent",
              color: activeMenuItem === "Logout" ? "white" : "inherit",
              "&:hover": { color: "#e2770d" },
            }}
            // onClick={() => handleMenuItemClick("Logout")}
          >
            <ListItemText
              sx={{
                color: activeMenuItem === "Logout" ? "white" : "black",
                "&:hover": { color: "#e2770d" },
              }}
              primary="Logout"
            />
          </ListItemButton>
          {/* <Divider /> */}
        </List>
      ) : (
        ""
      )}

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            color: "black",
            fontFamily: "Figtree",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px",
            textAlign: "left",

            bgcolor: "white",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {!loggedIn ? (
            <>
              <NavLink aria-current="page" to="/login" aria-label="Login">
                <ListItemButton
                  sx={{
                    backgroundColor:
                      activeMenuItem === "Login" ? "#e2770d" : "transparent",
                    color: activeMenuItem === "Login" ? "white" : "inherit",
                    "&:hover": { color: "#e2770d" },
                  }}
                  onClick={() => handleMenuItemClick("Login")}
                >
                  <ListItemText
                    sx={{
                      color: activeMenuItem === "Login" ? "white" : "black",
                      "&:hover": { color: "#e2770d" },
                    }}
                    primary="Login"
                  />
                </ListItemButton>
              </NavLink>

              <NavLink aria-current="page" to="/register" aria-label="Register">
                <ListItemButton
                  sx={{
                    backgroundColor:
                      activeMenuItem === "Create Account"
                        ? "#e2770d"
                        : "transparent",
                    color:
                      activeMenuItem === "Create Account" ? "white" : "inherit",
                    "&:hover": { color: "#e2770d" },
                  }}
                  onClick={() => handleMenuItemClick("Create Account")}
                >
                  <ListItemText
                    sx={{
                      color:
                        activeMenuItem === "Create Account" ? "white" : "black",
                      "&:hover": { color: "#e2770d" },
                    }}
                    primary="Create Account"
                  />
                </ListItemButton>
              </NavLink>
            </>
          ) : (
            ""
          )}
        </List>
      </Collapse>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.99992 0.666504H10.3333C10.7753 0.666504 11.1992 0.842099 11.5118 1.15466C11.8243 1.46722 11.9999 1.89114 11.9999 2.33317C11.9999 2.7752 11.8243 3.19912 11.5118 3.51168C11.1992 3.82424 10.7753 3.99984 10.3333 3.99984H1.99992C1.55789 3.99984 1.13397 3.82424 0.821407 3.51168C0.508847 3.19912 0.333252 2.7752 0.333252 2.33317C0.333252 1.89114 0.508847 1.46722 0.821407 1.15466C1.13397 0.842099 1.55789 0.666504 1.99992 0.666504ZM13.6666 13.9998H21.9999C22.4419 13.9998 22.8659 14.1754 23.1784 14.488C23.491 14.8006 23.6666 15.2245 23.6666 15.6665C23.6666 16.1085 23.491 16.5325 23.1784 16.845C22.8659 17.1576 22.4419 17.3332 21.9999 17.3332H13.6666C13.2246 17.3332 12.8006 17.1576 12.4881 16.845C12.1755 16.5325 11.9999 16.1085 11.9999 15.6665C11.9999 15.2245 12.1755 14.8006 12.4881 14.488C12.8006 14.1754 13.2246 13.9998 13.6666 13.9998ZM1.99992 7.33317H21.9999C22.4419 7.33317 22.8659 7.50877 23.1784 7.82133C23.491 8.13389 23.6666 8.55781 23.6666 8.99984C23.6666 9.44186 23.491 9.86579 23.1784 10.1783C22.8659 10.4909 22.4419 10.6665 21.9999 10.6665H1.99992C1.55789 10.6665 1.13397 10.4909 0.821407 10.1783C0.508847 9.86579 0.333252 9.44186 0.333252 8.99984C0.333252 8.55781 0.508847 8.13389 0.821407 7.82133C1.13397 7.50877 1.55789 7.33317 1.99992 7.33317Z"
                fill="black"
              />
            </svg>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}
