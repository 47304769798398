import React, { useState, useEffect } from "react";
import axios from "axios";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useAuth } from "../../../context/auth";
import Modall from "../../../components/modal/Modal";
import LogoutMessage from "../../../components/misc/logoutMessage/LogoutMessage";
import Wishlist from "../wishlist/Wishlist";
import ListedAds from "../listedAds/ListedAds";
import CustomDashboardCard from "../../../components/customDashboardCard/CustomDashboardCard";

// import CustomTabs from "../../../components/customTabs/CustomTab";

import "./Dashboard.css";

const CustomTabs = ({ value, handleChange, tabData }) => {
  const isSmScreen = useMediaQuery("(max-width: 389px)");
  const isMdScreen = useMediaQuery("(min-width: 390px) and (max-width: 600px)");
  const isLgScreen = useMediaQuery(
    "(min-width: 601px) and (max-width: 1024px)"
  );

  const paddingStyle = isSmScreen
    ? "12px 10px"
    : isMdScreen
    ? "12px 12px"
    : "12px 20px";

  const widthStyle = isSmScreen
    ? "110px"
    : isMdScreen
    ? "120px"
    : isLgScreen
    ? "240px"
    : "255px";

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
      TabIndicatorProps={{
        style: { display: "none" },
      }}
      orientation="horizontal"
    >
      {tabData.map((tab) => (
        <Tab
          key={tab.value}
          sx={{
            border: "1px solid gray",
            width: widthStyle,
            minWidth: "60px",
            height: 53,
            color: "#808080",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 500,
            padding: paddingStyle,
            lineHeight: "21px",
            ...(tab.value === "wishlist" &&
              tab.borderRadius && {
                borderTopLeftRadius: tab.borderRadius,
                borderBottomLeftRadius: tab.borderRadius,
              }),
            ...(tab.value === "messages" && {
              borderTopRightRadius: tab.borderRadius,
              borderBottomRightRadius: tab.borderRadius,
            }),
            textTransform: "Capitalize",
          }}
          value={tab.value}
          label={tab.label}
          disabled={tab.disabled}
        />
      ))}
    </Tabs>
  );
};

export default function Dashboard() {
  // context
  const [auth, setAuth] = useAuth();
  // state
  const [ads, setAds] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("wishlist");
  const [orientation, setOrientation] = useState("horizontal");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [loading, setLoading] = useState(false);

  const agent = auth.user?.role?.includes("Agent");

  const tabData = [
    { value: "wishlist", label: "Liked Properties", borderRadius: "10px" },
    { value: "listAds", label: "Listed Properties" },
    {
      value: "messages",
      label: "Messages",
      borderRadius: "10px",
      disabled: "disabled",
    },
  ];

  useEffect(() => {
    fetchAds();
  }, [auth.token !== ""]);

  useEffect(() => {
    if (page === 1) return;
    fetchAds();
  }, [page, agent]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      // Set orientation to vertical on small screens
      setOrientation(window.innerWidth <= 600 ? "vertical" : "horizontal");
    };

    handleResize(); // Call initially
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, []);

  const fetchAds = async () => {
    try {
      const { data } = await axios.get(
        `/user-ads/${auth.user?.userId}/${page}/${perPage}`
      );

      setAds([...ads, ...data.ads]);
      setTotal(data.total);
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        className={
          windowWidth > 1500 ? "container page-wrapper" : "page-wrapper"
        }
      >
        <LogoutMessage>
          <Modall handleClose={() => setIsOpen(false)} isOpen={isOpen}>
            <p className="header-modal">Request Pending Approval</p>
            <div className="info-modal">
              Dear {auth?.user?.lastName},
              <br />
              <br />
              Your application to become an agent is under review. We typically
              process requests within 1-3 business days. You will receive an
              email once a decision is made.
              <br />
              <br />
              For any queries, feel free to contact us.
              <br />
              <br />
              Best,
              <br />
              NimbleCasa Team
            </div>
          </Modall>

          {/* {!auth?.user?.info?.isApproved &&
          auth?.user?.role?.includes("Agent") && (
            <div className="notice">
              Your request to become an agent is pending approval
            </div>
          )} */}

          <div className="dashboard-main-container">
            <div className="dashboard-cards-container">
              {agent && (
                <CustomDashboardCard
                  title="Total properties listed"
                  number={total}
                  iconSrc={
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.375 8.47878V16.0626C16.375 16.4107 16.2367 16.7445 15.9906 16.9906C15.7444 17.2368 15.4106 17.3751 15.0625 17.3751H11.7812C11.4332 17.3751 11.0993 17.2368 10.8532 16.9906C10.607 16.7445 10.4688 16.4107 10.4688 16.0626V12.7813C10.4688 12.6073 10.3996 12.4404 10.2765 12.3173C10.1535 12.1942 9.98655 12.1251 9.8125 12.1251H7.1875C7.01345 12.1251 6.84653 12.1942 6.72346 12.3173C6.60039 12.4404 6.53125 12.6073 6.53125 12.7813V16.0626C6.53125 16.4107 6.39297 16.7445 6.14683 16.9906C5.90069 17.2368 5.56685 17.3751 5.21875 17.3751H1.9375C1.5894 17.3751 1.25556 17.2368 1.00942 16.9906C0.763281 16.7445 0.625 16.4107 0.625 16.0626V8.47878C0.624972 8.29713 0.662652 8.11745 0.735653 7.95112C0.808655 7.78478 0.915389 7.63541 1.0491 7.51245L7.6116 1.32073L7.62063 1.31171C7.86224 1.09198 8.1771 0.970215 8.50369 0.970215C8.83028 0.970215 9.14514 1.09198 9.38676 1.31171C9.38956 1.31492 9.39257 1.31793 9.39578 1.32073L15.9583 7.51245C16.0907 7.63606 16.196 7.78572 16.2677 7.95202C16.3394 8.11833 16.376 8.29767 16.375 8.47878Z"
                        fill="white"
                      />
                    </svg>
                  }
                />
              )}
              {agent && (
                <CustomDashboardCard
                  title="Total offers gotten"
                  number={"NA"}
                  iconSrc={
                    <svg
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="15.5" cy="15.5" r="15.5" fill="#EE7B0D" />
                      <path
                        d="M25 17C25.6 17 26.1 17.2 26.4 17.6C26.8 18 27 18.5 27 19L19 22L12 20V11H13.9L21.2 13.7C21.7 13.9 22 14.3 22 14.8C22 15.1 21.9 15.4 21.7 15.6C21.5 15.8 21.2 16 20.8 16H18L16.3 15.3L16 16.2L18 17H25ZM6 11H10V22H6V11Z"
                        fill="#FEF7F1"
                      />
                    </svg>
                  }
                />
              )}

              {agent && (
                <CustomDashboardCard
                  title="Closed deals"
                  number={"NA"}
                  iconSrc={
                    <svg
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="15.5" cy="15.5" r="15.5" fill="#EE7B0D" />
                      <path
                        d="M15 10.0001H18L21.29 6.7001C21.3829 6.60637 21.4935 6.53197 21.6154 6.4812C21.7373 6.43044 21.868 6.4043 22 6.4043C22.132 6.4043 22.2627 6.43044 22.3846 6.4812C22.5064 6.53197 22.617 6.60637 22.71 6.7001L25.29 9.2901C25.4762 9.47746 25.5808 9.73091 25.5808 9.9951C25.5808 10.2593 25.4762 10.5127 25.29 10.7001L23 13.0001H15V15.0001C15 15.2653 14.8946 15.5197 14.7071 15.7072C14.5196 15.8947 14.2652 16.0001 14 16.0001C13.7348 16.0001 13.4804 15.8947 13.2929 15.7072C13.1053 15.5197 13 15.2653 13 15.0001V12.0001C13 11.4697 13.2107 10.961 13.5858 10.5859C13.9608 10.2108 14.4695 10.0001 15 10.0001ZM8.99998 15.0001V19.0001L6.70998 21.2901C6.52373 21.4775 6.41919 21.7309 6.41919 21.9951C6.41919 22.2593 6.52373 22.5127 6.70998 22.7001L9.28998 25.2901C9.38295 25.3838 9.49355 25.4582 9.61541 25.509C9.73726 25.5598 9.86797 25.5859 9.99998 25.5859C10.132 25.5859 10.2627 25.5598 10.3846 25.509C10.5064 25.4582 10.617 25.3838 10.71 25.2901L15 21.0001H19C19.2652 21.0001 19.5196 20.8947 19.7071 20.7072C19.8946 20.5197 20 20.2653 20 20.0001V19.0001H21C21.2652 19.0001 21.5196 18.8947 21.7071 18.7072C21.8946 18.5197 22 18.2653 22 18.0001V17.0001H23C23.2652 17.0001 23.5196 16.8947 23.7071 16.7072C23.8946 16.5197 24 16.2653 24 16.0001V15.0001H17V16.0001C17 16.5305 16.7893 17.0392 16.4142 17.4143C16.0391 17.7894 15.5304 18.0001 15 18.0001H13C12.4695 18.0001 11.9608 17.7894 11.5858 17.4143C11.2107 17.0392 11 16.5305 11 16.0001V13.0001L8.99998 15.0001Z"
                        fill="white"
                      />
                    </svg>
                  }
                />
              )}

              <CustomDashboardCard
                title="Liked Properties"
                number={auth?.wishlist?.length || 0}
                iconSrc={
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="15.5" cy="15.5" r="15.5" fill="#EE7B0D" />
                    <path
                      d="M15 25.35L13.55 24.03C8.4 19.36 5 16.27 5 12.5C5 9.41 7.42 7 10.5 7C12.24 7 13.91 7.81 15 9.08C16.09 7.81 17.76 7 19.5 7C22.58 7 25 9.41 25 12.5C25 16.27 21.6 19.36 16.45 24.03L15 25.35Z"
                      fill="white"
                    />
                  </svg>
                }
              />
            </div>

            <div className="dashboard-tabs-container">
              {agent && (
                <CustomTabs
                  value={value}
                  handleChange={handleChange}
                  tabData={tabData}
                />
              )}
            </div>

            <div className="dashboard-ads-container">
              {value === "listAds" && agent && <ListedAds />}
              {value === "wishlist" && <Wishlist />}
            </div>
          </div>
        </LogoutMessage>
      </div>
    </>
  );
}
