import React from "react";
import "./CustomDashboardCard.css";

const Icon = ({ src }) => {
  if (typeof src === "string") {
    return <img src={src} alt="icon" />;
  }
  return src;
};

const CustomDashboardCard = ({ title, number, iconSrc }) => {
  return (
    <div className="card dashboard-cards">
      <div className="card-content dashboard-cards-content">
        <div className="card-title dashboard-cards-title">{title}</div>
        <div className="card-body dashboard-cards-body">
          <div className="dashboard-cards-number">{number}</div>
          <div className="dashboard-cards-circle-icon">
            <div className="dashboard-cards-icon">
              <Icon src={iconSrc} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDashboardCard;
