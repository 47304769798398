import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import useTheme from "@mui/system/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";

import AdFeatures from "../../../components/cards/AdFeatures";
import { formatNumber } from "../../../helpers/ad";
import CustomButton from "../../customButton/CustomButton";
import {
  ImageGallery,
  generatePhotosArray,
} from "../../misc/imageGallery/ImageGallery";

import UserAdUpdateButton from "./UserAdUpdateButton";

import { useAuth } from "../../../context/auth";

import "./UserAdCard.css";

export default function UserAdCard({ ad, setIsOpen, approved }) {
  const [auth, setAuth] = useAuth();

  const theme = useTheme();

  const [showBottom, setShowBottom] = useState(false);
  const [availablityStatus, setAvailablityStatus] = useState(ad.sold);
  const [userId, setUserId] = useState("");

  const initialRender = useRef(true);

  // const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmScreen = useMediaQuery("(max-width:768px)");
  let navigate = useNavigate();

  const handleAvailabilityStatusUpdate = useCallback(async () => {
    try {
      const { data } = await axios.put(`/ad/${ad._id}/${userId}`, {
        ...ad,
        sold: availablityStatus,
      });

      // console.log("ad update response => ", data);
      if (data?.error) {
        toast.error(data.error);
      } else {
        toast.success("Ad Updated successfully");
        navigate("/dashboard");
      }
    } catch (err) {
      // console.log(err);
    }
  }, [availablityStatus]);

  useEffect(() => {
    setUserId(auth.user?.userId);
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleAvailabilityStatusUpdate();
    }
  }, [availablityStatus, handleAvailabilityStatusUpdate]);

  const handleChange = (event) => {
    event.preventDefault();
    setAvailablityStatus(event.target.value);
  };

  const getStatusStyle = () => {
    const commonStyle = {
      borderRadius: "90px",
      padding: "6px 20px",
      fontFamily: "Figtree",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    };

    switch (availablityStatus) {
      case "Available":
        return {
          ...commonStyle,
          color: "#0172CB",
          backgroundColor: "#C7E4FA",
        };
      case "Under Contract":
        return {
          ...commonStyle,
          color: "#0D585A",
          backgroundColor: "#9BD9DB",
        };
      case "Sold":
        return {
          ...commonStyle,
          backgroundColor: "#D21C1C",
          color: "white",
        };
      default:
        return {}; // Default style
    }
  };

  return (
    <div
      className="ad-card-container1"
      onMouseEnter={() => {
        setShowBottom(true);
      }}
      onMouseLeave={() => setShowBottom(false)}
    >
      <div className="card ad-card-inner-wrapper1">
        <div className="d-flex justify-content-between">
          <div className="ad-card-top">
            {ad?.publishedStatus === "Pending" && (
              <CustomButton
                label={
                  ad?.publishedStatus === "Pending" &&
                  ad?.sold === "Available" && <span>Pending Approval</span>
                }
                style={
                  ad?.publishedStatus === "Pending" &&
                  ad?.sold === "Available" && {
                    background: "#FAE2C6",
                    borderRadius: "90px",
                    padding: "6px 20px",
                  }
                }
                labelClassName={
                  ad?.publishedStatus === "Pending" && "pending-button-label"
                }
              />
            )}
            {ad?.publishedStatus === "Published" && (
              // <FormControl sx={{ m: 0.2, borderRadius: "20px", minWidth: 150 }}>
              <Select
                value={availablityStatus}
                onChange={handleChange}
                inputProps={{ "aria-label": "Without label" }}
                SelectDisplayProps={{
                  style: { padding: 1, minWidth: 150 },
                }}
                sx={getStatusStyle()}
              >
                <MenuItem value={"Available"}>Available</MenuItem>
                <MenuItem value={"Under Contract"}>Under Contract</MenuItem>
                <MenuItem value={"Sold"}>
                  {ad?.action === "Sell" ? "Sold" : "Rented"}
                </MenuItem>
              </Select>
              // </FormControl>
            )}
          </div>
        </div>

        <ImageGallery
          photos={generatePhotosArray(ad?.photos)}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          height={"322"}
          width={"322"}
        />
        <div className="card-body1 ad-card-body1">
          <div className="ad-card-property-title1">
            {ad?.propertyTitle ||
              (ad?.houseType && `${ad?.houseType} property`) ||
              `${ad?.type} property`}
          </div>

          <div className="card-text address-height1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 40 40"
              stroke="currentColor"
              className="custom-svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>

            {ad?.landmark}
          </div>

          <div className="ad-features">
            <AdFeatures ad={ad} />
          </div>

          <div className="d-flex justify-content-between">
            <div className="d-flex flex-direction-row">
              <div className="ad-price d-flex flex-direction-row">
                {" "}
                <span className="ad-naira">
                  {" "}
                  <img
                    src="https://nimblecasauatstorage.blob.core.windows.net/naira-icon/naira.svg"
                    alt="N"
                    width={30}
                    height={30}
                  />
                </span>
                <span className="naira-value">{formatNumber(ad?.price)}</span>
              </div>
              {ad?.type === "Land" && ad?.areaPerPrice && (
                <h5 className="usecard-per-sqft">
                  {" "}
                  &nbsp;
                  <span>
                    {" "}
                    <em>per</em>
                  </span>
                  &nbsp;
                  {ad?.areaPerPrice || ""}
                </h5>
              )}
            </div>
          </div>

          <div
            className="ad-card-bottom "
            // style={
            //   isSmScreen
            //     ? { display: showBottom ? "block" : "block" }
            //     : { display: showBottom ? "block" : "none" }
            // }
          >
            <div className="pt-3 d-flex justify-content-between align-items-center">
              <p className="card-text day-posted">
                Posted {dayjs(ad?.createdAt).fromNow()}
              </p>
              <p className="justify-content-end">
                {/* <Link className="bg-white" to={`/user/ad/${ad._id}`}> */}
                {/* <CustomButton
                    label="Update Property"
                    className="contact-agent-button"
                    labelClassName="contact-agent"
                  /> */}

                <UserAdUpdateButton ad={ad} userId={userId} />
                {/* </Link> */}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <pre>{JSON.stringify(ad, null, 4)} </pre> */}
    </div>
  );
}
