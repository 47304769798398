import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import axios from "axios";
import { ShimmerPostList } from "react-shimmer-effects";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import AdCard from "../../components/cards/newAdCard/AdCard";
import { useAuth } from "../../context/auth";
import RowPerPage from "../../components/rowPerPage/RowPerPage";
// import SearchForm from "../../components/forms/SearchForm";
import SearchForm from "../../components/newSearchForm/SearchForm";

import CustomBreadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

import "./AdsPage.css";

const AdsByTypePage = () => {
  let count = 0;
  count++;

  // context
  const [auth] = useAuth();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  // state
  const [ads, setAds] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // hooks
  const params = useParams();
  const adType = params.adType;

  useEffect(() => {
    if (params?.id) fetchAds();
    window.scrollTo(0, 0);
  }, [params?.adType]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params?.adType]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (auth.user === null) {
      auth.token = "";
    }
    fetchAds();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAds();
  }, [page, perPage]);

  useEffect(() => {
    setPage(1);
    window.scrollTo(0, 0);
  }, [perPage]);

  const fetchAds = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `/ads/ad-type/${adType}/${page}/${perPage}`
      );

      setAds(data?.ads);

      setTotal(data.total);
      setLoading(false);
    } catch (err) {
      // console.log(err);
      setLoading(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <SearchForm navMenuProperty={true} />
      {/* <CustomBreadcrumbs
        paths={[
          { href: "/", label: "Home" },
          {
            href: "/nimble-rent-info",
            label: "Nimble Rent Info page",
          },
          { label: "Nimble Rent Ad page" },
        ]}
      /> */}
      <div
        className={
          windowWidth > 1500 ? "container page-wrapper" : "page-wrapper"
        }
      >
        <div className="d-flex row nimble-rent-container">
          {loading ? (
            <div style={{ padding: "40px 0" }}>
              <ShimmerPostList
                postStyle="STYLE_FOUR"
                col={4}
                row={2}
                gap={30}
              />
            </div>
          ) : (
            <>
              <h3 className="nimble-rent-title">
                {adType} {ads?.length > 1 ? "Properties" : "Property"}
              </h3>
              {ads?.map((ad) => (
                <AdCard ad={ad} key={ad._id} page="nimblerent" />
              ))}
            </>
          )}
        </div>
        {ads?.length > 0 ? (
          <div className="row small-screen-page-navigation">
            <div className="col text-center mt-4 mb-4">
              <Stack spacing={2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    color="primary"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    count={Math.ceil(total / perPage)}
                    page={page}
                    onChange={handleChange}
                  />
                  <RowPerPage
                    total={total}
                    rowPerPage={perPage}
                    setRowPerPage={setPerPage}
                  />
                </div>
              </Stack>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AdsByTypePage;
