import axios from "axios";

const fetchAds = async (page, perPage) => {
  const totals = {};
  const titles = ["House", "Land", "Commercial", "Industrial"];

  try {
    for (const title of titles) {
      const { data } = await axios.get(`/ads/home/${title}/${page}/${perPage}`);
      totals[title] = data.total;
    }
  } catch (err) {
    console.log(err);
  }
  return totals;
};

export default fetchAds;
