import React from "react";
import Sidebar from "../../../components/nav/Sidebar";
// import AdForm from "../../../components/forms/AdForm";
import AdForm from "../../../components/forms/adForm/AdForm";

export default function RentHouse() {
  return (
    <div className="d-flex justify-content-center">
      {/* <div className="container-fluid pt-5 background-color "> */}
      <AdForm action="Rent" type="House" />
      {/* </div> */}
    </div>
  );
}
