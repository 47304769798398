import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../customButton/CustomButton";
import "./NimbleRentReferralBanner.css";

const NimbleRentBottomBanner = () => {
  return (
    <div className="page-wrapper">
      <div className="page ">
        <div
          className="col-12 bottom-banner-container"
          style={{ backgroundColor: "#ee7b0d" }}
        >
          <div className="col-6 bottom-banner-box-word">
            <p className="referral-banner-join">Join our Referral Program</p>
            <h4 className="referral-banner-word">
              You can earn <span className="referral-banner-text2">competitive commissions</span> for every successful property
              sale by referring potential buyer
            </h4>
          </div>
          <img
            src="./referralImage.png"
            alt="image house"
            className="referral-banner-image"
          />

          <div className="col-2 referral-button">
            <Link
              to={"/referral-page"}
              aria-label="nimble rent information"
            >
              <button className="referral-check">Sign-Up Now</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NimbleRentBottomBanner;
