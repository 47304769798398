/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { IoMdPerson } from "react-icons/io";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ClearIcon from "@mui/icons-material/Clear";
import { FaStickyNote } from "react-icons/fa";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";

import { useAuth } from "../../context/auth";
import config from "../../config.js";
import { contactSellerFormSchema } from "../../../src/validations";
import BuyerTermsandConditions from "../../documents/BuyerTermsandConditions";
import Modall from "../modal/Modal";
import AdCard from "../cards/newAdCard/AdCard.js";
import RequestSubmissionSuccessModal from "../requestSubmissionSuccessModal/RequestSubmissionSuccessModal.js";
import CustomButton from "../customButton/CustomButton.js";
import "./ContactSellerModal.css";

const ContactSellerModal = ({ ad, setIsOpen, onClose }) => {
  // context
  const [auth, setAuth] = useAuth();
  // state

  const [loading, setLoading] = useState(false);

  const [isRequestSuccessModalOpen, setIsRequestSuccessModalOpen] =
    useState(false);

  const [agent, setAgent] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [checkedNimbleRent, setCheckedNimbleRent] = React.useState(false);
  const [NimbleRentOfferInterest, setNimbleRentOfferInterest] =
    React.useState();

  const isSmScreen = useMediaQuery("(max-width:768px)");

  const isMediumScreen = useMediaQuery("(max-width:1200px)");

  const [inputValues, setInputValues] = useState({
    name: auth?.user?.firstName || "",
    phone: auth?.user?.phone || "",
    email: auth?.user?.email || "",
    message: `Hi, I am interested in the property located at ${
      ad?.landmarkGoogleMap[0]?.city || ad?.landmarkGoogleMap[0]?.country || ""
    }. `,
  });

  useEffect(() => {
    if (ad?.nimbleRentStatus) {
      setCheckedNimbleRent(true);
      setNimbleRentOfferInterest("Interested in Nimble rent offer");
    } else {
      setNimbleRentOfferInterest("Not Interested in Nimble rent offer");
    }
  }, []);

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    checkedNimbleRent
      ? setNimbleRentOfferInterest("Interested in Nimble rent offer")
      : setNimbleRentOfferInterest("Not Interested in Nimble rent offer");
  }, [checkedNimbleRent]);

  const handleClickClear = (fieldName) => {
    setInputValues((prevValues) => {
      const updatedValues = { ...prevValues, [fieldName]: "" };
      console.log("up values", updatedValues); // Log updated values
      return updatedValues;
    });
  };

  const handleChangeNimble = (event) => {
    setCheckedNimbleRent((prevChecked) => !prevChecked);
  };

  const handleTermsandPolicyCheck = (event) => {
    setChecked(event.target.checked);
  };

  const fetchAgents = async () => {
    try {
      const { data } = await axios.get(
        `${config.AUTH_API}/api/Roles/GetUsersByRole?roleName=Agent`
      );
      setAgent(
        data?.responsePayload.filter((a) => {
          return a.userId === ad?.postedBy;
        })
      );
      // setLoading(false);
    } catch (err) {
      console.log(err);
      // setLoading(false);
    }
  };

  const onSubmit = async (values, actions) => {
    const { name, email, message, phone } = values;

    setLoading(true);
    try {
      const response = await axios.post(
        `${config.AUTH_API}/api/ContactSeller`,
        {
          adId: ad?._id || "",
          message: ad?.nimbleRentStatus
            ? `${message}, I am ${NimbleRentOfferInterest}. Thanks`
            : `${message}, Thnaks`,

          sellerEmail: agent[0]?.email.toString() || "",
          enquirerEmail: email,
          propertyPageUrl: `${config.CLIENT_BASE_URL}/ad/${ad?._id}` || "",
          sellerName: agent[0]?.firstName || "",
          enquirerName: name,
          enquirerPhone: phone,
          propertyAddress: ad?.address || "",
          adminEmail: config.AdminEmail || "",
        }
      );
      setIsRequestSuccessModalOpen(true);
      // toast.success("Your enquiry has been sent to the agent");
      setLoading(false);
      // setIsOpen(false);
    } catch (err) {
      console.error("enquiry error", err);
      setIsOpen(false);
      // setIsRequestSuccessModalOpen(true);

      toast.error("Something went wrong! Try again.");
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    isSubmitting,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: inputValues,
    //  {
    //   name: auth?.user?.firstName || "",
    //   phone: auth?.user?.phone || "",
    //   email: auth?.user?.email || "",
    //   message: `Hi, I am interested in the property located at ${
    //     ad?.landmarkGoogleMap[0]?.city ||
    //     ad?.landmarkGoogleMap[0]?.country ||
    //     ""
    //   }. Thanks`,
    // },
    validationSchema: contactSellerFormSchema,
    onSubmit,
  });

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "blue",
      padding: 2,
    },
  }));

  return (
    <>
      <Modall
        handleClose={() => setIsOpen(setIsRequestSuccessModalOpen)}
        isOpen={isRequestSuccessModalOpen}
        modalContentClassName={
          isSmScreen
            ? "small-request-success-modal-content"
            : "request-success-modal-content"
        }
        closeBtnClassName={
          // isSmScreen
          //   ? "small-request-success-close-btn"
          //   :
          "request-success-close-btn"
        }
      >
        <RequestSubmissionSuccessModal
          setIsRequestSuccessModalOpen={setIsRequestSuccessModalOpen}
          isRequestSuccessModalOpen={isRequestSuccessModalOpen}
          title={"Request form submission"}
          modalText={
            "Your request has been submitted, We will reach out to you within three working days. Thank you."
          }
        />
      </Modall>
      <Modall
        handleClose={() => setIsOpen1(false)}
        isOpen={isOpen1}
        modalContentClassName={
          isSmScreen
            ? "small-buyer-termsofuse-modal-content"
            : "buyer-termsofuse-modal-content"
        }
        closeBtnClassName={
          isSmScreen
            ? "small-buyer-termsofuse-close-btn"
            : "buyer-termsofuse-close-btn"
        }
      >
        <BuyerTermsandConditions setIsOpen1={setIsOpen1} />
      </Modall>

      {ad && (
        <div className="contact-agent-modal-main-inner-container">
          <form
            className={
              !isSmScreen
                ? isMediumScreen
                  ? "contact-agent-modal-contact-modal-medium"
                  : "contact-agent-modal-contact-modal"
                : "contact-agent-modal-contact-modal-mobile"
            }
            // onSubmit={handleSubmit}
          >
            {!isSmScreen && (
              <AdCard ad={ad} key={ad._id} tag={false} click={false} />
            )}

            <div className="modal-second-column">
              <div className="">
                <h3 className="modal-content-title">Contact this Property</h3>
                <div className="modal-content-subtitle">
                  Your information is safe with us. We will quickly contact you
                  to close this deal.
                </div>
              </div>
              {/* <div className="modal-image-wrapper">
                <img
                  src={ad?.photos[0].Location}
                  alt=""
                  className="property-image"
                  width={300}
                  height={30}
                />
              </div> */}
              <FormControl
                sx={{ m: 1, width: isSmScreen ? "auto" : "414px" }}
                variant="outlined"
              >
                <span className="modal-input-label">Full name</span>

                <OutlinedInput
                  size="small"
                  placeholder="Your name name"
                  name="name"
                  value={values.name}
                  // value={inputValues.name}
                  onChange={handleChange}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #D1D5DB",
                    marginBottom: "20px",
                    padding: "3.5px 20px",
                    background: " #F9FAFB",
                    marginRight: "0px",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IoMdPerson />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear text"
                        onClick={() => {
                          handleClickClear("name");
                          handleChange({ target: { name: "name", value: "" } });
                        }}
                        edge="end"
                      >
                        <ClearIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.phone && touched.name && (
                  <div className="mt-0 text-danger">
                    <h6>
                      {" "}
                      <p> {errors.name}</p>
                    </h6>
                  </div>
                )}

                <span className="modal-input-label">Email</span>
                <OutlinedInput
                  size="small"
                  placeholder="Please enter email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #D1D5DB",
                    marginBottom: "20px",
                    padding: "3.5px 20px",
                    background: " #F9FAFB",
                    marginRight: "0px",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IoMail />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear text"
                        onClick={() => {
                          handleClickClear("email");
                          handleChange({
                            target: { name: "email", value: "" },
                          });
                        }}
                        edge="end"
                      >
                        <ClearIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.email && touched.email && (
                  <div className="mt-0 text-danger">
                    <h6>
                      {" "}
                      <p> {errors.email}</p>
                    </h6>
                  </div>
                )}

                <span className="modal-input-label">Phone Number</span>
                <OutlinedInput
                  size="small"
                  placeholder="Please enter phone number"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #D1D5DB",
                    marginBottom: "20px",
                    padding: "3.5px 20px",
                    background: " #F9FAFB",
                    marginRight: "0px",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FaPhoneAlt />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear text"
                        onClick={() => {
                          handleClickClear("phone");
                          handleChange({
                            target: { name: "phone", value: "" },
                          });
                        }}
                        edge="end"
                      >
                        <ClearIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.phone && touched.phone && (
                  <div className="mt-0 text-danger">
                    <h6>
                      {" "}
                      <p> {errors.phone}</p>
                    </h6>
                  </div>
                )}

                <span className="modal-input-label">Quick note</span>
                <OutlinedInput
                  size="small"
                  placeholder="Please enter your message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  multiline
                  rows={3}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #D1D5DB",
                    marginBottom: "20px",
                    padding: "3.5px 20px",
                    background: " #F9FAFB",
                    marginRight: "0px",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FaStickyNote />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear text"
                        onClick={() => {
                          handleClickClear("message");
                          handleChange({
                            target: { name: "message", value: "" },
                          });
                        }}
                        edge="end"
                      >
                        <ClearIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {ad?.nimbleRentStatus &&
                  ad?.publishedStatus === "Published" && (
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={checkedNimbleRent}
                          onChange={handleChangeNimble}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={
                        <div className="nimble-rent-toggle">
                          <span>
                            {" "}
                            <strong>
                              Nimble Rent{" "}
                              <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/fire-IconLeft.png" />
                            </strong>
                          </span>
                          <span>
                            Rent now and pay later is available for this
                            property
                          </span>
                        </div>
                      }
                    />
                  )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleTermsandPolicyCheck}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <div className="terms-of-use">
                      <span>By submitting this form I agree to the</span>
                      <span>
                        <Link
                          className="modal-term-text-link"
                          onClick={() => setIsOpen1(true)}
                        >
                          Terms of Use
                        </Link>{" "}
                      </span>
                    </div>
                  }
                />

                <CustomButton
                  label={isSubmitting ? "Please wait" : "Send Enquiry"}
                  disabled={!checked || loading}
                  onClick={handleSubmit}
                  className="request-submit-button"
                  labelClassName="request-submit-wrapper"
                />
              </FormControl>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ContactSellerModal;
