import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  Checkbox,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IoMdPerson } from "react-icons/io";
import { FaStickyNote } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import ClearIcon from "@mui/icons-material/Clear";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { useAuth } from "../../context/auth";
import { contactUsSchema } from "../contactUs/validations";
import CustomButton from "../../components/customButton/CustomButton";

import Modall from "../../components/modal/Modal";
import RequestSubmissionSuccessModal from "../../components/requestSubmissionSuccessModal/RequestSubmissionSuccessModal";

import config from "../../config";

import "./ReferralPage.css";
import "../newContactUs/ContactUs.css";

const ReferralPage = () => {
  // state
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { auth } = useAuth();

  const [isRequestSuccessModalOpen, setIsRequestSuccessModalOpen] =
    useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const isSmScreen = useMediaQuery("(max-width:768px)");

  // Load saved form data from localStorage
  useEffect(() => {
    const savedValues = JSON.parse(localStorage.getItem("referralForm"));
    if (savedValues) {
      Object.keys(savedValues).forEach((key) => {
        setFieldValue(key, savedValues[key]);
      });
    }
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (values, actions) => {
    const {
      contactName,
      email,
      phone,
      address,
      whatsapp,
      hearAboutUs,
      realtor,
      propertyType,
    } = values;

    try {
      setLoading(true);
      const response = await axios.post(`${config.AUTH_API}/api/Referral`, {
        name: contactName,
        email: email,
        phone: phone,
        address: address,
        whatsappNo: whatsapp,
        referralSource: hearAboutUs,
        areYouRealtor: realtor === "yes" ? true : false,
        InterestedProperties: propertyType,
      });

      if (!response.data === "Succeeded") {
        toast.error(data.message);
        setLoading(false);
      } else {
        setIsRequestSuccessModalOpen(true);
        localStorage.removeItem("referralForm"); // Clear the form from localStorage on success
        setTimeout(() => {
          navigate("/");
          setLoading(false);
        }, 8000);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong. Try again.");
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    isSubmitting,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      contactName: auth?.user?.firstName || "",
      email: auth?.user?.email || "",
      phone: auth?.user?.phone || "",
      message: "",
      address: "",
      whatsapp: "",
      hearAboutUs: "",
      realtor: "",
      propertyType: "",
      agreeToTerms: false,
    },
    validationSchema: contactUsSchema,
    onSubmit,
  });

  // Save form data to localStorage on change
  useEffect(() => {
    localStorage.setItem("referralForm", JSON.stringify(values));
  }, [values]);

  const handleClickClear = (fieldName) => {
    const inputField = document.querySelector(`input[name="${fieldName}"]`);
    if (inputField) {
      inputField.value = "";
    }
  };

  return (
    <>
      <div className="">
        <Modall
          handleClose={() => setIsOpen(setIsRequestSuccessModalOpen)}
          isOpen={isRequestSuccessModalOpen}
          modalContentClassName={
            isSmScreen
              ? "small-request-success-modal-content"
              : "request-success-modal-content"
          }
          closeBtnClassName="request-success-close-btn"
        >
          <RequestSubmissionSuccessModal
            setIsRequestSuccessModalOpen={setIsRequestSuccessModalOpen}
            isRequestSuccessModalOpen={isRequestSuccessModalOpen}
            title={"Referral form submission"}
            modalText={
              "Your form has been submitted, We will reach out to you shortly. Thank you."
            }
          />
        </Modall>
        <div
          className="contactus-top-banner"
          style={{
            backgroundImage: `url(./ContactUsBackground.png)`,
          }}
        >
          <h3 className="referralPage-word">
            Join Our Exclusive Referral Program
          </h3>
          <img
            className="referralPage-img"
            src="./referralBannerV3.png"
            alt="referralImage"
          />
        </div>

        <div className="form-box">
          <div className="col-lg-12">
            {/* <h3 className="referral-jointitle">
              Join Our Exclusive Real Estate Referral Program
            </h3> */}
            <div className="contact-us-container col-lg-12">
              <div className="content-header col-lg-7">
                <div className="col-lg-12 content-word">
                  <h5 className="referral-maintitle">
                    Unlock a New Income Stream with Just One Referral!
                  </h5>
                  <p className="referral-subtitle">
                    Ready to turn your connections into cash? Welcome to our
                    Referral Partner Program!
                  </p>
                  <p className="referral-subtitle">
                    By simply referring potential buyers or investors to us, you
                    can earn competitive commissions for every successful
                    property sale.
                  </p>
                  <p className="referral-subtitle">
                    It's easy—no experience needed. Just fill out the form below
                    to get started!
                  </p>
                </div>
                <form className="contact-us-contact-modal">
                  <FormControl
                    sx={{ width: "100%" }}
                    variant="outlined"
                    className="form-input"
                  >
                    <span className="modal-input-label">
                      <span className="required-field">*</span>Full name
                    </span>
                    <OutlinedInput
                      size="small"
                      placeholder="Your name"
                      name="contactName"
                      value={values.contactName}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IoMdPerson />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("contactName");
                              handleChange({
                                target: { name: "contactName", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.contactName && touched.contactName && (
                      <p className="mt-0 text-danger">{errors.contactName}</p>
                    )}

                    <span className="modal-input-label">
                      <span className="required-field">*</span>Email
                    </span>
                    <OutlinedInput
                      size="small"
                      placeholder="Input email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IoMail />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("email");
                              handleChange({
                                target: { name: "email", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.email && touched.email && (
                      <div className="mt-0 text-danger">
                        <h6>
                          <p> {errors.email}</p>
                        </h6>
                      </div>
                    )}

                    <span className="modal-input-label">
                      <span className="required-field">*</span>Phone Number
                    </span>
                    <OutlinedInput
                      size="small"
                      placeholder="+234*****"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <FaPhoneAlt />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("phone");
                              handleChange({
                                target: { name: "phone", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.phone && touched.phone && (
                      <div className="mt-0 text-danger">
                        <h6>
                          <p> {errors.phone}</p>
                        </h6>
                      </div>
                    )}

                    {/* Address */}
                    <span className="modal-input-label">
                      <span className="required-field">*</span>Address
                    </span>
                    <OutlinedInput
                      size="small"
                      placeholder="Your address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("address");
                              handleChange({
                                target: { name: "address", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.address && touched.address && (
                      <div className="mt-0 text-danger">
                        <h6>
                          <p> {errors.address}</p>
                        </h6>
                      </div>
                    )}

                    {/* WhatsApp */}
                    <span className="modal-input-label">
                      <span className="required-field">*</span>WhatsApp Number
                    </span>
                    <OutlinedInput
                      size="small"
                      placeholder="+234*****"
                      name="whatsapp"
                      value={values.whatsapp}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 20px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <FaPhoneAlt />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => {
                              handleClickClear("whatsapp");
                              handleChange({
                                target: { name: "whatsapp", value: "" },
                              });
                            }}
                            edge="end"
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.whatsapp && touched.whatsapp && (
                      <div className="mt-0 text-danger">
                        <h6>
                          <p> {errors.whatsapp}</p>
                        </h6>
                      </div>
                    )}

                    {/* New Dropdown Section */}
                    <span className="modal-input-label">
                      <span className="required-field">*</span>How did you hear
                      about this program?
                    </span>
                    <Select
                      labelId="select-how-did-you-hear"
                      id="select-how-did-you-hear"
                      value={values.hearAboutUs || ""}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{
                        "aria-label": "How did you hear about this program?",
                      }}
                      name="hearAboutUs"
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D1D5DB",
                        marginBottom: "20px",
                        padding: "3.5px 15px",
                        background: " #F9FAFB",
                        marginRight: "0px",
                      }}
                    >
                      <MenuItem value="">
                        <em>Select an option</em>
                      </MenuItem>
                      <MenuItem value="Instagram">Instagram</MenuItem>
                      <MenuItem value="Facebook">Facebook</MenuItem>
                      <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                      <MenuItem value="Friend">Friend</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                    {errors.hearAboutUs && touched.hearAboutUs && (
                      <div className="mt-0 text-danger">
                        <h6>
                          <p> {errors.hearAboutUs}</p>
                        </h6>
                      </div>
                    )}

                    {/* Realtor selection (radio buttons) */}
                    <FormControl component="fieldset">
                      <span className="modal-input-label">
                        <span className="required-field">*</span>Are you a
                        Realtor?
                      </span>
                      <RadioGroup
                        aria-label="realtor"
                        name="realtor"
                        value={values.realtor}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              sx={{
                                color: "gray",
                                "&.Mui-checked": {
                                  color: "#FF9800",
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              sx={{
                                color: "gray",
                                "&.Mui-checked": {
                                  color: "#FF9800",
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                      {errors.realtor && touched.realtor && (
                        <div className="mt-0 text-danger">
                          <p>{errors.realtor}</p>
                        </div>
                      )}
                    </FormControl>

                    {/* Property type selection (checkboxes) */}
                    <FormControl component="fieldset">
                      <span className="modal-input-label">
                        <span className="required-field">*</span>What kind of
                        properties are you interested in selling?
                      </span>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.propertyType.includes("Land")}
                            onChange={handleChange}
                            name="propertyType"
                            value="Land"
                            sx={{
                              color: "gray",
                              "&.Mui-checked": {
                                color: "#FF9800",
                              },
                            }}
                          />
                        }
                        label="Land"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.propertyType.includes("House")}
                            onChange={handleChange}
                            name="propertyType"
                            value="House"
                            sx={{
                              color: "gray",
                              "&.Mui-checked": {
                                color: "#FF9800",
                              },
                            }}
                          />
                        }
                        label="House"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.propertyType.includes(
                              "Off-plan Projects"
                            )}
                            onChange={handleChange}
                            name="propertyType"
                            value="Off-plan Projects"
                            sx={{
                              color: "gray",
                              "&.Mui-checked": {
                                color: "#FF9800",
                              },
                            }}
                          />
                        }
                        label="Off-plan Projects"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.propertyType.includes(
                              "All of the above"
                            )}
                            onChange={handleChange}
                            name="propertyType"
                            value="All of the above"
                            sx={{
                              color: "gray",
                              "&.Mui-checked": {
                                color: "#FF9800",
                              },
                            }}
                          />
                        }
                        label="All of the above"
                      />
                      {errors.propertyType && touched.propertyType && (
                        <div className="mt-0 text-danger">
                          <p>{errors.propertyType}</p>
                        </div>
                      )}
                    </FormControl>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.agreeToTerms}
                          onChange={handleChange}
                          name="agreeToTerms"
                          sx={{
                            color: "gray",
                            "&.Mui-checked": {
                              color: "#FF9800",
                            },
                          }}
                        />
                      }
                      label={
                        <span className="agreeToTerms">
                          By submitting this form, you agree to the{" "}
                          <Link
                            className="submit-botton"
                            to={"/referer-terms-and-conditions"}
                            style={{
                              color: "#EE7B0D",
                            }}
                          >
                            terms and conditions
                          </Link>{" "}
                          of our referral program.
                        </span>
                      }
                      sx={{
                        alignItems: "flex-start",
                        marginTop: "15px",
                        marginLeft: "7px",
                      }}
                    />
                    {errors.agreeToTerms && touched.agreeToTerms && (
                      <div className="mt-0 text-danger">
                        <p>{errors.agreeToTerms}</p>
                      </div>
                    )}

                    <Link
                      className="submit-botton"
                      to={"/nimble-rent-information"}
                      aria-label="nimble rent information"
                    >
                      <span className="submit-button">
                        <CustomButton
                          label={isSubmitting ? "Please wait" : "Submit"}
                          disabled={isSubmitting || !values.agreeToTerms}
                          onClick={handleSubmit}
                          style={{
                            height: "40px",
                            padding: "8px 70px 8px 70px",
                            opacity: values.agreeToTerms ? 1 : 0.5, // Optional: visual feedback
                          }}
                        />
                      </span>
                    </Link>
                  </FormControl>
                </form>
              </div>
              <div className="contact-us-circles col-lg-7">
                <div className="contact-us-circle-section">
                  <div
                    id="referral-small-yellow"
                    class="contact-us-circle"
                  ></div>
                  <div id="referral-blue" class="contact-us-circle"></div>
                  <div id="referral-black" class="contact-us-circle"></div>
                  <div
                    id="contact-us-big-yellow"
                    class="contact-us-circle"
                  ></div>
                  <div id="contact-us-black" class="contact-us-circle"></div>
                  <div id="contact-us-blue" class="contact-us-circle"></div>
                  <div
                    id="contact-us-small-yellow"
                    class="contact-us-circle"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralPage;
