import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./ContactAgents.css";
import CustomButton from "../../components/customButton/CustomButton";
import FreepikCredit from "../../components/freepikCredit/FreepikCredit";

const ContactAgents = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container my-5 p-5 content-container">
      <div className="row">
        <div className="col-lg-6 word">
          <div className="p-4 pt-5">
            <div className="title">
              <h4 className="bold-text">
                Tap into the Potential of Your Property: Sell or Lease with
                Confidence
              </h4>
            </div>
            <div>
              <h5 className="second-title">
                Are you a property owner seeking a seamless and trustworthy
                experience?
              </h5>
            </div>
            <div className="contact-word">
              <div>
                We understand the complexities of navigating the real estate
                market, whether you're looking to sell or lease your property.
              </div>
              <div>
                Our team of accredited agents acts as your trusted advisor,
                handling every detail of the process with the utmost
                professionalism.
              </div>
              <div>
                Relax and focus on your goals while we handle the marketing,
                negotiations, and closing.
              </div>
              <div>
                Ready to explore your options?{" "}
                {/* <Link className="here-link" to="/contact-us">
                  here
                </Link> */}
              </div>
            </div>
            <div className="d-flex justify-content-center contact-agents-btn">
              <Link className="" to="/contact-us">
                <CustomButton
                  label="Contact Us"
                  className="contact-us-button"
                  style={{ textAlign: "unset" }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="pt-5 image">
            <img
              src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/saleImage.svg"
              alt="image"
              className="img-fluid image-contact-agents"
            />
            {/* <FreepikCredit /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactAgents;
