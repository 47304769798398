import * as yup from "yup";

export const contactUsSchema = yup.object().shape({
  contactName: yup.string().required("Name is required"),
  phone: yup.string().required("Contact Phone is required"),
  // message: yup.string().required("Drop a message for us"),
  email: yup
    .string()
    .required("Enter your email")
    .email("Invalid email address"),
  address: yup.string().required("Address is required"),
  whatsapp: yup.string().required("WhatsApp number is required"),
  hearAboutUs: yup.string().required("Please select how you heard about us"),
  realtor: yup.string().required("Please specify if you are a realtor"),
  propertyType: yup
    .array()
    .min(1, "Please select at least one property type")
    .required("Please select at least one property type"),
  agreeToTerms: yup
    .boolean()
    .oneOf([true], "You must agree to the terms and conditions")
    .required("You must agree to the terms and conditions"),
});



