import React from "react";

// import ProfileForm from "../../components/forms/profileForm/ProfileForm";
import ProfileUpdateForm from "../../components/forms/profileUpdateForm/ProfileUpdateForm";

export default function UpdateProfile() {
  return (
    <>
      {/* <ProfileForm /> */}
      <ProfileUpdateForm />
    </>
  );
}
