import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";

import { ShimmerPostList } from "react-shimmer-effects";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import AdCard from "../../../components/cards/newAdCard/AdCard.js";
import { useAuth } from "../../../context/auth.js";
import RowPerPage from "../../../components/rowPerPage/RowPerPage.js";
import NimbleFinanceOptionRowPerPage from "../../../components/rowPerPage/NimbleFinanceOptionRowPerPage.js";
// import SearchForm from "../../../components/forms/SearchForm.js";
import NimbleFinanceSearchForm from "../../../components/newSearchForm/NimbleFinanceSearchForm.js";

import { useSearch } from "../../../context/search.js";

import "./NimbleRentAdPage.css";

const NimbleRentAdPage = () => {
  let count = 0;
  count++;

  // context
  const [auth] = useAuth();
  const location = useLocation();
  const [search, setSearch] = useSearch();

  const pathnames = location.pathname.split("/").filter((x) => x);

  // state
  const [ads, setAds] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [loading, setLoading] = useState(false);

  const [nimbleFinanceSearch, setNimbleFinanceSearch] = useState({
    address: "",
    action: "",
    type: "",
    total: 0,
    pageNo: 1,
    perPage: 8,
    loading: true,
    results: [],
    nimbleMortgageStatus: false,
    nimbleRentStatus: false,
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [searchClicked, setSearchClicked] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (auth.user === null) {
      auth.token = "";
    }
    fetchAds();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAds();
  }, [page, perPage]);

  useEffect(() => {
    if (searchClicked) {
      window.scrollTo(0, 0);
      handleNimbleFinanceSearch();
    }
  }, [nimbleFinanceSearch.pageNo, nimbleFinanceSearch.perPage]);

  useEffect(() => {
    setNimbleFinanceSearch((prev) => ({
      ...prev,
      address: search.address,
    }));
  }, [search]);

  useEffect(() => {
    setPage(1);
    window.scrollTo(0, 0);
  }, [perPage]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchAds = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/nimble-finance/${page}/${perPage}`);
      setAds(data?.ads);

      setTotal(data.total);
      setLoading(false);
    } catch (err) {
      //   console.log(err);
      setLoading(false);
    }
  };

  const handleNimbleFinanceSearch = async () => {
    setLoading(true);

    try {
      const { results, page, price, pageNo, perPage, ...rest } =
        nimbleFinanceSearch;

      const query = queryString.stringify(rest);

      const { data } = await axios.get(
        `/nimble-finance-ad-search/${pageNo}/${perPage}?${query}`
      );

      setSearchClicked(true);
      setNimbleFinanceSearch((prev) => ({
        ...prev,
        results: data.ads,
        total: data.total,
        page: window.location.pathname,
        loading: false,
      }));
    } catch (err) {
      setNimbleFinanceSearch({ ...nimbleFinanceSearch, loading: false });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleNimbleSeachPageChange = (event, value) => {
    setNimbleFinanceSearch((prev) => ({
      ...prev,
      pageNo: value,
    }));
  };

  return (
    <>
      <NimbleFinanceSearchForm
        navMenuProperty={true}
        searchClicked={searchClicked}
        setSearchClicked={setSearchClicked}
        nimbleFinanceSearch={nimbleFinanceSearch}
        setNimbleFinanceSearch={setNimbleFinanceSearch}
        handleNimbleFinanceSearch={handleNimbleFinanceSearch}
      />

      <div
        className={
          windowWidth > 1500 ? "container page-wrapper" : "page-wrapper"
        }
      >
        {!searchClicked && (
          <div className="d-flex row nimble-rent-container">
            {loading ? (
              <div style={{ padding: "40px 0" }}>
                <ShimmerPostList
                  postStyle="STYLE_FOUR"
                  col={4}
                  row={2}
                  gap={30}
                />
              </div>
            ) : ads?.length > 0 ? (
              <>
                <h3 className="nimble-rent-title">
                  Properties Available for Financing
                </h3>
                {ads?.map((ad) => (
                  <AdCard ad={ad} key={ad._id} page="nimblerent" />
                ))}

                <div className="row small-screen-page-navigation">
                  <div className="col text-center mt-4 mb-4">
                    <Stack spacing={2}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pagination
                          color="primary"
                          shape="rounded"
                          showFirstButton
                          showLastButton
                          variant="outlined"
                          count={Math.ceil(total / perPage)}
                          page={page}
                          onChange={handleChange}
                        />
                        <RowPerPage
                          total={total}
                          rowPerPage={perPage}
                          setRowPerPage={setPerPage}
                        />
                      </div>
                    </Stack>
                  </div>
                </div>
              </>
            ) : (
              <div className="nimble-rent-no-property">
                Currently we do not have properties that are available for
                Nimble Financing. Kindly check other properties we have or{" "}
                <Link className="nimble-finance-contact-us" to="/contact-us">
                  contact us
                </Link>{" "}
                for more information.
              </div>
            )}
          </div>
        )}

        {searchClicked && (
          <div className="row d-flex justify-content-center">
            {nimbleFinanceSearch.results?.length > 0 ? (
              <>
                <div className="col-md-12 text-center p-3">
                  <button
                    className="btn  disabled search-result-header"
                    style={{
                      background: "#ee7b0d",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                  >
                    <b>
                      Displaying{" "}
                      {nimbleFinanceSearch.perPage *
                        (nimbleFinanceSearch.pageNo - 1) +
                        1 || 1}{" "}
                      to{" "}
                      {Math.min(
                        nimbleFinanceSearch.perPage *
                          nimbleFinanceSearch.pageNo -
                          (nimbleFinanceSearch.perPage -
                            nimbleFinanceSearch.results?.length) +
                          0,
                        nimbleFinanceSearch.total
                      )}{" "}
                      out of {nimbleFinanceSearch.total} results found.
                    </b>
                  </button>
                </div>

                <div className="row d-flex justify-content-center">
                  {nimbleFinanceSearch.loading ? (
                    <div style={{ padding: "40px 0" }}>
                      <ShimmerPostList
                        postStyle="STYLE_FOUR"
                        col={3}
                        row={2}
                        gap={30}
                      />
                    </div>
                  ) : (
                    nimbleFinanceSearch?.results?.map((ad) => (
                      <AdCard ad={ad} key={ad._id} />
                    ))
                  )}
                  {nimbleFinanceSearch?.results?.length > 0 ? (
                    <div className="row mb-5">
                      <div className="col text-center mt-4 mb-4">
                        <Stack spacing={2}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Pagination
                              color="primary"
                              shape="rounded"
                              showFirstButton
                              showLastButton
                              variant="outlined"
                              count={Math.ceil(
                                nimbleFinanceSearch?.total /
                                  nimbleFinanceSearch.perPage
                              )}
                              page={nimbleFinanceSearch.pageNo}
                              onChange={handleNimbleSeachPageChange}
                            />

                            <NimbleFinanceOptionRowPerPage
                              nimbleFinanceSearch={nimbleFinanceSearch}
                              setNimbleFinanceSearch={setNimbleFinanceSearch}
                            />
                          </div>
                        </Stack>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div className="col-md-12 text-center p-5">
                <b>No properties found </b>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default NimbleRentAdPage;
