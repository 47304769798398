import React, { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useMediaQuery from "@mui/material/useMediaQuery";

const NimbleFinanceOptionRowPerPage = ({
  nimbleFinanceSearch,
  setNimbleFinanceSearch,
}) => {
  const isMdScreen = useMediaQuery("(min-width:768px)");

  function generateMultiples(number, multiple, count) {
    const result = [];
    for (let i = 0; i < count; i++) {
      result.push(number + i * multiple);
    }
    return result;
  }

  const number = 8;
  const multiple = 8;
  const count = Math.ceil(
    nimbleFinanceSearch.total / nimbleFinanceSearch.perPage
  ); // Change this to the desired number of elements in the array

  const RowPerPageArray = generateMultiples(number, multiple, count);

  useEffect(() => {
    /* It resets the page number to default
     when rowperpage changes */

    setNimbleFinanceSearch((prev) => ({
      ...prev,
      pageNo: 1,
    }));
  }, [nimbleFinanceSearch.perPage]);

  const handleChange = (event) => {
    setNimbleFinanceSearch((prev) => ({
      ...prev,
      perPage: event.target.value,
    }));
  };

  return (
    <>
      {isMdScreen && (
        <div className="mx-4 d-flex flex-direction-row">
          <span>Properties per page</span>
          <FormControl
            sx={{ p: 0, marginLeft: 1, marginRight: 2, minWidth: 80 }}
          >
            <Select
              value={nimbleFinanceSearch.perPage}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              SelectDisplayProps={{
                style: { padding: 6 },
              }}
            >
              {RowPerPageArray.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  );
};

export default NimbleFinanceOptionRowPerPage;
