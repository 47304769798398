import React, { useState, useEffect } from "react";
import axios from "axios";

import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import AdCard from "../cards/newAdCard/AdCard";

import "./LandingPageFeaturedProperties.css";

const LandingPageFeaturedProperties = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [total, setTotal] = useState(0);

  const [featuredProperty, setFeaturedProperty] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isMdScreen = useMediaQuery("(min-width: 768px) and (max-width: 900px)");

  const isLgScreen = useMediaQuery(
    "(min-width: 901px) and (max-width: 1200px)"
  );

  const isXLScreen = useMediaQuery("(min-width:1201px)");

  useEffect(() => {
    fetchAds();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollLeft = () => {
    document.getElementById("card-container").scrollLeft -= 350;
  };

  const scrollRight = () => {
    document.getElementById("card-container").scrollLeft += 350;
  };

  const fetchAds = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/featured-ads/${page}/${perPage}`);
      setFeaturedProperty(data?.ads);
      setTotal(data?.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div
      className={windowWidth > 1700 ? "container page-wrapper" : "page-wrapper"}
    >
      <div className="section-title">
        <div className="ad-title-left">Featured Properties</div>

        {(isMdScreen && total > 2) ||
        (isLgScreen && total > 3) ||
        (isXLScreen && total > 4) ? (
          <div className="navigation-button">
            <IconButton onClick={scrollLeft}>
              <KeyboardArrowLeftIcon style={{ fontSize: 32 }} />
            </IconButton>
            <IconButton onClick={scrollRight}>
              <KeyboardArrowRightIcon style={{ fontSize: 32 }} />
            </IconButton>
          </div>
        ) : null}
      </div>
      <div id="card-container" style={{ display: "flex", overflowX: "auto" }}>
        {featuredProperty?.map((ad) => (
          <AdCard ad={ad} key={ad._id} page="home" section={"featuredAd"} />
        ))}
      </div>
    </div>
  );
};

export default LandingPageFeaturedProperties;
