import React from "react";
import { Link } from "react-router-dom";

import "./NimbleRentBottomBanner.css";
import CustomButton from "../customButton/CustomButton";

const NimbleRentBottomBannerMobile = () => {
  return (
    <div className="nimble-rent-bottom-banner-container">
      <span className="nimble-rent-bottom-banner-left-column">
        <div className="banner-texts">
          <div className="banner-text">Buy or Rent now</div>
          <div className="banner-text ">
          <span className="banner-text">and</span>{" "}
            <span className="banner-text2">Pay later</span>{" "}
            {/* <span className="banner-text banner-text3">with</span> */}
          </div>
          <div className="banner-text banner-text4">with Finance</div>
        </div>
        <div>
          <Link
            to={"/nimble-rent-information"}
            aria-label="nimble rent information"
          >
            <CustomButton
              label="Check Eligibility"
              className="check-eligibility-button"
            />
          </Link>
        </div>
      </span>
      <span className="nimble-rent-bottom-banner-right-column">
        <img
          src="./nimblerentbottonbanner.png"
          alt=""
          width={230}
          height={280}
        />
      </span>
    </div>
  );
};

export default NimbleRentBottomBannerMobile;
