import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import "./FooterLocation.css";
import { Link } from "react-router-dom";

const FooterLocation = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [locations, setLocations] = useState([]);

  const [uniqueAdAreasArray, setUniqueAdAreasArray] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState("lagos");
  const uniqueAdAreas = new Set();

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    setFilteredLocations(
      locations
        .filter((location) => location.adArea === selectedLocation)
        .sort((a, b) => a.adCity.localeCompare(b.adCity))
    );
  }, [selectedLocation]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleHeaderClick = (area, locations) => {
    if (area !== selectedLocation) {
      setSelectedLocation(area);
      setIsButtonClicked(true);
    }
    setFilteredLocations(
      locations
        .filter((location) => location.adArea === selectedLocation)
        .sort((a, b) => a.adCity.localeCompare(b.adCity))
    );
  };

  const locationStyle = {
    borderBottom: isButtonClicked ? "2px solid #ee7b0d" : "",
  };

  const fetchLocations = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/adlocation`);

      setLocations(data.allLocations);

      data?.allLocations.forEach((location) => {
        uniqueAdAreas.add(location.adArea);
      });
      setUniqueAdAreasArray(Array.from(uniqueAdAreas));

      setFilteredLocations(
        data?.allLocations
          .filter((location) => location?.adArea === selectedLocation)
          .sort((a, b) => a.adCity.localeCompare(b.adCity))
      );

      setLoading(false);
    } catch (err) {
      // console.log(err);
      setLoading(false);
    }
  };

  return (
    <div
      className={windowWidth > 1700 ? "container page-wrapper" : "page-wrapper"}
    >
      <div className="col-lg-10 col-md-10 location-inner-padding">
        <h3 className="col-lg-5 col-md-7 location-header">
          Check out properties we have in different locations
        </h3>
        <div className="locations">
          {uniqueAdAreasArray.map((area, index) => (
            <div
              key={index}
              className="location"
              onClick={() => handleHeaderClick(area, locations)}
              style={selectedLocation === area ? locationStyle : null}
            >
              <div className="header">
                <h6 className="header-list">{area}</h6>
              </div>
            </div>
          ))}
        </div>
        {/* {locations && ( */}
        <div className="city-name-container">
          {filteredLocations.map((area) => (
            <Link
              to={`/ads/city/${area.adArea}/${area.adCity}/${area.adLocationCoordinates.coordinates[0]}/${area.adLocationCoordinates.coordinates[1]}`}
              className="city-location"
              key={area.adCity}
            >
              {area.adCity}
            </Link>
          ))}
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default FooterLocation;
