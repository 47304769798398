import React, { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ShimmerPostList } from "react-shimmer-effects";
import useMediaQuery from "@mui/material/useMediaQuery";

import AdCard from "../../cards/newAdCard/AdCard";

import "./LandingPageAds.css";

const LandingPageAds = ({ title, updateTotal }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);

  const [houseAds, setHouseAds] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [total, setTotal] = useState(0);

  const isSmScreen = useMediaQuery("(min-width: 300px) and (max-width: 800px)");
  const isMdScreen = useMediaQuery("(min-width: 768px) and (max-width: 900px)");

  const isLgScreen = useMediaQuery(
    "(min-width: 901px) and (max-width: 1200px)"
  );
  const isXLScreen = useMediaQuery("(min-width:1201px)");

  useEffect(() => {
    fetchAds();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchAds = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/ads/home/${title}/${page}/${perPage}`);

      const filteredAds = data?.ads?.filter(
        (d) => d.type === title && d.publishedStatus === "Published"
      );
      setHouseAds(filteredAds);

      setTotal(filteredAds.length);
      updateTotal(title, filteredAds.length);
      setLoading(false);
    } catch (err) {
      // console.log(err);
      setLoading(false);
    }
  }, [title, page, perPage, updateTotal]);

  return (
    <div
      className={windowWidth > 1700 ? "container page-wrapper" : "page-wrapper"}
    >
      <div className="d-flex row nimble-rent-container">
        {loading ? (
          <div style={{ padding: "40px 0" }}>
            <ShimmerPostList postStyle="STYLE_FOUR" col={4} row={2} gap={30} />
          </div>
        ) : (
          <>
            <div className="section-title">
              <div className="ad-title-left">{title} Properties</div>
              {!isSmScreen && (
                <div className="navigation-button">
                  <Link to={`/ads/ad-type/${title}`}>
                    <span className="link-text">View more</span>
                  </Link>
                </div>
              )}
            </div>
            {isSmScreen &&
              houseAds
                ?.slice(0, 2)
                .map((ad) => <AdCard ad={ad} key={ad._id} page="home" />)}
            {isMdScreen &&
              !isSmScreen &&
              houseAds
                ?.slice(0, 4)
                .map((ad) => <AdCard ad={ad} key={ad._id} page="home" />)}
            {isLgScreen &&
              !isMdScreen &&
              houseAds
                ?.slice(0, 6)
                .map((ad) => <AdCard ad={ad} key={ad._id} page="home" />)}
            {isXLScreen &&
              !isLgScreen &&
              houseAds
                ?.slice(0, 8)
                .map((ad) => <AdCard ad={ad} key={ad._id} page="home" />)}

            {isSmScreen && (
              <div className="small-screen-navigation-button">
                <Link to={`/ads/ad-type/${title}`}>
                  <span className="small-screen-link-text">
                    View more {title} Properties{" "}
                  </span>
                  <span className="small-screen-link-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 40 40"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      // class="w-1 h-1"
                      className="custom-downarrow-svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LandingPageAds;
