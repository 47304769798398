import React, { useEffect, useMemo, useState, useRef } from "react";
import { setKey, geocode, RequestType } from "react-geocode";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "@mui/material/utils";

import config from "../../config.js";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function NewLocationSearchInput({
  value,
  setValue,
  inputValue,
  setInputValue,
  options,
  setOptions,
  userCurrentLocation,
  setUserCurrentLocation,
  handleSearch,
  setInputFocused,
  isInputFocused,
  setAd,
  ad,
}) {
  setKey(config.GOOGLE_MAPS_KEY);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue]);

  const defaultOption = ["Current Location"];

  const searchOptions = [...options, ...defaultOption];

  //current Location logic start here
  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(success, error);
  //   } else {
  //     console.log("Geolocation not supported");
  //   }
  // }, [navigator.geolocation]);

  const success = (position) => {
    geocode(
      RequestType.LATLNG,
      `${position.coords.latitude},${position.coords.longitude}`,
      {
        location_type: "ROOFTOP", // Override location type filter for this request.
        enable_address_descriptor: true, // Include address descriptor in response.
      }
    )
      .then(({ results }) => {
        const address = results[0].formatted_address;
        const neighborhood = results[0].address_components[2].long_name;
        const { city, state, country, sublocality } =
          results[0].address_components.reduce((acc, component) => {
            if (component.types.includes("locality"))
              acc.city = component.long_name;
            else if (component.types.includes("neighborhood"))
              acc.state = component.long_name;
            else if (component.types.includes("administrative_area_level_2"))
              acc.state = component.long_name;
            else if (component.types.includes("country"))
              acc.country = component.long_name;
            return acc;
          }, {});

        // localStorage.setItem("cLocation", neighborhood);
        setUserCurrentLocation(address);

        // setSearch((prev) => ({ ...prev, address }));
      })
      .catch(console.error);
  };

  const error = () => {
    console.log("Unable to retrieve your location");
  };

  const handleClickClear = (fieldName) => {
    setAd((prevValues) => {
      const updatedValues = { ...prevValues, [fieldName]: "" };
      return updatedValues;
    });
  };
  // console.log(ad.landmark);
  return (
    <div className="">
      <Autocomplete
        forcePopupIcon={false}
        id="google-map-demo"
        autoHighlight
        sx={{
          width: "auto",
          border: "1px solid #ee7b0d",
          borderRadius: "8px",
          alignItems: "center",
          display: "flex",
          maxWidth: 1200,
          border: "1px",
          "& .MuiTextField-root": {
            backgroundColor: "white",
            borderRadius: "50px",
            color: "white",
            fontFamily: "Figtree",
            fontSize: "16px",
            alignItems: "start",
          },
          "& .MuiAutocomplete-inputRoot": {
            padding: "10px", // Adjust the input padding as needed
            backgroundColor: "#F9FAFB",
            border: "1px solid #D1D5DB",
            borderRadius: "8px",
            marginRight: "20px",
            height: "50px",
          },
          "& .MuiAutocomplete-listbox": {
            marginTop: "5px", // Adjust the list box margin as needed
            backgroundColor: "white",
          },
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options} //searchOptions
        autoComplete
        filterSelectedOptions
        value={ad?.landmark || value} //value
        noOptionsText="Enter ad landmark location"
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setInputFocused(true);

          if (newValue !== "Current Location") {
            console.log("new value", newValue);

            setAd((prev) => ({ ...prev, landmark: newValue?.description }));
          }

          setValue(
            newValue === "Current Location" ? userCurrentLocation : newValue
          );
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,

              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ marginRight: "-100px" }}
                    aria-label="clear text"
                    onClick={() => {
                      handleClickClear("landmark");
                    }}
                    edge="end"
                  >
                    <ClearIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="ad landmark address/location"
            fullWidth
            variant="filled"
          />
        )}
      />
    </div>
  );
}
