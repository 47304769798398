import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useAuth } from "../../context/auth.js";
// import SearchForm from "../../components/forms/SearchForm.js";
import SearchForm from "../../components/newSearchForm/SearchForm.js";

// import CustomBreadcrumbs from "../../../components/breadcrumbs/Breadcrumbs.js";
import LandingPageFeaturedProperties from "../../components/featuredProperties/LandingPageFeaturedProperties.js";
import LandingPageFeaturedPropertiesMobile from "../../components/featuredProperties/LandingPageFeaturedPropertiesMobile.js";

import LandingPageAds from "../../components/landingPageMainComponents/adsComponent/LandingPageAds.js";

import fetchAds from "./fetchAds";
import "./HomePage.css";
import NimbleRentBottomBanner from "../../components/nimbleRentBottomBanner/NimbleRentBottomBanner.js";
import NimbleRentBottomBannerMobile from "./../../components/nimbleRentBottomBanner/NimbleRentBottomBannerMobile";

import NimbleRentReferralBanner from "./../../components/nimbleRentReferralBanner/NimbleRentReferralBanner.js"
import NimbleRentReferralBannerMobile from "./../../components/nimbleRentReferralBanner/NimbleRentReferralBannerMobile.js"

const HomePage = () => {
  let count = 0;
  count++;

  // context
  const [auth] = useAuth();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  // state

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [totals, setTotals] = useState({});

  const isSmScreen = useMediaQuery("(max-width:767px)");

  const [adsTotals, setAdsTotals] = useState({
    House: 0,
    Land: 0,
    Commercial: 0,
    Industrial: 0,
  });

  const updateTotal = (type, value) => {
    setAdsTotals((prevTotals) => ({
      ...prevTotals,
      [type]: value,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (auth.user === null) {
      auth.token = "";
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const fetchedTotals = await fetchAds(page, perPage);
      setTotals(fetchedTotals);
      setLoading(false);
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <SearchForm navMenuProperty={true} />
      {isSmScreen ? (
        <LandingPageFeaturedPropertiesMobile />
      ) : (
        <LandingPageFeaturedProperties />
      )}
      {isSmScreen ? (
        <NimbleRentReferralBannerMobile />
      ) : (
        <NimbleRentReferralBanner />
      )}
      {totals.House > 0 && (
        <LandingPageAds title="House" updateTotal={updateTotal} />
      )}
      {isSmScreen ? (
        <NimbleRentBottomBannerMobile />
      ) : (
        <NimbleRentBottomBanner />
        // ""
      )}
      {totals.Land > 0 && (
        <LandingPageAds title="Land" updateTotal={updateTotal} />
      )}
      {isSmScreen ? (
        <NimbleRentBottomBannerMobile />
      ) : (
        // ""
        <NimbleRentBottomBanner />
      )}
      {totals.Commercial > 0 && (
        <LandingPageAds title="Commercial" updateTotal={updateTotal} />
      )}
      {totals.Industrial > 0 && (
        <LandingPageAds title="Industrial" updateTotal={updateTotal} />
      )}

      {/* <pre>{JSON.stringify(houseTotal, null, 4)} </pre> */}
    </>
  );
};

export default HomePage;
