const config = {
  AdminEmail: "support@nimblecasa.com",

  APIS_PROTECT: "Fm2dT0GzyUa_Q_6KxMTzNwELPcEVcHOUqyjd0yn0LJqA",

  AUTH_API_KEY: "d6cf4f00-773e-40b6-aed1-1960691e954e",

  CLIENT_BASE_URL: "https://www.nimblecasa.com",
  API: "https://nimblecasa-prod-node-server.azurewebsites.net/api",
  AUTH_API: "https://nimblecasa-prod-auth-backend.azurewebsites.net",
  PAYMENT_API: "https://nimblecasa-prod-payment-backend.azurewebsites.net",

  GOOGLE_PLACES_KEY: "AIzaSyD3IfqOASixLFAOqv7dDtwllrpHsa11iTs",
  GOOGLE_MAPS_KEY: "AIzaSyD3IfqOASixLFAOqv7dDtwllrpHsa11iTs",
  STRIPE_PUBLISHABLE_KEY: "pk_test_51N5rmAIPzi27NxkuwY7kRWxrd45VLdOlaFpegNGUksbkeGd4CTnOlDcdHwU7u1yv1Cgln0s36kjMtN9XuiLtXbHK00cbwxDnxX",
  // appId: "#{REACT-appId}#",
  emailId: "E4BCBFAA-3258-4934-AA87-891AE86B6E71",

  // PAYSTACK_KEYS = "development"
  REACT_APP_PS_PUBLIC_TEST_KEY: "pk_test_sk_test_8b48c4990c2ac49a717cdda794f7104ae4ed56a4",
  REACT_APP_PS_PUBLIC_LIVE_KEY: "#{REACT-APP-PS-PUBLIC-LIVE-KEY-DEV}#",

  GOOGLE_ANALYTICS_ID: "G-D009SM6Z62",
};

export default config;
