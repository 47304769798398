import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import "./NimbleRentInfo.css";
import CustomButton from "./../customButton/CustomButton";

// Define the OrangeStepIcon function
function OrangeStepIcon(index) {
  return function OrangeStepIconComponent(props) {
    const { active, completed } = props;

    return (
      <div
        style={{
          backgroundColor: active || completed ? "#EE7B0D" : undefined,
          color: "white",
          width: 30,
          height: 30,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {index + 1}
      </div>
    );
  };
}
const NimbleRentInfo = () => {
  const steps = [
    {
      label: "Search properties on Nimble rent",
      description:
        "Select a desired apartment from properties available on Nimble rent.",
    },
    {
      label: "Payment option",
      description:
        "Ensure you check use Nimble rent to pay while submitting property request.",
    },
    {
      label: "Verification",
      description:
        "We will reach out to you to help confirm the necessary information to approve your loan.",
    },
    {
      label: "Access to loan",
      description:
        "Once verification is complete and you meet all eligibility criteria, your loan is disbursed.",
    },
  ];

  const steps2 = [
    {
      label: "Search properties on NimbleCasa",
      description:
        "Select a desired apartment from properties available on the web app.",
    },
    {
      label: "Contact Us",
      description:
        "Send enquiry by clicking on the “Contact Agent” button on the property card.",
    },
    {
      label: "Verification",
      description:
        "We will reach out to you to help confirm the necessary information to approve your loan.",
    },
    {
      label: "Access to loan",
      description:
        "Once verification process is complete and you meet all eligibility requirements, your loan is disbursed.",
    },
  ];

  return (
    <div className="nimble-rent-info-container1">
      <div className="rent-details-container">
        <div className="main-title-container-1">
          <h2 className="main-title">
            Introducing Finance by NimbleCasa: A Hassle-Free Financing Solution:
            {/* Finance{" "} */}
            <span className="title-fire">
              <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/Fire.png" width={31} height={31}></img>&nbsp;
              {/* is here!! */}
            </span>
            &nbsp;
          </h2>
          <p className="title-subtexts">
            Stop dreaming about home ownership and start living in it! <br />
            <br />
            NimbleCasa financing options that make owning or renting a home in
            Lagos a reality. Eligible users can rent a property now to pay later
            (Nimble Rent) or buy a property now to pay later (Nimble Mortgage).
            {/* These are services offered by NimbleCasa that allow eligible users
            to rent now pay later (Nimble Rent) and buy now pay later (Nimble
            Mortgage). */}
          </p>
        </div>
        <div className="main-title-container">
          <h2 className="main-title">
            You can rent now and pay later with{" "}
            <span className="nimble-rent">Nimble Rent</span>
            &nbsp;&nbsp;
            <span className="title-fire">
              <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/Fire.png" width={31} height={31}></img>&nbsp;
              {/* is here!! */}
            </span>
            &nbsp;
          </h2>
          <p className="title-subtexts">
            NimbleRent offers a RNPL(Rent Now Pay Later) service. We allow our
            valued customers to secure their desired property for rent
            immediately and pay back the rental amount in convenient
            installments. We have collaborated with some financial institutions
            that are willing to offer loan services.
          </p>
        </div>
        <div className="cards-container">
          <div className="card-one-container">
            <div>
              <div className="card-one-title-container">
                <h3 className="card-one-title">Eligibility Status</h3>
                <div className="card-one-subtitle-box">
                  <p className="subtitle-box-text">
                    Please note that only Civil Servants are qualified for the
                    offer.
                  </p>
                </div>
              </div>

              <div className="card-one-inner-text-container">
                <p className="subtitle-text">
                  The followings are the eligible criteria when using Nimble
                  rent.
                </p>
                <div className="listed-style-container">
                  <ul className="">
                    <li>
                      Your salary account must be operational for at least 6
                      months.
                    </li>
                    <li>Your account number must be linked to BVN.</li>
                    <li>Correctly updated phone numbers.</li>
                    <li>
                      Must have positive credit record with Credit Bureaus.
                    </li>
                    <li>
                      Minimum Loan Amount: N500,000 (Five Hundred Thousand Naira
                      only).
                    </li>
                    <li>Convenient repayment period up to 12 months.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card-two-container">
            <div className="card-two-inner-container">
              <div className="stepper-title-container">
                <h3 className="stepper-title">How it works</h3>
                <p className="stepper-subtext">
                  Quick and easy steps to use Nimble rent!
                </p>
              </div>

              <div className="stepper-container">
                {/* <Box sx={{ maxWidth: 400 }}> */}
                <Stepper
                  className="label-content"
                  activeStep={steps.length - 1}
                  orientation="vertical"
                >
                  {steps.map((step, index) => (
                    <Step key={step.label} expanded>
                      <StepLabel StepIconComponent={OrangeStepIcon(index)}>
                        <span className="step-label"> {step.label} </span>
                      </StepLabel>
                      <StepContent>
                        <Typography
                          sx={{
                            borderLeft:
                              index < steps.length - 1
                                ? "2px dotted orange"
                                : "none",
                            marginLeft: -2.5,
                            paddingLeft: 4,
                            paddingBottom: 2,
                          }}
                        >
                          {step.description}
                        </Typography>
                      </StepContent>
                      {index < steps.length - 1 && (
                        <Box sx={{ borderLeft: "2px dotted orange", ml: -1 }} />
                      )}
                    </Step>
                  ))}
                </Stepper>
                {/* </Box> */}
              </div>
            </div>
          </div>
          <div>
            <div className="main-title-container">
              <h2 className="main-title">
                You can buy now and pay later with{" "}
                <span className="nimble-rent">Nimble Mortgage</span>
                &nbsp;&nbsp;
                <span className="title-fire">
                  <img src="https://nimblecasauatstorage.blob.core.windows.net/nimblecasa-icons/Fire.png" width={31} height={31}></img>&nbsp;
                  {/* is here!! */}
                </span>
                &nbsp;
              </h2>
              <p className="title-subtexts">
                Nimble Mortgage offers a BNPL (Buy Now Pay Later) service. We
                allow our valued customers to secure their desired property for
                sale immediately and pay back the purchase amount in convenient
                installments. We have collaborated with some financial
                institutions that are willing to offer loan services.
              </p>
            </div>
          </div>

          <div className="card-one-container-2">
            {/* <div> */}
            <div className="card-one-title-container-2">
              <h3 className="card-one-title">Eligibility Criteria</h3>
              <div className="card-one-subtitle-box">
                <p className="subtitle-box-text">
                  Please note that all eligible users are qualified for this
                  offer.
                </p>
              </div>
            </div>
            <div className="card-one-inner-text-container-2">
              {/* <div> */}
              <p className="subtitle-text-2">
                To be eligible for a mortgage with NimbleCasa, applicants must
                meet the following requirements.
              </p>
            </div>
            <div className="listed-style-container-2">
              <ul className="">
                <li>
                  Open an account with a minimum deposit of N200,000.00 with
                  Mortgage institution.
                </li>
                {/* <li>
                  Provide a minimum equity deposit of 30% in your account or
                  paid to the vendor.
                </li> */}
                <li>
                Provide a minimum equity deposit to your account or pay it to the vendor.
                </li>
                <li>
                  The applicant must not be more than 58 years old at the last
                  year of loan repayment.
                </li>
                <li>
                  The debt ratio cannot be more than 35% of net monthly salary.
                </li>
                <li>
                  Maintain a clean credit record from at least 2 credit bureau.
                </li>
                <li>Provide valid identification documents.</li>
                <li>Submit any of the following documents:</li>
                <ul>
                  <li>Paystub of up to 12 months.</li>
                  <li>Employment letter/promotion letter (if applicable).</li>
                  <li>HR letter showing length of employment.</li>
                  <li>Business account showing transactions.</li>
                  <li>Pension funds (if applicable)</li>
                </ul>
              </ul>
            </div>
            <div className="card-one-title-container-2">
              <h3 className="card-one-title-2">
                Diaspora Clients Additional Information
              </h3>
            </div>
            <div className="listed-style-container-2">
              <ul className="">
                <li>Tenor up to 10 years.</li>
                {/* <li>Minimum equity contribution up to 50%.</li> */}
                <li>Minimum equity contribution is required.</li>
                <li>Most recent credit reports.</li>
                <li>Valid ID and documents, including:</li>
                <ul>
                  <li>Green Card.</li>
                  <li>Permanent Residence card.</li>
                  <li>Work permit.</li>
                  <li>Other relevant documents.</li>
                </ul>
              </ul>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
          <div className="card-two-container-2">
            <div className="card-two-inner-container">
              <div className="stepper-title-container">
                <h3 className="stepper-title">How it works</h3>
                <p className="stepper-subtext">
                  Quick and easy steps to use Nimble Mortgage!
                </p>
              </div>

              <div className="stepper-container">
                {/* <Box sx={{ maxWidth: 400 }}> */}
                <Stepper
                  className="label-content"
                  activeStep={steps.length - 1}
                  orientation="vertical"
                >
                  {steps2.map((step, index) => (
                    <Step key={step.label} expanded>
                      <StepLabel StepIconComponent={OrangeStepIcon(index)}>
                        <span className="step-label"> {step.label} </span>
                      </StepLabel>
                      <StepContent>
                        <Typography
                          sx={{
                            borderLeft:
                              index < steps.length - 1
                                ? "2px dotted orange"
                                : "none",
                            marginLeft: -2.5,
                            paddingLeft: 4,
                            paddingBottom: 2,
                          }}
                        >
                          {step.description}
                        </Typography>
                      </StepContent>
                      {index < steps.length - 1 && (
                        <Box sx={{ borderLeft: "2px dotted orange", ml: -1 }} />
                      )}
                    </Step>
                  ))}
                </Stepper>
                {/* </Box> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="  nimble-rent-info-actions-buttons-main-c">
        <div className="nimble-rent-info-actions-buttons-container">
          <div className="nimble-rent-info-actions-buttons">
            <Link
              to={"/nimble-finance"}
              aria-label="nimble finance information"
            >
              <CustomButton
                label="See properties on Nimble Finance"
                className="nimble-rent-ad-button"
                style={{}}
              />
            </Link>
          </div>

          <div className="nimble-rent-info-actions-buttons">
            <Link to={"/"} aria-label="nimble rent information">
              <CustomButton
                className="back-home-button"
                // className="outlined-button"
                label="Take me to home page"
                style={{ padding: "10px 45px" }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NimbleRentInfo;
