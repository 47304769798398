import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import useMediaQuery from "@mui/material/useMediaQuery";

import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AdDelete from "../../../components/adDelete/AdDelete";
import Modall from "../../modal/Modal";
import RequestSubmissionSuccessModal from "../../requestSubmissionSuccessModal/RequestSubmissionSuccessModal";

const options = [
  { label: "Edit", icon: <EditIcon /> },
  { label: "Delete", icon: <DeleteIcon /> },
];

export default function UserAdUpdateButton({ ad, userId }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState("");
  const [isRequestSuccessModalOpen, setIsRequestSuccessModalOpen] =
    useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const isSmScreen = useMediaQuery("(max-width:994px)");
  const isSSmScreen = useMediaQuery("(max-width:768px)");

  let navigate = useNavigate();

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (event, index) => {
    event.preventDefault();
    setSelectedIndex(index);
    setValue(index);
    setOpen((prevOpen) => !prevOpen);

    // Check if the modal should be opened
    if (index === 0) {
      navigate(`/user/ad/${ad._id}`);
    }
    if (index === 1) {
      setIsDeleteOpen(true);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Modall
        handleClose={() => setIsOpen(setIsDeleteOpen)}
        isOpen={isDeleteOpen}
        // styling="modal-content"
        modalContentClassName={
          isSSmScreen
            ? "small-delete-ad-modal-content1"
            : "delete-ad-modal-content1"
        }
        closeBtnClassName={
          isSmScreen
            ? "small-delete-ad-modal-close-btn"
            : "delete-ad-modal-close-btn"
        }
      >
        <AdDelete
          setIsDeleteOpen={setIsDeleteOpen}
          isDeleteOpen={isDeleteOpen}
          ad={ad}
          userId={userId}
          setIsRequestSuccessModalOpen={setIsRequestSuccessModalOpen}
          isRequestSuccessModalOpen={isRequestSuccessModalOpen}
        />
      </Modall>
      <Modall
        handleClose={() => setIsOpen(setIsRequestSuccessModalOpen)}
        isOpen={isRequestSuccessModalOpen}
        modalContentClassName={
          isSmScreen
            ? "small-request-success-modal-content"
            : "request-success-modal-content"
        }
        closeBtnClassName={"request-success-close-btn"}
      >
        <RequestSubmissionSuccessModal
          setIsRequestSuccessModalOpen={setIsRequestSuccessModalOpen}
          isRequestSuccessModalOpen={isRequestSuccessModalOpen}
          title={"Property ad deleted successfully"}
          modalText={
            "You have successfully deleted this property and all the information attached to it."
          }
        />
      </Modall>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        style={{
          width: "169.22px",
          borderRadius: "8px",
        }}
      >
        <Button
          style={{
            backgroundColor: "#EE7B0D",
            color: "white",
            boxShadow: "none",
            // borderRadius: "8px",
            padding: " 6px 10px",
            borderRadius: "8px 0px 0px 8px",

            textTransform: "Capitalize",
          }}
          onClick={handleClick}
        >
          Update property
        </Button>

        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          style={{
            backgroundColor: "#EE7B0D",
            color: "white",
            boxShadow: "none",
            textTransform: "Capitalize",
            borderRadius: "0px 8px 8px 0px",
            padding: "4px 8px",
          }}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 1,
          marginTop: "-8px", // Adjust the margin as needed
          marginRight: "-8px", // Adjust the margin as needed
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top-end" // Adjust placement here
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              marginBottom: "20px",
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper
              sx={{
                border: "1px solid #0000000F",
                boxShadow: "0px 2px 8px 0px #00000026",
                width: "154px",
                height: "auto",
                borderRadius: "border-radius: 6px 0px 0px 0px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      // selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      sx={{ "&:hover": { backgroundColor: "#EE7B0D" } }}
                    >
                      <span style={{ marginRight: "8px" }}>{option.icon}</span>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
