import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ShimmerPostList } from "react-shimmer-effects";
import queryString from "query-string";

// import SearchForm from "../components/forms/SearchForm";
import SearchForm from "../components/newSearchForm/SearchForm";
import { useSearch } from "../context/search";
// import AdCard from "../components//cards/AdCard";
import AdCard from "../components/cards/newAdCard/AdCard";

import RowPerPage from "../components/rowPerPage/RowPerPage";

export default function Search() {
  const [search, setSearch] = useSearch();
  const [perPage, setPerPage] = useState(8);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleSearch();
  }, [perPage]);

  useEffect(() => {
    setSearch((prev) => ({
      ...prev,
      perPage: perPage,
    }));
  }, [perPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search?.results]);

  const handleChange = (event, value) => {
    setSearch((prev) => ({
      ...prev,
      pageNo: value,
      loading: true,
    }));
  };

  const handleSearch = useCallback(async () => {
    setSearch((prev) => ({ ...prev, loading: true }));
    try {
      const { results, page, price, pageNo, ...rest } = search;

      const query = queryString.stringify(rest);

      const { data } = await axios.get(`/search/${pageNo}/${perPage}?${query}`);

      setSearch((prev) => ({
        ...prev,
        results: data.ads,
        total: data.total,
        page: window.location.pathname,
        loading: false,
      }));
    } catch (err) {
      console.log(err);
      setSearch({ ...search, loading: false });
    }
  }, [perPage]);

  return (
    <div>
      <SearchForm />
      <div
        className={
          windowWidth > 1500 ? "container page-wrapper" : "page-wrapper"
        }
      >
        <div className="row d-flex justify-content-center">
          {search.results?.length > 0 ? (
            <>
              <div className="col-md-12 text-center p-3">
                <button
                  className="btn disabled search-result-header"
                  style={{
                    background: "#ee7b0d",
                    color: "#fff",
                    borderRadius: "20px",
                  }}
                >
                  <b>
                    Displaying {perPage * (search.pageNo - 1) + 1 || 1} to{" "}
                    {Math.min(
                      perPage * search.pageNo -
                        (perPage - search.results?.length) +
                        0,
                      search.total
                    )}{" "}
                    out of {search.total} results found.
                  </b>
                </button>
              </div>

              <div className="row d-flex justify-content-center">
                {search.loading ? (
                  <div style={{ padding: "40px 0" }}>
                    <ShimmerPostList
                      postStyle="STYLE_FOUR"
                      col={3}
                      row={2}
                      gap={30}
                    />
                  </div>
                ) : (
                  search?.results?.map((ad) => <AdCard ad={ad} key={ad._id} />)
                )}
                {search?.results?.length > 0 ? (
                  <div className="row mb-5">
                    <div className="col text-center mt-4 mb-4">
                      <Stack spacing={2}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Pagination
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            variant="outlined"
                            count={Math.ceil(search?.total / perPage)}
                            page={search.pageNo}
                            onChange={handleChange}
                          />

                          <RowPerPage
                            total={search.total}
                            rowPerPage={perPage}
                            setRowPerPage={setPerPage}
                            page={search.pageNo}
                          />
                        </div>
                      </Stack>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <div className="col-md-12 text-center p-5">
              <b>No properties found </b>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
