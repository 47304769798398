import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../customButton/CustomButton";
import "./NimbleRentBottomBanner.css";

const NimbleRentBottomBanner = () => {
  return (
    <div className="page-wrapper">
      <div className="page ">
        <div
          className="col-12 bottom-banner-container"
          style={{ backgroundColor: "#0172CB" }}
        >
          <div className="col-4 bottom-banner-box-word">
            <h4 className="banner-word">
            Buy or Rent now and  <span className="pay-later">Pay later</span> with
              Finance
            </h4>
          </div>
          <div className="col-6 bottom-banner-blue-rectangle">
            <div className="bottom-banner-circle bottom-banner-grey"></div>
            <div className="bottom-banner-circle bottom-banner-light-blue"></div>
            <div className="bottom-banner-circle bottom-banner-dark-blue"></div>
          </div>
          <img
            src="./Bannerimage.png"
            alt="image house"
            className="bottom-banner-circle-image"
          />
          <div className="col-2 banner-button">
            <Link
              to={"/nimble-rent-information"}
              aria-label="nimble rent information"
            >
              <CustomButton
                className="button-check"
                label="Check Eligibility"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NimbleRentBottomBanner;
